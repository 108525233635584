const messages = {
	en: {
		currentPassword: "Password",
		emailLabel: "Email",
		error: {
			emailInvalid:
				"Email can only consist of letters, digits or these symbols: @ . + - _",
		},
		header: "Change email",
		newUsername: "New email",
		submit: "SUBMIT",
		tooltip: {
			newEmail: "Please enter a valid email address for your new email",
			password: "Please enter your current password",
		},
	},
};

export default messages;
