import { Popover } from "@app/components/popovers/popover";
import { balanceAction } from "@app/constants/balance-options";
import { ComboButton } from "@app/controls/combo-button";
import { formatNumber } from "@app/utils/format-number";
import { memo } from "react";
import "./balance-card.css";
import { FaInfoCircle } from "react-icons/fa";

export const BalanceCardView = memo(
	(props: {
		className?: string;
		balance: number;
		balanceLastUpdated: string;
		balanceLastUpdatedWarning: string;
		onDeposit?: () => void;
		onWithdrawOption: (value: string) => void;
		isDesktop?: boolean;
		onBalanceAction: (action: string) => void;
	}) => {
		const desktopNumberOptions = {
			currencyCode: "R ",
			decimalPlaces: 2,
			groupSeparator: ",",
		};

		const mobileNumberOptions = {
			currencyCode: "R ",
			decimalPlaces: props.balance >= 1000 ? 2 : 0,
			formatUnit: true,
		};

		const containerOuterStyle = [
			"flex flex-row",
			"flex-wrap",
			"gap-x-4",
			"py-5.5",
			"px-5",
			"lg:px-7",
			"balance-card",
			"relative",
			"z-20",
			props.className,
		].join(" ");

		const actionOptions = [
			{
				value: balanceAction.addFunds,
				label: "Add Funds",
			},
			{
				value: balanceAction.withdraw,
				label: "Withdraw",
			},
		];

		return (
			<div className={containerOuterStyle}>
				<div className="flex flex-col balance-card-inner mr-auto w-full">
					<div className="flex flex-row gap-x-2 items-center justify-between">
						<div className="flex flex-row font-primary-bold gap-x-2 balance-card-heading">
							Balance
						</div>
						<ComboButton
							className="balance-card-combo-button pb-0 z-0"
							buttonClassName="pb-1.5"
							fixedValue={
								actionOptions.length > 0 ? actionOptions[0].value : undefined
							}
							fixedValueHiddenInDropdown
							items={actionOptions}
							onClick={(value: string) => props.onBalanceAction(value)}
						/>
					</div>
					<div className="flex flex-row font-primary-light gap-x-2 justify-between whitespace-nowrap text-3xl leading-none">
						<div className="balance-card-figure">
							{props.balance > 0
								? formatNumber(
										props.balance,
										props.isDesktop
											? desktopNumberOptions
											: mobileNumberOptions,
									)
								: "R 0"}
						</div>
					</div>
					{props.balanceLastUpdated && (
						<div className="font-primary-regular text-sm balance-card-last-updated flex">
							<div>{props.balanceLastUpdated}</div>
							{props.balanceLastUpdatedWarning && (
								<div>
									<Popover
										toggleContent={
											<FaInfoCircle
												className="opacity-50 balance-card icon-filled ml-2"
												size="18px"
											/>
										}
										popperContent={
											<div
												className="font-primary-regular leading-5 -mt-2.5 mx-2.5 p-2.5 footnote"
												dangerouslySetInnerHTML={{
													__html: props.balanceLastUpdatedWarning,
												}}
											/>
										}
										showArrow={true}
										showOnHover={props.isDesktop}
										flip={true}
										placement="bottom"
										offset={[16, 16]}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		);
	},
);
