import { Graphing } from "@app/components/graphing";
import { dateFormats } from "@app/constants/date-formats";
import { Dropdown } from "@app/controls/dropdown";
import type { ArbitragerSpreads, ChartType, DropdownItem } from "@app/entities";
import type { RootState } from "@app/redux";
import type { FormatNumberOptions } from "@app/utils/format-number";
import type { Tick, TooltipItem } from "chart.js";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import type { ArbitragerPeriod } from "src/constants/arbitrager-period";
import type { xAxisCallbackFunction } from "../graphing/properties";
import messages from "./messages";
import maxTicks from "./models/arbitrager-period-ticks";
import type { ViewProperties } from "./properties";
import "./spread-graph-card.css";

const SpreadGraphCardView = React.memo((props: ViewProperties) => {
	const { language } = useSelector(
		(rootState: RootState) => rootState.language,
	);

	const breakdownDropdownStyle = ["spread-graph-breakdown", "mx-2"].join(" ");

	const chartStyle = [
		"w-full",
		"spread-graph-chart",
		"pl-1.5",
		"lg:pl-5",
		"pr-0",
		"lg:pr-9",
	].join(" ");

	const colStyle = ["flex", "flex-col"].join(" ");

	const desktopSeparatorStyle = [
		"border-b",
		"-mx-9",
		"mt-5",
		"hidden",
		"lg:flex",
		"spread-graph-separator",
	].join(" ");

	const mainStyle = [
		colStyle,
		"grow",
		"py-7",
		"relative",
		"spread-graph",
		props.className ?? "",
	]
		.join(" ")
		.trim();

	const mobileSeparatorStyle = [
		"border-b",
		"mb-5",
		"-mx-5",
		"lg:hidden",
		"spread-graph-separator",
	].join(" ");

	const rowStyle = ["flex", "flex-row"].join(" ");

	const headerActionStyle = [
		"items-end",
		"flex-1",
		"justify-start lg:justify-end",
		"gap-y-4",
		"mb-12",
		"lg:mb-0",
		"spread-graph-header-action",
	].join(" ");

	const headerStyle = [
		"block",
		"lg:flex",
		"flex-row",
		"gap-x-2",
		"justify-between",
	].join(" ");

	const headerOuterStyle = [
		colStyle,
		"lg:flex-row",
		"px-5",
		"lg:px-9",
		"gap-y-5",
		"justify-between",
		"lg:pb-5",
	].join(" ");

	const headerInnerStyle = [rowStyle, "items-center", "pb-6", "lg:pb-0"].join(
		" ",
	);

	const timeDropdownStyle = ["spread-graph-dropdown", "mr-auto lg:mr-0"].join(
		" ",
	);

	const getDecimalPlaces = () => {
		return props.breakdown === "EXCHANGE_RATE" || props.breakdown === "SPREAD"
			? 1
			: 0;
	};

	const getHeaderContent = () => {
		return (
			<div className={headerOuterStyle}>
				<div className={[colStyle, "w-full"].join(" ")}>
					<div className={headerStyle}>
						<div className={headerInnerStyle}>
							<div className={breakdownDropdownStyle}>
								<Dropdown
									className="breakdown font-primary-bold"
									items={breakdownOptions}
									theme="primary"
									selectedValue={props.breakdown}
									onSelect={
										!props.loading ? props.onChangeBreakdown : undefined
									}
								/>
							</div>
						</div>
						<div className={mobileSeparatorStyle}></div>
						<div className={headerActionStyle}>
							<Dropdown
								className={timeDropdownStyle}
								items={periodOptions}
								selectedValue={String(props.intervalIndex)}
								theme="primary"
								onSelect={!props.loading ? props.onChange : undefined}
							/>
						</div>
					</div>
					<div className={desktopSeparatorStyle}></div>
				</div>
			</div>
		);
	};

	const getMaxTicks = () => {
		const period = props.currentPeriod?.days as ArbitragerPeriod;

		if (period) {
			return maxTicks[period];
		} else {
			return 12;
		}
	};

	const now = React.useMemo(() => {
		return new Date();
	}, []);

	const breakdownOptions =
		React.useMemo((): DropdownItem<ArbitragerSpreads>[] => {
			return [
				{
					value: "EXCHANGE_RATE",
					label: messages[language].breakdown.EXCHANGE_RATE,
				},
				{
					value: "LOCAL_PRICE",
					label: messages[language].breakdown.LOCAL_PRICE,
				},
				{
					value: "OFFSHORE_PRICE",
					label: messages[language].breakdown.OFFSHORE_PRICE,
				},
				{
					value: "SPREAD",
					label: messages[language].breakdown.SPREAD,
				},
			];
		}, []);

	const breakdownFormatOptions = React.useMemo((): FormatNumberOptions => {
		const prefix = props.breakdown
			? messages[language].prefix[props.breakdown]
			: "";
		const suffix = props.breakdown
			? messages[language].suffix[props.breakdown]
			: "";

		return {
			currencyCode: prefix,
			decimalPlaces: getDecimalPlaces(),
			groupSeparator: messages[language].groupSeparator,
			isPercentage: suffix === "%",
			formatUnit: props.isDesktop ? false : true,
		};
	}, [props.breakdown, props.isDesktop]);

	const periodOptions = React.useMemo(() => {
		if (props.periodSettings) {
			return props.periodSettings.marketChart.periods.map((x, i) => {
				return {
					value: String(i),
					label: x.display,
				};
			});
		}
		return [];
	}, [props.periodSettings]);

	const xAxisCallback: xAxisCallbackFunction = (
		tickValue: string | number,
		index: number,
		ticks: Tick[],
	): string | number | undefined => {
		if (!props.chartLabelsFull) return undefined;

		var result: string | number | undefined = props.chartLabelsFull[index];
		var convertedResult = moment(result).subtract(
			now.getTimezoneOffset(),
			"minutes",
		);

		const hours = +convertedResult.format("H");
		const minutes = +convertedResult.format("m");

		if ((props.currentPeriod?.days as ArbitragerPeriod) === 1) {
			result = props.chartLabels
				? props.chartLabels[index]
				: convertedResult.format(dateFormats.time24HoursMinutes);
		} else if ((props.currentPeriod?.days as ArbitragerPeriod) === 365) {
			result = convertedResult.format(dateFormats.iso8601);
		} else if (convertedResult.isValid()) {
			if (!(hours === 0 && minutes >= 0 && minutes <= 30)) {
				result = undefined;
			} else {
				result = convertedResult.format(dateFormats.iso8601);
			}
		}

		return result;
	};

	return (
		<>
			<div className={mainStyle}>
				{getHeaderContent()}
				<Graphing
					animationDuration={300}
					autoSkip
					className={chartStyle}
					datasets={props.chartData ? [props.chartData] : []}
					labels={props.chartLabels ? props.chartLabels : []}
					type={"line"}
					loading={props.loading}
					liveTracker
					liveTrackerFormatOptions={breakdownFormatOptions}
					maxTicksLimit={getMaxTicks()}
					pointHitRadius={30}
					xAxisMaxRotation={props.isDesktop ? 35 : 90}
					xAxisMinRotation={props.isDesktop ? 35 : 90}
					yAxisFormatOptions={breakdownFormatOptions}
					onRenderTooltipLabel={(tooltip: TooltipItem<ChartType>) =>
						props.onRenderTooltipLabel(tooltip, breakdownFormatOptions)
					}
					onRenderTooltipTitle={props.onRenderTooltipTitle}
					xAxisCallback={xAxisCallback}
				/>
			</div>
		</>
	);
});

export { SpreadGraphCardView };
