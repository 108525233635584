import type React from "react";

export type InputType =
	| "currency"
	| "date"
	| "email"
	| "number"
	| "password"
	| "search"
	| "tel"
	| "text";

const types: { [key in InputType]: React.HTMLInputTypeAttribute } = {
	currency: "number",
	date: "date",
	email: "email",
	number: "number",
	password: "password",
	search: "text",
	tel: "text",
	text: "text",
};

export { types };
