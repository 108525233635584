import { Modal } from "@app/components/modals/modal";
import { Popover } from "@app/components/popovers/popover";
import { Wizard } from "@app/components/wizard";
import { Button } from "@app/controls/button";
import type { RootState } from "@app/redux";
import React from "react";
import { useSelector } from "react-redux";
import "./deposit-funds-modal.css";
import messages from "./messages";
import type { ViewProperties } from "./properties";

export const DepositFundsModalView = React.memo((props: ViewProperties) => {
	const { language } = useSelector((state: RootState) => state.language);

	const getFeeTable = () => {
		return (
			<div className="fee-table flex flex-col items-center">
				<h4 className="mb-2">{messages[language].feeTableHeader}</h4>
				<table>
					<thead>
						<tr>
							{messages[language].feeTable.headerContent.map((x, index) => (
								<th key={`header-${index}`} className="p-2">
									{x}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{messages[language].feeTable.bodyContent.map((row) => (
							<tr>
								{row.map((cell, index) => (
									<td key={`cell-${index}`}>{cell}</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	const getPopover = () => {
		return (
			<Popover
				className="w-full"
				toggleContent={
					<a href="#" target="_self">
						{messages[language].howMuchDeposit}
					</a>
				}
				popperContent={
					<div className="font-primary-medium -ml-2.5 p-2.5 z-10 deposit-info overflow-y-auto">
						{messages[language].depositPopover}
						<br />
						<br />
						{getFeeTable()}
					</div>
				}
				showArrow={true}
				placement="top-start"
				offset={[8, 16]}
			/>
		);
	};

	const getDepositFunds = () => {
		return (
			<>
				<h1 className="font-bold text-2xl mb-8">
					{messages[language].depositFundsHeader}
				</h1>
				<div className="account-details text-lg mb-9">
					<span>
						{messages[language].depositFundsSubHeader(props.bankName)}
					</span>
					<br />
					<br />
					<h3 className="font-bold">{messages[language].accountDetails}</h3>
					<p>
						<span>{messages[language].accountHolder}</span>
						{props.accountHolder}
					</p>
					<p>
						<span>{messages[language].bankName}</span>
						{props.bankName}
					</p>
					<p>
						<span>{messages[language].branchCode}</span>
						{props.branchCode}
					</p>
					<p>
						<span>{messages[language].accountNumber}</span>
						{props.accountNumber}
					</p>
					<p className="mb-9">
						<span>{messages[language].accountType}</span>
						{props.accountType}
					</p>
					{getPopover()}
				</div>
			</>
		);
	};

	const getSourceOfFunds = () => {
		return (
			<>
				<h1 className="font-bold text-2xl mb-8">
					{messages[language].sourceOfFundsHeader}
				</h1>
				<div
					className="source-of-funds text-lg mb-9"
					dangerouslySetInnerHTML={{
						__html: messages[language].sourceOfFundsContent(
							props.rmName,
							props.rmEmail,
							props.clientName,
						),
					}}
				/>
			</>
		);
	};

	return (
		<Modal
			className="deposit-funds-modal"
			open={props.open}
			onClose={props.onClose}
		>
			<Wizard
				isComplete={props.isComplete}
				forms={[getDepositFunds()]}
				completeForm={getSourceOfFunds()}
				hideProgress
			/>
			<Button className="done" theme="primary" onClick={props.onNextStep}>
				{props.isComplete
					? messages[language].doneButton
					: messages[language].transferredFundsButton}
			</Button>
		</Modal>
	);
});
