import { Popover } from "@app/components/popovers/popover";
import type { RootState } from "@app/redux";
import React, { type CSSProperties } from "react";
import { useSelector } from "react-redux";
import "./bar-graph.css";
import { FaInfoCircle } from "react-icons/fa";
import type { Detail } from "./models/detail";
import type { ViewProperties } from "./properties";

export const BarGraphView = React.memo((props: ViewProperties) => {
	const { language } = useSelector(
		(rootState: RootState) => rootState.language,
	);

	const getPopoverContent = (
		totalAmount: number,
		percentages?: {
			first: number;
			second: number;
			third: number;
		},
	) => {
		let firstHovered = false;
		let secondHovered = false;
		let thirdHovered = false;

		if (props.mouseHoverPositionXAxisState && percentages) {
			if (props.mouseHoverPositionXAxisState <= percentages.first)
				firstHovered = true;
			else if (props.mouseHoverPositionXAxisState <= percentages.second)
				secondHovered = true;
			else if (props.mouseHoverPositionXAxisState <= percentages.third)
				thirdHovered = true;
		}

		if (props.details) {
			const sortedDetails = props.details.sort((a, b) => a.order - b.order);
			const heading = props.allowance?.heading;
			const rows = sortedDetails
				.filter((_, index) => index < props.tooltipRowsToShow)
				.map((detail, index) => ({
					label: detail.label,
					amount: detail.amount,
					colorClass: detail.colourClass,
					hovered:
						index === 0
							? firstHovered
							: index === 1
								? secondHovered
								: thirdHovered,
				}));

			return (
				<div className={footnoteStyle}>
					<div className={popoverSubheadingStyle}>{heading}</div>
					<div className={popoverRowsContainerStyle}>
						{rows.map((row, index) => (
							<div key={row.amount + row.label}>
								<div
									className={[
										popoverDetailContainerStyle,
										row.hovered ? "bar-graph-popover-row-highlighted" : "",
									].join(" ")}
								>
									<div>
										<span className={row.colorClass} />
										<span className={popoverDetailStyle}>{row.label}</span>
									</div>
									<div>
										<span className={popoverDetailAmountStyle}>
											{props.getFormattedFigures([row.amount])}
										</span>
									</div>
								</div>
								{index !== rows.length - 1 && (
									<hr className={popoverRowDividerStyle} />
								)}
							</div>
						))}
						{props.showTooltipTotal && (
							<>
								<hr className={popoverRowDividerStyle} />
								<div className={popoverDetailContainerStyle}>
									<span className={totalRemainingStyle}>Total Remaining</span>
									<span className={popoverDetailAmountStyle}>
										{props.getFormattedFigures([totalAmount])}
									</span>
								</div>
							</>
						)}
					</div>
				</div>
			);
		}
	};

	const getTooltipContent = (details: Detail[], totalAmount: number) => {
		return (
			<Popover
				toggleContent={
					<FaInfoCircle className={"opacity-50 withdraw-icon"} size="18px" />
				}
				popperContent={getPopoverContent(totalAmount)}
				showOnHover={true}
				placement={"bottom"}
				flip={false}
				offset={({ popper, reference }) => {
					if (!props.popoverContainer.current || !props.headerContainer.current)
						return [0, 0];

					const target = props.popoverContainer.current.clientWidth / 2;

					return [
						target -
							props.headerContainer.current.clientWidth -
							reference.width +
							1,
						reference.height +
							props.popoverContainer.current.clientHeight +
							(props.mobile ? 12 : 14),
					];
				}}
			/>
		);
	};

	const getBarGraphContent = (
		totalAmount: number,
		firstWidthPercent: number,
		secondWidthPercent: number,
		thirdWidthPercent: number,
		usedWidthPercent: number,
		figuresFormatted: string,
	) => {
		return (
			<Popover
				toggleClassName="w-full"
				toggleContent={
					<div
						className={barStyle}
						ref={props.popoverContainer}
						onMouseMove={props.onBarGraphMouseMove}
					>
						<div className={barTextStyle}>{figuresFormatted}</div>
						<div
							className={barFillStyle}
							style={
								{
									"--first-width-percentage": `${firstWidthPercent}%`,
									"--second-width-percentage": `${secondWidthPercent}%`,
									"--third-width-percentage": `${thirdWidthPercent}%`,
									"--used-width-percentage": `${usedWidthPercent}%`,
								} as CSSProperties
							}
						>
							<div className={"leading-10 inner"}> </div>
						</div>
					</div>
				}
				popperContent={getPopoverContent(totalAmount, {
					first: firstWidthPercent,
					second: secondWidthPercent,
					third: thirdWidthPercent,
				})}
				showOnHover={true}
				placement={"bottom-start"}
				flip={false}
				offset={({ popper, reference }) => {
					return [(reference.width - popper.width) / 2, reference.height / 2];
				}}
			/>
		);
	};

	const getBarGraph = () => {
		if (!props.allowance || !props.details) {
			return undefined;
		}

		const details = props.details.sort((a, b) => a.order - b.order);

		const firstValue = details[0] ? details[0].amount : 0;
		const secondValue = details[1] ? details[1].amount : 0;
		const thirdValue = details[2] ? details[2].amount : 0;

		const totalAmount = props.totalAmount;

		const usedWidthPercent = Math.max(
			0,
			Math.min(100, (totalAmount / props.allowance.valueUpper) * 100),
		);

		const firstWidthPercent = props.getDetailWidthPercentages(
			totalAmount,
			firstValue,
			usedWidthPercent,
		);

		const secondWidthPercent =
			props.getDetailWidthPercentages(
				totalAmount,
				secondValue,
				usedWidthPercent,
			) + firstWidthPercent;

		const thirdWidthPercent =
			props.getDetailWidthPercentages(
				totalAmount,
				thirdValue,
				usedWidthPercent,
			) + secondWidthPercent;

		const formattedFigures = props.getFormattedFigures([
			totalAmount,
			props.allowance.valueUpper,
		]);
		return (
			<div className={props.addZIndex ? "details-container" : ""}>
				<div className={tooltipContainerStyle}>
					<div className={subheadingStyle} ref={props.headerContainer}>
						{props.allowance.heading}
					</div>

					<div className={tooltipStyle}>
						{getTooltipContent(props.details, totalAmount)}
					</div>
				</div>
				{getBarGraphContent(
					totalAmount,
					firstWidthPercent,
					secondWidthPercent,
					thirdWidthPercent,
					usedWidthPercent,
					formattedFigures,
				)}
			</div>
		);
	};

	const barFillStyle = [
		"h-10",
		"text-transparent",
		`bar-graph-fill-${props.graphFillClassNumber}-values`,
	].join(" ");

	const barStyle = ["h-10", "bar-graph"].join(" ");

	const barTextStyle = [
		"absolute",
		"leading-10",
		"left-0",
		"text-center",
		"text-sm",
		"w-full",
		"bar-graph-text",
		"z-10",
	].join(" ");

	const subheadingStyle = [
		"font-primary-bold",
		"mb-1.5",
		"mt-2.5",
		"text-lg",
		"bar-text",
	].join(" ");

	const popoverSubheadingStyle = [
		"font-primary-bold",
		"text-sm",
		"bar-text",
	].join(" ");

	const footnoteStyle = [
		"font-primary-regular",
		"leading-5",
		"-m-2.5",
		"p-2.5",
		"bar-graph-popover",
		"z-10",
	].join(" ");

	const tooltipStyle = ["mt-3.5", "ml-2"].join(" ");

	const tooltipContainerStyle = ["flex", "flex-row"].join(" ");

	const popoverRowsContainerStyle = ["flex", "flex-col"].join(" ");

	const popoverRowDividerStyle = [
		"block",
		"h-px",
		"bar-graph-popover-row",
	].join(" ");

	const totalRemainingStyle = [
		"font-primary-medium",
		"text-sm",
		"bar-graph-popover-total-remaining",
	].join(" ");

	const popoverDetailContainerStyle = [
		"flex",
		"justify-between",
		"items-center",
		"py-2",
	].join(" ");

	const popoverDetailStyle = [
		"mx-2",
		"text-sm",
		"flex-grow",
		"bar-graph-popover-row-detail",
	].join(" ");

	const popoverDetailAmountStyle = [
		"mr-2",
		"bar-graph-popover-row-detail",
		"text-sm",
	].join(" ");

	return <div>{getBarGraph()}</div>;
});
