import { CustomLoader } from "@app/components/custom-loader";
import { Modal } from "@app/components/modals/modal";
import { type Row, Table } from "@app/components/table";
import { Button } from "@app/controls/button";
import type { WithdrawalAccount } from "@app/entities";
import type { RootState } from "@app/redux";
import { memo } from "react";
import { useSelector } from "react-redux";
import "./account-selection-modal.css";
import { FaEdit, FaTrash } from "react-icons/fa";
import messages from "./messages";
import type { ViewProperties } from "./properties";

export const AccountSelectionModalView = memo((props: ViewProperties) => {
	const { language } = useSelector((state: RootState) => state.language);

	const getTableHeadersContent = (): Row => {
		return {
			cells: [
				{
					content: (
						<div className={"account-selection-header-text"}>
							{messages[language].bankNameText}
						</div>
					),
					alignment: "left",
				},
				{
					content: (
						<div className={"account-selection-header-text"}>
							{messages[language].accountNumberText}
						</div>
					),
				},
			],
		};
	};

	const getTableRow = (account: WithdrawalAccount): Row => {
		return {
			cells: [
				{
					content: (
						<div className="font-primary-regular inline whitespace-nowrap account-selection-text-content account-selection-bank-name">
							{account.bankName}
						</div>
					),
					alignment: "left",

					onClick: () => props.onSelectAccount(account),
				},
				{
					content: (
						<div className="font-primary-regular inline whitespace-nowrap account-selection-text-content">
							{account.accountNumber}
						</div>
					),
					onClick: () => props.onSelectAccount(account),
				},
				{
					content: (
						<div className={"account-selection-actions-container"}>
							<Button
								className={"-pr-10"}
								onClick={(event) => {
									event.stopPropagation();
									event.preventDefault();
									props.onEdit(account);
								}}
							>
								<FaEdit size="20px" className={"account-selection-action"} />
							</Button>
							<Button
								onClick={(event) => {
									event.stopPropagation();
									event.preventDefault();
									props.onShowDelete(account);
								}}
							>
								<FaTrash
									size="20px"
									className={"account-selection-action py-0.5"}
								/>
							</Button>
						</div>
					),
				},
			],
		};
	};

	const getTableDataContent = (isMain: boolean): Row[] => {
		const items = isMain
			? props.data.filter((x) => x.clientId === props.clientId)
			: props.data.filter((x) => x.clientId !== props.clientId);
		return items.map((x) => getTableRow(x));
	};

	const clientTableData = getTableDataContent(true);
	const spouseTableData = getTableDataContent(false);

	return (
		<Modal
			className={"account-selection-modal"}
			open={props.open}
			onClose={props.onClose}
		>
			<h1 className={"font-bold text-2xl pb-4"}>
				{messages[language].accountSelectionHeader}
			</h1>

			{!props.dataLoading &&
				clientTableData.length === 0 &&
				spouseTableData.length === 0 && (
					<div className="font-primary-regular my-14 opacity-70 text-base text-center">
						{messages[language].noResults}
					</div>
				)}
			{(props.dataLoading ||
				(!props.dataLoading && clientTableData.length > 0)) && (
				<>
					<h2 className={"font-bold text-lg"}>
						{messages[language].accountsHeader}
					</h2>
					<div className={"account-selection-table-container"}>
						<Table
							headers={getTableHeadersContent()}
							data={props.dataLoading ? [] : clientTableData}
						/>
						{props.dataLoading && <CustomLoader page={false} />}
					</div>
				</>
			)}
			{spouseTableData.length > 0 && (
				<>
					<h2 className={"font-bold text-lg"}>
						{messages[language].spousesAccountsHeader}
					</h2>
					<div className={"account-selection-table-container"}>
						<Table headers={getTableHeadersContent()} data={spouseTableData} />
					</div>
				</>
			)}
			<Button
				className={"account-selection-button"}
				type="button"
				theme="primary"
				onClick={props.onAdd}
			>
				{messages[language].addNewBankAccountButton}
			</Button>
		</Modal>
	);
});
