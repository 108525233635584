import type { NotificationResponse } from "@app/entities";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	notifications?: NotificationResponse[] | null;
	notificationsError?: string[] | null;
	notificationsLoading?: boolean;
};

export const defaultState: State = {};

const slice = createSlice({
	name: "notifications",
	initialState: defaultState,
	reducers: {
		setNotifications(state, action: PayloadAction<NotificationResponse[]>) {
			state.notifications = action.payload;
		},
		setNotificationsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.notificationsError = action.payload;
		},
		setNotificationsLoading(state: State, action: PayloadAction<boolean>) {
			state.notificationsLoading = action.payload;
		},
	},
});

export const {
	setNotifications,
	setNotificationsError,
	setNotificationsLoading,
} = slice.actions;

export default slice.reducer;
