import type { ReactNode } from "react";
import { WizardView } from "./wizard-view";

export const Wizard = (props: {
	className?: string;
	completeForm: ReactNode;
	forms: ReactNode[];
	hideSteps?: boolean;
	isComplete?: boolean;
	step?: number;
	hideProgress?: boolean;
}) => {
	return (
		<WizardView
			{...props}
			step={props.step !== undefined && props.step >= 0 ? props.step : 0}
		/>
	);
};
