const messages = {
	en: {
		depositFundsHeader: "Deposit Funds",
		depositFundsSubHeader: (bankName: string) => (
			<>
				You can deposit funds by sending an EFT
				<br />
				to your&nbsp;{bankName}&nbsp;account.
			</>
		),
		sourceOfFundsHeader: "Source of Funds",
		accountDetails: "Account Details",
		accountHolder: "Account Holder: ",
		bankName: "Bank: ",
		branchCode: "Branch Code: ",
		accountNumber: "Account Number: ",
		accountType: "Account Type: ",
		howMuchDeposit: "How much should I deposit?",
		doneButton: "DONE",
		transferredFundsButton: "I HAVE TRANSFERRED FUNDS",
		depositPopover:
			"The more you invest the better your returns will be. This is due to fixed costs and our performance share decreasing as a percentage of the total amount invested. Note that your investment amount can be increased or decreased at any stage of the investment.",
		feeTableHeader: "Table 1: Performance Fee Tiering",
		feeTable: {
			headerContent: ["Investment Amount per Cycle", "Percentage Fee"],
			bodyContent: [
				["R100,000 – R149,999", "35%"],
				["R150,000 – R199,999", "33%"],
				["R200,000 – R299,999", "30%"],
				["R300,000 – R399,999", "28%"],
				["+R400,000", "25%"],
			],
		},
		sourceOfFundsContent: (
			rmName: string,
			rmEmail: string,
			clientName: string,
		) => `
        <p>Mercantile Bank requires that source of funds are shown for funds deposited.</p>
        <br />
        <p>Please provide a bank statement showing the funds leaving the originating account and being sent to Mercantile Bank. Proof of Payment (PoP) will not be accepted.</p>
        <br />
        <p>The statement can be sent to your Relationship Manager, ${rmName}, at
        <a href="mailto:${rmEmail}?subject=${encodeURIComponent(clientName)}%20-%20Source%20of%20funds">
        ${rmEmail}
        </a>
        </p>`,
	},
};

export default messages;
