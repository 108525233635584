import type { ReadNotifications } from "@app/entities";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

export type State = {
	notificationsRead?: ReadNotifications[];
	notificationsReadError?: string[];
	notificationsReadLoading?: boolean;
};

export const defaultState: State = {};

const slice = createSlice({
	name: "notificationsRead",
	initialState: defaultState,
	reducers: {
		setNotificationsRead(
			state: State,
			action: PayloadAction<ReadNotifications[]>,
		) {
			state.notificationsRead = action.payload;
		},
		setNotificationRead(
			state: State,
			action: PayloadAction<{ userId: number; notificationId: number }>,
		) {
			var newState: ReadNotifications[] = [
				{
					userId: action.payload.userId,
					notificationIds: [action.payload.notificationId],
				},
			];

			if (state.notificationsRead) {
				state.notificationsRead.forEach((x) => {
					var notificationIds = cloneDeep(x.notificationIds);

					if (action.payload.userId !== x.userId)
						newState.push({
							userId: x.userId,
							notificationIds: notificationIds,
						});
					else {
						if (!notificationIds.includes(action.payload.notificationId))
							notificationIds.push(action.payload.notificationId);
						newState[0].notificationIds = notificationIds;
					}
				});
			}

			state.notificationsRead = newState;
		},
		setNotificationsReadError(
			state: State,
			action: PayloadAction<string[] | undefined>,
		) {
			state.notificationsReadError = action.payload;
		},
		setNotificationsReadLoading(state: State, action: PayloadAction<boolean>) {
			state.notificationsReadLoading = action.payload;
		},
	},
});

export const {
	setNotificationRead,
	setNotificationsRead,
	setNotificationsReadError,
	setNotificationsReadLoading,
} = slice.actions;

export default slice.reducer;
