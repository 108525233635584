import type {
	ClientResponse,
	ClientSummariesResponse,
	ClientSummary,
	Cycle,
	CycleListResponse,
	StatisticsResponse,
} from "@app/entities";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	clients: ClientSummary[];
	clientsError?: string[] | null;
	clientsLoading?: boolean;
	clientsTotalCount: number;
	clientsTotalPages: number;

	cycles: Cycle[];
	cyclesError?: string[] | null;
	cyclesLoading?: boolean;
	cyclesTotalCount: number;
	cyclesTotalPages: number;

	activeClient?: ClientResponse;
	activeClientError?: string[] | null;
	activeClientLoading?: boolean;

	statementError?: string[] | null;
	statementLoading?: boolean;

	createClientError?: string[] | null;
	createClientLoading?: boolean;

	saveKrakenDetailsError?: string[] | null;
	saveKrakenDetailsLoading?: boolean;

	statistics?: StatisticsResponse;
	statisticsError?: string[] | null;
	statisticsLoading?: boolean;
};

export const defaultState: State = {
	clients: [],
	clientsTotalCount: 0,
	clientsTotalPages: 0,

	cycles: [],
	cyclesTotalCount: 0,
	cyclesTotalPages: 0,
};

const slice = createSlice({
	name: "clients",
	initialState: defaultState,
	reducers: {
		setClientSummaries(state, action: PayloadAction<ClientSummariesResponse>) {
			state.clients = action.payload.clients;
			state.clientsTotalCount = action.payload.total;
			state.clientsTotalPages = action.payload.totalPages;
		},
		setClientSummariesError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.clientsError = action.payload;
		},
		setClientSummariesLoading(state: State, action: PayloadAction<boolean>) {
			state.clientsLoading = action.payload;
		},

		setActiveClient(state, action: PayloadAction<ClientResponse>) {
			state.activeClient = action.payload;
		},
		setActiveClientError(state: State, action: PayloadAction<string[] | null>) {
			state.activeClientError = action.payload;
		},
		setActiveClientLoading(state: State, action: PayloadAction<boolean>) {
			state.activeClientLoading = action.payload;
		},

		setCycles(state, action: PayloadAction<CycleListResponse>) {
			state.cycles = action.payload.cycles;
			state.cyclesTotalCount = action.payload.total;
			state.cyclesTotalPages = action.payload.totalPages;
		},
		setCyclesError(state: State, action: PayloadAction<string[] | null>) {
			state.cyclesError = action.payload;
		},
		setCyclesLoading(state: State, action: PayloadAction<boolean>) {
			state.cyclesLoading = action.payload;
		},

		setStatementError(state: State, action: PayloadAction<string[] | null>) {
			state.statementError = action.payload;
		},
		setStatementLoading(state: State, action: PayloadAction<boolean>) {
			state.statementLoading = action.payload;
		},

		setCreateClientError(state: State, action: PayloadAction<string[] | null>) {
			state.createClientError = action.payload;
		},
		setCreateClientLoading(state: State, action: PayloadAction<boolean>) {
			state.createClientLoading = action.payload;
		},

		setSaveKrakenDetailsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.saveKrakenDetailsError = action.payload;
		},
		setSaveKrakenDetailsLoading(state: State, action: PayloadAction<boolean>) {
			state.saveKrakenDetailsLoading = action.payload;
		},

		setStatistics(state: State, action: PayloadAction<StatisticsResponse>) {
			state.statistics = action.payload;
		},
		setStatisticsError(state: State, action: PayloadAction<string[] | null>) {
			state.statisticsError = action.payload;
		},
		setStatisticsLoading(state: State, action: PayloadAction<boolean>) {
			state.statisticsLoading = action.payload;
		},
	},
});

export const {
	setClientSummaries,
	setClientSummariesError,
	setClientSummariesLoading,

	setActiveClient,
	setActiveClientError,
	setActiveClientLoading,

	setCycles,
	setCyclesError,
	setCyclesLoading,

	setStatementError,
	setStatementLoading,

	setCreateClientError,
	setCreateClientLoading,

	setSaveKrakenDetailsError,
	setSaveKrakenDetailsLoading,

	setStatistics,
	setStatisticsError,
	setStatisticsLoading,
} = slice.actions;

export default slice.reducer;
