import type { NotificationResponse, ReferrerResponse } from "@app/entities";
import { concatenateArrays } from "@app/utils/concatenate-arrays";
import { authorizedApi } from "./api";
import type {
	GenericFailureResponse,
	GenericResponse,
	GetReferrerResponse,
} from "./models";

function mapToReferrer(value: GetReferrerResponse): ReferrerResponse {
	return {
		id: value.id,
		name: value.name,
		requiresDetail: value.requires_detail,
	};
}

export async function getNotifications(): Promise<
	NotificationResponse[] | GenericResponse
> {
	try {
		var { data } =
			await authorizedApi.get<NotificationResponse[]>("notifications/");

		return data;
	} catch (exception: any) {
		var error = exception.data as GenericFailureResponse;

		var reasons = concatenateArrays(error.genericErrors, [error.detail ?? ""]);

		return {
			reasons: reasons,
		};
	}
}

export async function getReferrers(): Promise<
	ReferrerResponse[] | GenericResponse
> {
	try {
		var { data } =
			await authorizedApi.get<GetReferrerResponse[]>("referred_by/");

		return data.map((x) => {
			return mapToReferrer(x);
		});
	} catch (exception: any) {
		var error = exception.data as GenericFailureResponse;

		var reasons = concatenateArrays(error.genericErrors, [error.detail ?? ""]);

		return {
			reasons: reasons,
		};
	}
}
