import React, { useEffect, useState } from "react";

import desktopGraphicSrc from "./graphic-desktop.jpg";
import mobileGraphicSrc from "./graphic-mobile.jpg";

import styles from "./index.module.css";

export const ForexBanner = ({
	email,
	onBannerShowChange,
}: {
	email: string;

	onBannerShowChange: (show: boolean) => void;
}) => {
	const key = `forex-banner-${email}`;
	const [show, setShow] = useState(!window.localStorage.getItem(key));

	useEffect(() => {
		onBannerShowChange(show);
	}, [show]);

	if (!show) return null;

	return (
		<div className={styles.container}>
			<button
				type="button"
				className={styles.closeButton}
				aria-label="Close"
				onClick={() => {
					setShow(false);
					window.localStorage.setItem(key, "false");
				}}
			>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M14.1667 5.83325L5.83337 14.1666M5.83337 5.83325L14.1667 14.1666"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
			<img
				className={styles.desktopGraphic}
				src={desktopGraphicSrc}
				alt=""
				width={236}
				height={234}
			/>
			<img
				className={styles.mobileGraphic}
				src={mobileGraphicSrc}
				alt=""
				width={336}
				height={112}
			/>
			<div className={styles.content}>
				<h3 className={styles.title}>
					International money transfers, made simple
				</h3>
				<p className={styles.description}>
					Moving funds for offshore investments, emigration, imports/exports,
					etc has never been easier.
				</p>
				<a
					className={styles.link}
					href="https://futureforex.co.za/contact/"
					target="_blank"
					rel="noreferrer"
				>
					Get in touch
				</a>
			</div>
		</div>
	);
};
