import { useSetThrottlingTime, useThrottlingTimer } from "@app/helpers";
import type { RootState } from "@app/redux";
import { secondsToHoursMinutesSeconds } from "@app/utils/seconds-to-hours-minutes-seconds";
import { isEmpty } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ActivateAccountModalView } from "./activate-account-modal-view";

const TICK_TIME = 1000;

export const ActivateAccountModal = (props: {
	open: boolean;
	onClose: () => void;
	onResend: () => void;
	isResendingEmail: boolean;
}) => {
	const { throttlingTime } = useSelector((state: RootState) => state.auth);

	const [setThrottlingTime] = useSetThrottlingTime();

	const [formattedThrottling, setFormattedThrottling] = useState<
		string | undefined
	>();

	const onClose = () => {
		setThrottlingTime(0);
		props.onClose();
	};

	const onUpdateThrottleFormatting = () => {
		const throttlingTimeAsMoment = moment(throttlingTime);
		const formattedThrottling = secondsToHoursMinutesSeconds(
			throttlingTime ? throttlingTimeAsMoment.diff(moment(), "seconds") : 0,
		);

		setFormattedThrottling(
			throttlingTime &&
				!isEmpty(formattedThrottling) &&
				throttlingTimeAsMoment.isAfter()
				? formattedThrottling
				: undefined,
		);
	};

	useThrottlingTimer(throttlingTime, TICK_TIME, onUpdateThrottleFormatting);

	return (
		<ActivateAccountModalView
			{...props}
			formattedThrottling={formattedThrottling}
			onClose={onClose}
		/>
	);
};
