import { CustomLoader } from "@app/components/custom-loader";
import { links } from "@app/constants/links";
import { paths } from "@app/constants/paths";
import type { ClientSummariesResponse } from "@app/entities";
import {
	useGetClient,
	useGetClientSummaries,
	useGetProfile,
	useListPeriodSettings,
	useLogout,
	useSetPowerLogin,
} from "@app/helpers";
import type { RootState } from "@app/redux";
import { authorizedApi, setToken } from "@app/services";
import { isArray } from "lodash";
import { type ReactNode, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

export const Auth = ({ children }: { children: ReactNode }) => {
	const [getProfile] = useGetProfile();
	const [setPowerLogin] = useSetPowerLogin();
	const [listPeriodSettings] = useListPeriodSettings();
	const [getClientSummaries] = useGetClientSummaries();
	const [getClient] = useGetClient();
	const [logout] = useLogout();

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const authToken = searchParams.get("auth-token");
	const loginSource = searchParams.get("login-source");

	const [isPendingSessionCheck, setCheckingSession] = useState(false);

	const { user, userIsAuthenticated, profile } = useSelector(
		(state: RootState) => state.auth,
	);

	const activeToken = authToken || user?.token;

	const handleLoginRedirect = useCallback(() => {
		const loginSource = window.localStorage.getItem("login-source");
		const isInactive = searchParams.get("inactive") === "true";

		if (isInactive) {
			navigate(paths.login(isInactive));
			return;
		}

		if (loginSource === "OFP") {
			window.localStorage.removeItem("login-source");
			window.location.href = links.forexLogin();
			return;
		}

		navigate(paths.login());
	}, [navigate, searchParams.get]);

	const handleSessionCheck = useCallback(async () => {
		console.log("handleSessionCheck");
		try {
			// Check if the user is still authenticated
			// Similar to verify session check on OFP
			await authorizedApi.get("auth/users/me");
		} catch (error) {
			logout(() => {
				handleLoginRedirect();
			});
		}
	}, [handleLoginRedirect, logout]);

	useEffect(() => {
		setToken(activeToken || "");
	}, [activeToken]);

	useEffect(() => {
		if (loginSource) {
			window.localStorage.setItem("login-source", loginSource);
		}
	}, [loginSource]);

	useEffect(() => {
		if (authToken) {
			setCheckingSession(true);
			setPowerLogin(authToken);

			Promise.allSettled([
				listPeriodSettings(),
				getProfile(),
				getClientSummaries(),
			]).then((responses) => {
				let redirectToClientsScreen = false;

				searchParams.delete("auth-token");

				if ("value" in responses[2] && responses[2].value) {
					if (!isArray(responses[2].value)) {
						const clients = responses[2].value as ClientSummariesResponse;

						if (clients.clients.length > 1) {
							redirectToClientsScreen = true;
						} else if (clients.clients.length === 1) {
							getClient(clients.clients[0].id, () => {
								setCheckingSession(false);
							});
						}
					}
				}

				if (redirectToClientsScreen) {
					setCheckingSession(false);
					navigate(paths.clients);
				}
			});
		} else if (!userIsAuthenticated) {
			handleLoginRedirect();
		} else {
			setCheckingSession(true);
			handleSessionCheck().then(() => {
				getProfile();
				setCheckingSession(false);
			});
		}
		return () => {
			setCheckingSession(false);
		};
	}, [userIsAuthenticated]);

	return (
		<>{user && !isPendingSessionCheck ? children : <CustomLoader page />}</>
	);
};
