import { SessionExpiredModal } from "@app/components/modals/session-expired-modal";
import { useClearUserData, useSetNewPageLoad } from "@app/helpers";
import type { RootState } from "@app/redux";
import { abortRequests } from "@app/services";
import * as React from "react";
import { useSelector } from "react-redux";

export const SessionWrapper = ({ children }: { children: React.ReactNode }) => {
	const [clearUserData] = useClearUserData();
	const [setNewPageLoad] = useSetNewPageLoad();

	const [displaySessionExpiredModal, setDisplaySessionExpiredModal] =
		React.useState<boolean>(false);

	const { tokenHasExpired, newPageLoad } = useSelector(
		(state: RootState) => state.auth,
	);

	//#region FUNCTIONS
	function onPageLoad(): void {
		setTimeout(() => {
			setNewPageLoad(false);
		}, 5000);
	}

	React.useEffect(() => {
		window.addEventListener("load", onPageLoad);

		return function cleanup() {
			abortRequests();
			window.removeEventListener("load", onPageLoad);
		};
	}, []);

	React.useEffect(() => {
		if (tokenHasExpired) {
			clearUserData();
			if (!newPageLoad) setDisplaySessionExpiredModal(true);
		}
	}, [tokenHasExpired]);

	return (
		<>
			{children}
			{displaySessionExpiredModal && (
				<SessionExpiredModal
					open
					onClose={() => {
						setDisplaySessionExpiredModal(false);
					}}
				/>
			)}
		</>
	);
};
