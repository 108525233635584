import React from "react";
import type { Properties } from "./properties";
import "./status.css";

export const StatusView = React.memo((props: Properties) => {
	const getBar = () => {
		return (
			<div className={["bar flex flex-1", props.className].join(" ")}>
				{[...Array(props.totalStages)].map((x, index) => {
					var classesToUse = ["section flex flex-1"];

					if (props.stage >= index + 1) classesToUse.push("complete");

					return <div key={index} className={classesToUse.join(" ")} />;
				})}
			</div>
		);
	};

	const getCircle = () => {
		const style = {
			"--percentage": `${(props.stage / props.totalStages) * 100}%`,
		} as React.CSSProperties;

		return (
			<div className={["circle", props.className].join(" ")} style={style}>
				<div className="inner-circle" />
			</div>
		);
	};

	const getStatusType = () => {
		switch (props.type) {
			case "bar":
				return getBar();
			case "circle":
				return getCircle();
		}
	};

	return getStatusType();
});
