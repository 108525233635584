import type { ArbitragerPeriod } from "src/constants/arbitrager-period";

type PeriodPair = { [key in ArbitragerPeriod]: number };

const maxTicks: PeriodPair = {
	"1": 12,
	"7": 7,
	"30": 5,
	"90": 7,
	"365": 12,
};

export default maxTicks;
