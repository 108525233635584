import type { RootState } from "@app/redux";
import { useSelector } from "react-redux";
import { CustomLoaderView } from "./custom-loader-view";
import type { Properties } from "./properties";

export const CustomLoader = (props: Properties) => {
	const { newPageLoad } = useSelector((state: RootState) => state.auth);

	return (
		<CustomLoaderView
			{...props}
			size={props.size ?? "large"}
			thickness={props.thickness ?? "thick"}
			theme={newPageLoad !== false ? "white" : props.theme}
		/>
	);
};
