import { dateFormats } from "@app/constants/date-formats";
import type { ValidationErrors } from "@app/constants/validation-errors";
import { clampNumber } from "@app/utils/clamp-number";
import { FormikControlWrapper } from "@app/wrappers/formik-control-wrapper";
import { debounce } from "lodash";
import moment from "moment";
import React from "react";
import { findDOMNode } from "react-dom";
import { InputView } from "./input-view";
import type { InputState } from "./models/input-state";
import type { Properties } from "./properties";

const Input = (props: Properties) => {
	const defaultDebounce = 700;

	const defaultState: InputState = {
		errors: [],
		focused: false,
		peekPassword: false,
		value: props.defaultValue || "",
	};

	const [state, setState] = React.useState<InputState>(defaultState);
	const containerRef = React.useRef(null as HTMLDivElement | null);

	// Prevents validation checking on initial load
	const [canValidate, setCanValidate] = React.useState(false);

	const validate = (valueToUse?: string) => {
		const valueToCheck = valueToUse ?? state.value;

		const errors: ValidationErrors[] = [];

		// Length & basic checks
		if (
			props.maxLength &&
			valueToCheck.length > (props.maxLength || Number.MAX_SAFE_INTEGER)
		) {
			errors.push("maxLength");
		}
		if (props.minLength && valueToCheck.length < (props.minLength || 0)) {
			errors.push("minLength");
		}
		if (props.required && valueToCheck.length === 0) {
			errors.push("required");
		}

		if (valueToCheck.length > 0) {
			switch (props.type) {
				case "currency": {
					const currencyRx = new RegExp(/^[\d\-]?[\d]*[\.]?[\d]{1,2}$/);
					if (!currencyRx.test(valueToCheck)) {
						errors.push("currency");
					}
					break;
				}
				case "date": {
					const valueAsDate = moment(valueToCheck, dateFormats.reverseIso8601);
					if (!valueAsDate.isValid()) {
						errors.push("date");
					}
					break;
				}
				case "email": {
					const emailRx = new RegExp(
						/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+$/,
					);
					if (!emailRx.test(valueToCheck)) {
						errors.push("email");
					}
					break;
				}
				case "number": {
					const numberRx = new RegExp(/^[\d\+\-]?[\d]*(\.[\d]*)?$/);
					if (!numberRx.test(valueToCheck)) {
						errors.push("numeric");
					}
					break;
				}
				case "password": {
					break;
				}
				case "search": {
					break;
				}
				case "tel": {
					// Very general telephone number limiting - no country-specific format
					const telRx = new RegExp(/^[+]*[(]?[0-9]*[)]?[-\s\./0-9]*$/);
					if (!telRx.test(valueToCheck)) {
						errors.push("telephone");
					}
					break;
				}
				case "text": {
					break;
				}
				default: {
					// Custom error check
					if (props.error) {
						errors.push("custom");
					}
				}
			}
		}

		setState({ ...state, value: valueToCheck, errors: errors });

		if (props.onValidationCallback) {
			props.onValidationCallback(errors);
		}

		return errors[0];
	};

	const debouncedChangeHandler = React.useMemo(
		() =>
			debounce((text: string) => {
				if (props.onChange) props.onChange(text);
			}, props.debounceTime || defaultDebounce),
		[props.onChange],
	);

	const onChange = (value: string) => {
		if (props.type === "number" || props.type === "currency") {
			if (!value) value = `${value}`;
			else
				value = `${clampNumber(
					+value,
					props.minValue ?? Number.MIN_SAFE_INTEGER,
					props.maxValue ?? Number.MAX_SAFE_INTEGER,
				)}`;
		}

		if (props.validateOnChange && !props.useFormik) validate(value);
		else setState({ ...state, value: value });

		if (props.debounceTime && props.debounceTime > 0) {
			debouncedChangeHandler(value);
		} else {
			if (props.onChange) props.onChange(value);
		}
	};

	const onBlur = () => {
		setState({ ...state, focused: false });
	};

	const onFocus = () => {
		setState({ ...state, focused: true });
	};

	const onTogglePeekPassword = () => {
		setState({ ...state, peekPassword: !state.peekPassword });
	};

	const disableScroll = (event: WheelEvent) => {
		event.preventDefault();
	};

	React.useEffect(() => {
		return () => {
			debouncedChangeHandler.cancel();
		};
	}, [debouncedChangeHandler]);

	React.useEffect(() => {
		if (props.value && props.value !== state.value) {
			setState({ ...state, value: props.value ? props.value : "" });
		}
	}, [props.value]);

	React.useEffect(() => {
		if (props.validateOnBlur && !props.useFormik) {
			if (canValidate) {
				if (!state.focused) {
					validate();
				}
			} else {
				setCanValidate(true);
			}
		}
	}, [state.focused]);

	React.useEffect(() => {
		if (props.defaultValue && !state.value)
			setState({ ...state, value: props.defaultValue });
	}, [props.defaultValue]);

	React.useEffect(() => {
		if (!containerRef || !containerRef.current || props.type !== "currency")
			return;

		var elements = (
			findDOMNode(containerRef.current) as Element
		).getElementsByTagName("input");

		for (var i = 0; i < elements.length; i++) {
			var element = elements[i];

			element.removeEventListener("wheel", disableScroll);

			if (props.scrollDisabled) {
				element.addEventListener("wheel", disableScroll);
			}
		}

		return () => {
			if (props.scrollDisabled) {
				for (var i = 0; i < elements.length; i++) {
					var element = elements[i];

					element.removeEventListener("wheel", disableScroll);
				}
			}
		};
	}, [props.scrollDisabled, props.type, containerRef.current]);

	return (
		<div ref={(ref) => (containerRef.current = ref)} className="w-full">
			<FormikControlWrapper
				component={
					<InputView
						{...props}
						peekPassword={state.peekPassword}
						value={state.value}
						valid={!(state.errors && state.errors.length > 0)}
						onChange={onChange}
						onBlur={onBlur}
						onFocus={onFocus}
						onTogglePeekPassword={onTogglePeekPassword}
					/>
				}
				error={state.errors ? state.errors[0] : undefined}
				focused={state.focused}
				name={props.name || "input"}
				useFormik={props.useFormik}
				value={state.value}
				validate={validate}
				validateOnBlur={props.validateOnBlur}
				validateOnChange={props.validateOnChange}
			/>
		</div>
	);
};

export { Input };
