import type { NotificationResponse } from "@app/entities";
import { apiLookup } from "@app/services";
import { parseError } from "@app/utils/parse-error";
import { isArray } from "lodash";
import type { AppThunk } from "..";
import {
	setNotifications,
	setNotificationsError,
	setNotificationsLoading,
} from "./notifications-slice";

export const getNotificationsAction =
	(
		callback?: (response?: NotificationResponse[] | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: NotificationResponse[] | string[] | undefined = undefined;

		try {
			dispatch(setNotificationsLoading(true));
			dispatch(setNotificationsError(null));

			var response = await apiLookup.getNotifications();

			if (isArray(response)) {
				dispatch(setNotifications(response));
				results = response;
			} else if (response.reasons) {
				dispatch(setNotificationsError(response.reasons));
				results = response.reasons;
			}
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setNotificationsError(reasons));
		} finally {
			callback && callback(results);

			dispatch(setNotificationsLoading(false));
		}
	};
