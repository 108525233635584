import { Disclosure, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";

interface AccordionCardState {
	open: boolean;
}

export const AccordionCard = (props: {
	children?: JSX.Element;
	className?: string;
	headerContent?: JSX.Element;
	locked?: boolean;
	open?: boolean;
	onToggleOpen?: (open?: boolean) => void;
}) => {
	const defaultState: AccordionCardState = {
		open: props.open || false,
	};
	const [state, setState] = useState<AccordionCardState>(defaultState);

	const onToggleOpen = () => {
		if (props.onToggleOpen) {
			props.onToggleOpen(!state.open);
		}
		setState({ ...state, open: !state.open });
	};

	useEffect(() => {
		if (props.open !== undefined && props.open !== state.open) {
			setState({ ...state, open: props.open });
		}
	}, [props.open]);

	return (
		<div className={["flex", "flex-col", "grow", props.className].join(" ")}>
			<Disclosure>
				<>
					{!props.children || props.locked ? (
						props.headerContent
					) : (
						<Disclosure.Button onClick={() => onToggleOpen()}>
							{props.headerContent}
						</Disclosure.Button>
					)}
				</>
				<Transition
					as={Fragment}
					show={state.open}
					enter="transform transition duration-100"
					enterFrom="opacity-0 scale-y-50"
					enterTo="opacity-100 scale-y-100"
					leave="transform duration-100 transition ease-in-out"
					leaveFrom="opacity-100 scale-y-100"
					leaveTo="opacity-0 scale-y-50"
				>
					<Disclosure.Panel className={"origin-top"}>
						{props.children ? props.children : undefined}
					</Disclosure.Panel>
				</Transition>
			</Disclosure>
		</div>
	);
};
