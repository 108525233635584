import { apiErrors } from "@app/constants/api-errors";
import { paths } from "@app/constants/paths";
import { useResetPassword, useThrottlingTimer } from "@app/helpers";
import type { RootState } from "@app/redux";
import { secondsToHoursMinutesSeconds } from "@app/utils/seconds-to-hours-minutes-seconds";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordView, type FormValues } from "./forgot-password-view";

interface ForgotPasswordState {
	errors?: string[];
	formattedThrottling?: string;
	step: number;
	validate: boolean;
}

const TICK_TIME = 1000;

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [resetPassword] = useResetPassword();

	const { throttlingTime } = useSelector((state: RootState) => state.auth);

	const [state, setState] = useState<ForgotPasswordState>({
		errors: [],
		step: 0,
		validate: false,
	});

	const onClearError = () => {
		setState({ ...state, errors: undefined });
	};

	const onLogin = () => {
		navigate(paths.login());
	};

	const onResetPassword = (values: FormValues) => {
		resetPassword(values.username || "", (errors) => {
			if (errors) {
				if (
					errors &&
					errors.length > 0 &&
					!errors.find((x) => x.includes(apiErrors.throttled))
				) {
					onResetPasswordFail(errors);
				}
				return;
			}

			onResetPasswordSuccess();
		});
	};

	const onResetPasswordSuccess = () => {
		setState({
			...state,
			step: 1,
			validate: false,
		});
	};

	const onResetPasswordFail = (errors?: string[], prompt?: boolean) => {
		setState({
			...state,
			errors: errors ?? [
				"An error occured while attempting to reset your password",
			],
			validate: true,
		});
	};

	const onUpdateThrottleFormatting = () => {
		const throttlingTimeAsMoment = moment(throttlingTime);

		const formattedThrottling = secondsToHoursMinutesSeconds(
			throttlingTime ? throttlingTimeAsMoment.diff(moment(), "seconds") : 0,
		);

		setState({
			...state,
			formattedThrottling:
				throttlingTime &&
				!isEmpty(formattedThrottling) &&
				throttlingTimeAsMoment.isAfter()
					? formattedThrottling
					: undefined,
		});
	};

	const onValidate = (errors: FormValues, submit: () => void) => {
		if (Object.keys(errors).length > 0) {
			onResetPasswordFail([
				"Please enter your username or email address in the field below",
			]);
			return;
		}

		submit();
	};

	useThrottlingTimer(throttlingTime, TICK_TIME, onUpdateThrottleFormatting);

	return (
		<ForgotPasswordView
			errors={state.errors}
			formattedThrottling={state.formattedThrottling}
			formSubmitted={state.step > 0}
			step={state.step}
			validate={state.validate}
			onClearError={onClearError}
			onLogin={onLogin}
			onResetPassword={onResetPassword}
			onValidate={onValidate}
		/>
	);
};

export default ForgotPassword;
