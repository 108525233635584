import type { ReferrerResponse } from "@app/entities";
import type { AppDispatch } from "@app/redux";
import { getReferrersAction } from "@app/redux/lookup/lookup-actions";
import { useDispatch } from "react-redux";

export const useGetReferrers = () => {
	const dispatch: AppDispatch = useDispatch();

	const getReferrers = (
		callback?: (response?: string[] | ReferrerResponse[]) => void,
	) => {
		dispatch(getReferrersAction(callback));
	};

	return [getReferrers];
};
