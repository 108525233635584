import { Modal } from "@app/components/modals/modal";
import { Button } from "@app/controls/button";
import type { RootState } from "@app/redux";
import { useSelector } from "react-redux";
import "./new-client-disclaimer-modal.css";

export const NewClientDisclaimerModal = (props: {
	open: boolean;
	onClose: () => void;
	onCloseTransitionEnd: () => void;
	onProceed: () => void;
}) => {
	const { language } = useSelector((state: RootState) => state.language);

	return (
		<Modal
			className="new-client-disclaimer-modal w-full h-full flex flex-col items-center"
			overlayColor="light"
			open={props.open}
			onClose={props.onClose}
			onCloseTransitionEnd={props.onCloseTransitionEnd}
		>
			<div className="new-client-disclaimer-container">
				<div className="heading mb-10">
					<h2 className="mb-5">Note</h2>
					<div className="heading-underline" />
				</div>
				<div
					className="my-9.5"
					dangerouslySetInnerHTML={{
						__html: `<p>You are about to add another client to your user account. This will allow you to manage their investment with Future Forex and therefore they will be required to sign a mandate giving you this permission.</p>
        <br/>
        <p>When a client you refer completes their first cycle with us, you’ll earn a <b>R750 referral reward</b>. Clients added to your user account are automatically considered as referred by you.</p>
        <br/>
        <p>Would you like to proceed?</p>`,
					}}
				/>
				<Button
					className="proceed-button mx-auto"
					type="button"
					theme="primary"
					onClick={props.onProceed}
				>
					PROCEED
				</Button>
			</div>
		</Modal>
	);
};
