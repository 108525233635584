import type { Profile, User } from "@app/entities";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	user?: User | null;
	userIsAuthenticated?: boolean;
	userError?: string[] | null;
	userLoading?: boolean;

	profile?: Profile | null;
	profileError?: string[] | null;
	profileLoading?: boolean;

	registerLoading?: boolean;
	registerError?: string[] | null;

	activateUserLoading?: boolean;
	activateUserError?: string[] | null;

	resendUserActivationLoading?: boolean;
	resendUserActivationError?: string[] | null;

	resetPasswordLoading?: boolean;
	resetPasswordError?: string[] | null;

	confirmPasswordResetLoading?: boolean;
	confirmPasswordResetError?: string[] | null;

	updateUserPasswordLoading?: boolean;
	updateUserPasswordError?: string[] | null;

	updateUserUsernameLoading?: boolean;
	updateUserUsernameError?: string[] | null;

	throttlingTime?: number;

	tokenHasExpired?: boolean;

	newPageLoad: boolean;
};

export const defaultState: State = {
	newPageLoad: true,
};

const slice = createSlice({
	name: "auth",
	initialState: defaultState,
	reducers: {
		setUserIsAuthenticated(state: State, action: PayloadAction<boolean>) {
			state.userIsAuthenticated = action.payload;
		},
		setUser(state: State, action: PayloadAction<User | null>) {
			state.user = action.payload;
		},
		setUserError(state: State, action: PayloadAction<string[] | null>) {
			state.userError = action.payload;
		},
		setUserLoading(state: State, action: PayloadAction<boolean>) {
			state.userLoading = action.payload;
		},

		setRegisterError(state: State, action: PayloadAction<string[] | null>) {
			state.registerError = action.payload;
		},
		setRegisterLoading(state: State, action: PayloadAction<boolean>) {
			state.registerLoading = action.payload;
		},

		setActivateUserError(state: State, action: PayloadAction<string[] | null>) {
			state.activateUserError = action.payload;
		},
		setActivateUserLoading(state: State, action: PayloadAction<boolean>) {
			state.activateUserLoading = action.payload;
		},

		setResendUserActivationError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.resendUserActivationError = action.payload;
		},
		setResendUserActivationLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.resendUserActivationLoading = action.payload;
		},

		setResetPasswordError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.resetPasswordError = action.payload;
		},
		setResetPasswordLoading(state: State, action: PayloadAction<boolean>) {
			state.resetPasswordLoading = action.payload;
		},

		setConfirmPasswordResetError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.confirmPasswordResetError = action.payload;
		},
		setConfirmPasswordResetLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.confirmPasswordResetLoading = action.payload;
		},

		setUpdateUserPasswordError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.updateUserPasswordError = action.payload;
		},
		setUpdateUserPasswordLoading(state: State, action: PayloadAction<boolean>) {
			state.updateUserPasswordLoading = action.payload;
		},

		setUpdateUserUsernameError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.updateUserUsernameError = action.payload;
		},
		setUpdateUserUsernameLoading(state: State, action: PayloadAction<boolean>) {
			state.updateUserUsernameLoading = action.payload;
		},

		setProfile(state: State, action: PayloadAction<Profile | null>) {
			state.profile = action.payload;
		},
		setProfileError(state: State, action: PayloadAction<string[] | null>) {
			state.profileError = action.payload;
		},
		setProfileLoading(state: State, action: PayloadAction<boolean>) {
			state.profileLoading = action.payload;
		},

		setThrottlingTime(state: State, action: PayloadAction<number>) {
			state.throttlingTime = action.payload;
		},

		setTokenHasExpired(state: State, action: PayloadAction<boolean>) {
			state.tokenHasExpired = action.payload;
		},

		setNewPageLoad(state: State, action: PayloadAction<boolean>) {
			state.newPageLoad = action.payload;
		},
	},
});

export const {
	setUserIsAuthenticated,
	setUserLoading,
	setUser,
	setUserError,

	setProfile,
	setProfileError,
	setProfileLoading,

	setRegisterError,
	setRegisterLoading,

	setActivateUserError,
	setActivateUserLoading,

	setResendUserActivationError,
	setResendUserActivationLoading,

	setResetPasswordError,
	setResetPasswordLoading,

	setConfirmPasswordResetError,
	setConfirmPasswordResetLoading,

	setUpdateUserPasswordError,
	setUpdateUserPasswordLoading,

	setUpdateUserUsernameError,
	setUpdateUserUsernameLoading,

	setThrottlingTime,

	setTokenHasExpired,

	setNewPageLoad,
} = slice.actions;

export default slice.reducer;
