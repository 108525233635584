import type { ReferrerResponse } from "@app/entities";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	referrers?: ReferrerResponse[] | null;
	referrersError?: string[] | null;
	referrersLoading?: boolean;
};

export const defaultState: State = {};

const slice = createSlice({
	name: "lookup",
	initialState: defaultState,
	reducers: {
		setReferrers(state, action: PayloadAction<ReferrerResponse[]>) {
			state.referrers = action.payload;
		},
		setReferrersError(state: State, action: PayloadAction<string[] | null>) {
			state.referrersError = action.payload;
		},
		setReferrersLoading(state: State, action: PayloadAction<boolean>) {
			state.referrersLoading = action.payload;
		},
	},
});

export const { setReferrers, setReferrersError, setReferrersLoading } =
	slice.actions;

export default slice.reducer;
