const messages = {
	en: {
		accountSelectionHeader: "Select an account",
		accountsHeader: "Your accounts",
		spousesAccountsHeader: "Spouse's accounts",
		bankNameText: "Bank Name",
		bankName: "",
		accountNumberText: "Account Number",
		accountNumber: "",
		addNewBankAccountButton: "ADD BANK ACCOUNT",
		withdrawAllDropdownItems: {
			yes: "Yes",
			no: "No",
		},
		noResults: "No Accounts to display",
	},
};

export default messages;
