import React from "react";
import "./checkbox.css";
import type { ViewProperties } from "./properties";

export const CheckboxView = React.memo((props: ViewProperties) => {
	const getClassNames = () => {
		var classesToUse = ["checkbox flex checkbox-text cursor-pointer"];

		if (props.className) classesToUse.push(props.className);

		return classesToUse.join(" ");
	};

	const Checkbox = () => {
		var boxClasses = ["box flex-none relative mr-2"];

		if (props.required && props.hasError && !props.hideValidation)
			boxClasses.push("invalid");

		return (
			<div className={boxClasses.join(" ")}>
				<input
					name={props.name}
					className="w-0 h-0 z-10 invisible"
					type="checkbox"
					required={props.required}
					defaultChecked={props.checked}
				/>
				{props.checked && <div className="tick flex absolute rotate-45" />}
			</div>
		);
	};

	return (
		<div className={getClassNames()} onClick={props.onClick} tabIndex={0}>
			<Checkbox />
			<label className="my-auto cursor-pointer">{props.label}</label>
		</div>
	);
});
