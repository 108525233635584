import type { CreateWithdrawalAccount, WithdrawalAccount } from "@app/entities";
import type { AppDispatch, RootState } from "@app/redux";
import {
	createWithdrawalBankAction,
	destroyWithdrawalBankAction,
	requestWithdrawalAction,
	retrieveWithdrawalBankAction,
	retrieveWithdrawalBankListAction,
	updateWithdrawalBankAction,
} from "@app/redux/withdrawal/withdrawal-actions";
import {
	setRequestWithdrawalError,
	setRetrieveWithdrawalBankListError,
	setUpdateWithdrawalBankError,
} from "@app/redux/withdrawal/withdrawal-slice";
import type { MappedReasons } from "@app/services";
import { useDispatch, useSelector } from "react-redux";

export const useCreateWithdrawalBank = () => {
	const dispatch: AppDispatch = useDispatch();

	const createWithdrawalBank = (
		account: CreateWithdrawalAccount,
		callback?: (
			success: boolean,
			response?: string[],
			mappedReasons?: MappedReasons,
		) => void,
	) => {
		dispatch(createWithdrawalBankAction(account, callback));
	};

	return [createWithdrawalBank];
};

export const useDestroyWithdrawalBank = () => {
	const dispatch: AppDispatch = useDispatch();

	const destroyWithdrawalBank = (
		bankId: number,
		callback?: (success: boolean, response?: string[]) => void,
	) => {
		dispatch(destroyWithdrawalBankAction(bankId, callback));
	};

	return [destroyWithdrawalBank];
};

export const useRequestWithdrawal = () => {
	const dispatch: AppDispatch = useDispatch();
	var { activeClient } = useSelector((state: RootState) => state.clients);

	const requestWithdrawal = (
		amount: string | null,
		withdrawAll: boolean,
		bankId: number,
		straightThroughProcess: boolean,
		callback?: (
			success: boolean,
			response?: string[],
			mappedReasons?: MappedReasons,
		) => void,
	) => {
		if (activeClient)
			dispatch(
				requestWithdrawalAction(
					activeClient.id,
					amount,
					withdrawAll,
					bankId,
					straightThroughProcess,
					callback,
				),
			);
		else {
			var messages = ["No active client selected"];

			dispatch(setRequestWithdrawalError(messages));

			callback && callback(false, messages);
		}
	};

	return [requestWithdrawal];
};

export const useRetrieveWithdrawalBank = () => {
	const dispatch: AppDispatch = useDispatch();

	const retrieveWithdrawalBank = (
		bankId: number,
		callback?: (
			success: boolean,
			response?: string[] | WithdrawalAccount,
		) => void,
	) => {
		dispatch(retrieveWithdrawalBankAction(bankId, callback));
	};

	return [retrieveWithdrawalBank];
};

export const useRetrieveWithdrawalBankList = () => {
	const dispatch: AppDispatch = useDispatch();
	var { activeClient } = useSelector((state: RootState) => state.clients);

	const retrieveWithdrawalBankList = (
		callback?: (
			success: boolean,
			response?: string[] | WithdrawalAccount[],
		) => void,
	) => {
		if (activeClient)
			dispatch(retrieveWithdrawalBankListAction(activeClient.id, callback));
		else {
			var messages = ["No active client selected"];

			dispatch(setRetrieveWithdrawalBankListError(messages));

			callback && callback(false, messages);
		}
	};

	return [retrieveWithdrawalBankList];
};

export const useUpdateWithdrawalBank = () => {
	const dispatch: AppDispatch = useDispatch();
	var { activeClient } = useSelector((state: RootState) => state.clients);

	const updateWithdrawalBank = (
		account: CreateWithdrawalAccount,
		callback?: (
			success: boolean,
			response?: string[],
			mappedReasons?: MappedReasons,
		) => void,
	) => {
		if (activeClient)
			dispatch(updateWithdrawalBankAction(account.id, account, callback));
		else {
			var messages = ["No active client selected"];

			dispatch(setUpdateWithdrawalBankError(messages));

			callback && callback(false, messages);
		}
	};

	return [updateWithdrawalBank];
};
