import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import type { Properties } from "./properties";

import "./paginator.css";

const DEFAULT_MAX_BLOCK_SIZE = 3;

export interface PaginatorState {
	blockSize: number;
	currentPage: number;
	totalPages: number;
}

export const Paginator = (props: {
	blockSize?: number;
	currentPage: number;
	showFixedFirstPage?: boolean;
	showFixedLastPage?: boolean;
	totalPages: number;
	onNavigatePage: (page: number) => void;
}) => {
	const [state, setState] = useState<PaginatorState>({
		blockSize: 3,
		currentPage: props.currentPage || 1,
		totalPages: props.totalPages || 0,
	});

	const blockDelta = state.currentPage % state.blockSize;

	const onNavigatePage = (page: number) => {
		const viablePage = Math.max(1, Math.min(state.totalPages, page));

		setState({ ...state, currentPage: viablePage });

		props.onNavigatePage(viablePage);
	};

	const onNavigateNextBlock = () => {
		const candidatePage = Math.min(
			state.currentPage +
				state.blockSize -
				(blockDelta > 0 ? blockDelta : state.blockSize) +
				1,
			state.totalPages,
		);

		onNavigatePage(candidatePage);
	};

	const onNavigatePreviousBlock = () => {
		const candidatePage = Math.max(
			state.currentPage - (blockDelta > 0 ? blockDelta : state.blockSize),
			1,
		);

		onNavigatePage(candidatePage);
	};

	const onNavigateNextPage = () => {
		onNavigatePage(state.currentPage + 1);
	};

	const onNavigatePreviousPage = () => {
		onNavigatePage(state.currentPage - 1);
	};

	useEffect(() => {
		setState({ ...state, totalPages: props.totalPages });
	}, [props.totalPages]);

	useEffect(() => {
		setState({
			...state,
			blockSize: Math.max(1, props.blockSize || DEFAULT_MAX_BLOCK_SIZE),
		});
	}, [props.blockSize]);

	const currentBlock = Math.floor((props.currentPage - 1) / state.blockSize);
	const totalBlocks = Math.floor((props.totalPages - 1) / state.blockSize);

	const getLeadingEllipsisContent = () =>
		currentBlock > 0 ? (
			<>
				{props.showFixedFirstPage && props.totalPages > 0 && (
					<button
						type="button"
						className={getPageNumberStyle(1)}
						onClick={() => props.onNavigatePage(1)}
					>
						1
					</button>
				)}
				<button
					type="button"
					className="font-primary-bold paginator-clickable"
					onClick={onNavigatePreviousBlock}
				>
					...
				</button>
			</>
		) : undefined;

	const getTrailingEllipsisContent = () =>
		currentBlock !== totalBlocks ? (
			<>
				<button
					type="button"
					className="font-primary-bold paginator-clickable"
					onClick={onNavigateNextBlock}
				>
					...
				</button>
				{props.showFixedLastPage && props.totalPages > 0 && (
					<button
						type="button"
						key={props.totalPages}
						className={getPageNumberStyle(props.totalPages)}
						onClick={() => props.onNavigatePage(props.totalPages)}
					>
						{props.totalPages}
					</button>
				)}
			</>
		) : undefined;

	const isStartPageEnabled = props.currentPage > 1;
	const isNextPageEnabled = props.currentPage < props.totalPages;

	const getPageNumberContent = () => {
		const startPage =
			(Math.floor((props.currentPage - 1) / state.blockSize) + 1) *
				state.blockSize -
			state.blockSize;
		const endPage = Math.min(startPage + state.blockSize, props.totalPages);

		return [...Array(endPage - startPage)].map((item, index) => {
			const pageNumber = index + startPage + 1;
			return (
				<button
					type="button"
					key={pageNumber}
					className={getPageNumberStyle(pageNumber)}
					onClick={() => props.onNavigatePage(pageNumber)}
				>
					{pageNumber}
				</button>
			);
		});
	};

	const getPageNumberStyle = (page: number) => {
		const pageNumberStyle = ["font-primary-bold"];
		if (page === props.currentPage) {
			pageNumberStyle.push("paginator-active");
		}
		pageNumberStyle.push("paginator-clickable");

		return pageNumberStyle.join(" ");
	};

	const getPagerStyle = (enabled: boolean) => {
		const pagerStyle = [
			"flex",
			"items-center",
			"paginator-clickable",
			"w-auto",
			"h-4",
		];
		if (!enabled) {
			pagerStyle.push("paginator-chevron--disabled");
		}
		return pagerStyle.join(" ");
	};

	if (props.totalPages === 0) {
		return null;
	}

	return (
		<div className="flex flex-wrap justify-evenly lg:gap-x-2.5 lg:justify-center lg:w-auto max-h-full min-w-min text-lg w-3/4">
			<button
				type="button"
				className="my-auto"
				onClick={isStartPageEnabled ? onNavigatePreviousPage : undefined}
			>
				<FaChevronLeft
					className={getPagerStyle(isStartPageEnabled)}
					size="16px"
				/>
			</button>
			{getLeadingEllipsisContent()}
			{getPageNumberContent()}
			{getTrailingEllipsisContent()}
			<button
				type="button"
				className="my-auto"
				onClick={isNextPageEnabled ? onNavigateNextPage : undefined}
				aria-label="Next Page"
			>
				<FaChevronRight
					className={getPagerStyle(isNextPageEnabled)}
					size="16px"
				/>
			</button>
		</div>
	);
};
