import { FormikControlWrapperView } from "./formik-control-wrapper-view";
import type { Properties } from "./properties";

export const FormikControlWrapper = (props: Properties) => {
	return props.useFormik ? (
		<FormikControlWrapperView {...props} />
	) : (
		props.component
	);
};
