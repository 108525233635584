import { BarGraph } from "@app/components/bar-graph";
import React from "react";
import "./allowance-card.css";
import type { FiaDetails, SdaDetails } from "@app/entities";
import type { Detail } from "../bar-graph/models/detail";

export type AllowanceType = "fia" | "sda";
export interface Allowance {
	allowanceType?: AllowanceType;
	heading: string;
	valueLower: number;
	valueUpper: number;
}

const AllowanceCardView = React.memo(
	(props: {
		mobile?: boolean;
		allowances?: Allowance[];
		fiaDetails?: FiaDetails | null;
		sdaDetails?: SdaDetails | null;
		className?: string;
		getFiaDetails: () => Detail[] | undefined;
		getSdaDetails: () => Detail[] | undefined;
	}) => {
		const getAllowanceBarsContent = () => {
			if (
				!props.allowances ||
				(props.allowances && props.allowances.length === 0)
			) {
				return undefined;
			}

			return props.allowances.map((allowance, index) => {
				let details: Detail[] = [];
				let tooltipHeading: string;
				let showTooltipTotal = false;
				let tooltipRowsToShow = 3;
				let graphFillClassNumber = "three";
				let addZIndex = false;
				let totalAmount = 0;

				if (allowance.allowanceType === "fia" && props.fiaDetails) {
					details = props.getFiaDetails() ?? [];
					tooltipHeading = "AIT (FIA) Details: ";
					showTooltipTotal = true;
					totalAmount = details.reduce((left, right) => left + right.amount, 0);
				} else if (allowance.allowanceType === "sda" && props.sdaDetails) {
					details = props.getSdaDetails() ?? [];
					tooltipHeading = "SDA Details: ";
					tooltipRowsToShow = 2;
					graphFillClassNumber = "two";
					addZIndex = true;
					totalAmount = details
						.filter((x) => x.label)
						.reduce((left, right) => left + right.amount, 0);
				}

				return (
					<BarGraph
						allowance={allowance}
						details={details}
						key={index}
						graphFillClassNumber={graphFillClassNumber}
						tooltipHeading={tooltipHeading}
						showTooltipTotal={showTooltipTotal}
						tooltipRowsToShow={tooltipRowsToShow}
						addZIndex={addZIndex}
						totalAmount={totalAmount}
					/>
				);
			});
		};

		const colStyle = [
			"flex",
			"flex-col",
			"flex-1",
			"lg:px-7",
			"py-6",
			"px-5",
			"allowance",
		].join(" ");

		const headingStyle = ["font-primary-bold", "allowance-text"].join(" ");

		const allowanceBarsStyle = [
			"flex",
			"flex-col",
			"justify-evenly",
			"flex-1",
		].join(" ");

		return (
			<div
				className={[colStyle, "relative z-10", props.className ?? ""]
					.join(" ")
					.trim()}
			>
				<div className={headingStyle}>Allowances</div>
				<div className={allowanceBarsStyle}>{getAllowanceBarsContent()}</div>
			</div>
		);
	},
);

export { AllowanceCardView };
