import { apiErrors, loginErrors } from "@app/constants/api-errors";
import { pathParams, paths } from "@app/constants/paths";
import type { PeriodSettingsResponse, User } from "@app/entities";
import {
	useActivateUser,
	useGetClient,
	useGetClientSummaries,
	useListPeriodSettings,
	useLogin,
	useResendUserActivation,
} from "@app/helpers";
import type { RootState } from "@app/redux";
import { isArray } from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { type FormValues, LoginView } from "./login-view";

interface LoginState {
	activationStatus?: "success" | "error" | "stale";
	addressForResend?: string;
	errors: string[];
	openModal: boolean;
	openResetModal: boolean;
	resendEmail: boolean;
	isResendingEmail: boolean;
	resetError?: string;
	resetStatus?: boolean;
	showResendSuccessModal?: boolean;
	validate: boolean;
}

const Login = () => {
	const [activateUser] = useActivateUser();
	const [loginUser] = useLogin();
	const [searchParams] = useSearchParams();
	const [getClientSummaries] = useGetClientSummaries();
	const [getClient] = useGetClient();
	const [listPeriodSettings] = useListPeriodSettings();
	const [resendUserActivation] = useResendUserActivation();

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const params = useParams();
	const paramUser = params[pathParams.user];
	const paramToken = params[pathParams.token];

	const { activateUserLoading, userIsAuthenticated, userLoading } = useSelector(
		(rootState: RootState) => rootState.auth,
	);

	const { periodSettingsLoading } = useSelector(
		(rootState: RootState) => rootState.arbitrager,
	);

	const [state, setState] = React.useState<LoginState>({
		activationStatus: undefined,
		addressForResend: undefined,
		errors: [],
		openModal: searchParams.get("inactive") === "true",
		openResetModal: false,
		resendEmail: false,
		isResendingEmail: false,
		resetStatus: undefined,
		showResendSuccessModal: false,
		validate: false,
	});

	const fetchPeriodSettings = (callback?: () => void) => {
		listPeriodSettings(
			(settingsResponse?: PeriodSettingsResponse | string[] | undefined) => {
				const responseAsSettings = settingsResponse as PeriodSettingsResponse;

				if (
					settingsResponse &&
					!("reasons" in settingsResponse) &&
					!isArray(settingsResponse) &&
					responseAsSettings &&
					responseAsSettings.marketChart.periods.length > 0
				) {
					callback?.();
				} else {
					onLoginFail(isArray(settingsResponse) ? settingsResponse : undefined);
				}
			},
		);
	};

	const getIsLoading = () => {
		return activateUserLoading || userLoading || periodSettingsLoading;
	};

	const resendActivationEmail = () => {
		if (state.resendEmail && state.addressForResend) {
			setState((current) => ({ ...current, isResendingEmail: true }));
			resendUserActivation(
				state.addressForResend,
				(errors?: string[] | undefined) => {
					setState((current) => ({ ...current, isResendingEmail: false }));
					if (!errors) {
						setState({
							...state,
							openModal: false,
							resendEmail: false,
							showResendSuccessModal: true,
						});
					} else {
						const outputError =
							errors &&
							errors.length > 0 &&
							!errors.find((x) => x.includes(apiErrors.throttled))
								? errors
								: state.errors;

						setState({
							...state,
							errors: outputError,
							resendEmail: false,
						});
					}
				},
			);
		}
	};

	const onClearError = () => {
		setState({ ...state, errors: [] });
	};

	const onClose = () => {
		setState({ ...state, openModal: false });
	};

	const onCloseResendSuccess = () => {
		setState({ ...state, showResendSuccessModal: false });
	};

	const onCloseReset = (passwordResetSuccessfully?: boolean) => {
		setState({
			...state,
			openResetModal: false,
			resetStatus: passwordResetSuccessfully,
		});
	};

	const onForgotPassword = () => {
		navigate(paths.forgotPassword);
	};

	const onLogin = async (values: FormValues) => {
		try {
			loginUser(values.username || "", values.password || "", (user: User) => {
				if (!user.isAuthentic) {
					if ((user.reasons || []).indexOf(loginErrors.inactiveAccount) > -1) {
						setState({
							...state,
							addressForResend: values.username,
							errors: [],
							openModal: true,
						});
					} else {
						onLoginFail([
							"Wrong email or password. Please check these and try again.",
						]);
					}
				} else if (user.isAuthentic) {
					window.localStorage.setItem("login-source", "arb");
					getClientSummaries(0, undefined, (response) => {
						if (response && "total" in response) {
							fetchPeriodSettings(() => {
								if (response.total === 0) {
									navigate(paths.dashboard);
								} else if (response.total === 1) {
									getClient(response.clients[0].id, () => {
										navigate(paths.dashboard);
									});
								} else navigate(paths.clients);
							});
						} else {
							onLoginFail(response);
						}
					});
				}
			});
		} catch (error) {
			onLoginFail();
		}
	};

	const onValidate = (errors: FormValues, submit: () => void) => {
		if (Object.keys(errors).length > 0) {
			onLoginFail(["Please enter both fields"]);
			return;
		}
		submit();
	};

	const onLoginFail = (error?: string[]) => {
		const outputError = error || [
			"An error occured while attempting to log in",
		];

		setState({
			...state,
			errors: outputError,
			validate: true,
		});
	};

	const onRegister = () => {
		navigate(paths.register);
	};

	const onResend = () => {
		setState({ ...state, resendEmail: true });
	};

	const onResetPassword = () => {
		setState({ ...state, openResetModal: true });
	};

	const onResetStatus = () => {
		navigate(paths.login());
	};

	const onVerifyAccount = () => {
		if (paramUser && paramToken) {
			activateUser(paramUser, paramToken, (errors?: string[] | undefined) => {
				setState({
					...state,
					activationStatus:
						errors === undefined
							? "success"
							: errors[0]?.includes("Stale token for given user.")
								? "stale"
								: "error",
				});
			});
		}
	};

	useEffect(() => {
		if (
			pathname.includes(`${paths.activationBase}/${paramUser}/${paramToken}`)
		) {
			onVerifyAccount();
		} else if (
			pathname.includes(`${paths.resetBase}/${paramUser}/${paramToken}`)
		) {
			onResetPassword();
		} else {
			if (userIsAuthenticated) {
				navigate(paths.dashboard);
			}
		}
	}, []);

	useEffect(() => {
		if (state.resendEmail && state.addressForResend) {
			resendActivationEmail();
		}
	}, [state.resendEmail]);

	return (
		<LoginView
			activationStatus={state.activationStatus}
			errors={state.errors}
			loading={getIsLoading()}
			openModal={state.openModal}
			openResetModal={state.openResetModal}
			paramToken={paramToken}
			paramUser={paramUser}
			resetError={state.resetError}
			resetStatus={state.resetStatus}
			isResendingEmail={state.isResendingEmail}
			showResendSuccessModal={state.showResendSuccessModal}
			validate={state.validate}
			onClearError={onClearError}
			onClose={onClose}
			onCloseResendSuccess={onCloseResendSuccess}
			onCloseReset={onCloseReset}
			onForgotPassword={onForgotPassword}
			onLogin={onLogin}
			onRegister={onRegister}
			onResend={onResend}
			onResetStatus={onResetStatus}
			onValidate={onValidate}
		/>
	);
};

export default Login;
