const messages = {
	en: {
		confirmPassword: "Password Confirmation",
		currentPassword: "Old Password",
		error: {
			passwordMismatch: "Password Confirmation must match your new password",
		},
		header: "Change password",
		headerReset: "Reset password",
		newPassword: "New Password",
		submit: "SUBMIT",
		tooltip: {
			confirmPassword: "Please re-enter your new password",
			currentPassword: "Please enter your current password",
			newPassword: "Please enter your new password",
		},
	},
};

export default messages;
