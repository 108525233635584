import { Listbox, Transition } from "@headlessui/react";
import { isEmpty } from "lodash";
import React, { createRef } from "react";
import { usePopper } from "react-popper";
import "./dropdown.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import type { ViewProperties } from "./properties";

export const DropdownView = React.memo((props: ViewProperties) => {
	const referenceElement = createRef<HTMLButtonElement>();
	const popperElement = createRef<HTMLUListElement>();

	const onFirstUpdate = React.useCallback((state: any) => {
		/* On initial display of dropdown with bottom placement, popper offsets the dropdown so its not flush against the toggle.
        This unsets that transform */
		if (state.placement === "bottom")
			state.elements.popper.style.transform = "translate3d(0px, 0px, 0px)";
	}, []);

	const { styles, attributes } = usePopper(
		referenceElement.current,
		popperElement.current,
		{
			placement: "bottom",
			onFirstUpdate: onFirstUpdate,
		},
	);

	const getDropdownClassNames = (open: boolean) => {
		const classesToUse = ["dropdown flex relative cursor-pointer"];

		switch (props.theme) {
			case "primary":
				classesToUse.push("dropdown-primary");
				break;
			case "secondary":
				classesToUse.push("dropdown-secondary");
				break;
			case "navbar":
				classesToUse.push("dropdown-navbar");
				break;
		}

		if (open) classesToUse.push("open");

		if (props.className) classesToUse.push(props.className);

		if (props.required && props.hasError && !props.hideValidation)
			classesToUse.push("invalid");

		return classesToUse.join(" ");
	};

	const getDropdownHeader = () => {
		const headerClasses = ["text-left flex-auto"];
		const labelClasses = ["label"];
		let text = "";
		let showHeader = true;

		if (props.selectedItem && !props.fixedHeader) {
			headerClasses.push("selected-item");

			text = props.selectedItem.label || props.selectedItem.value;

			if (text && !isEmpty(text)) {
				headerClasses.push("selection-appended");
			}

			showHeader = false;

			if (props.theme === "secondary") {
				labelClasses.push("text-sm");
				showHeader = true;
			}
		} else headerClasses.push("header");

		return (
			<div className={headerClasses.join(" ")}>
				{showHeader && (
					<div className={labelClasses.join(" ")}>{props.header}</div>
				)}
				{text}
			</div>
		);
	};

	const getCaretIcon = (open: boolean) => {
		if (props.chevron) {
			return props.chevron;
		}

		if (open)
			return (
				<FaChevronUp size="16px" className="chevron flex-none mr-2 ml-2" />
			);
		return (
			<FaChevronDown size="16px" className="chevron flex-none mr-2 ml-2" />
		);
	};

	const getDropdownItems = () => {
		return props.items.map((x) => {
			return (
				<Listbox.Option key={x.value} value={x} disabled={x.disabled}>
					{({ active, selected }) => {
						const classesToUse = ["dropdown-item"];

						if (active) classesToUse.push("active");

						if (selected) classesToUse.push("selected");

						return (
							<div className={classesToUse.join(" ")}>{x.label || x.value}</div>
						);
					}}
				</Listbox.Option>
			);
		});
	};

	return (
		<Listbox
			disabled={props.disabled}
			value={props.selectedItem}
			onChange={(item) => item && props.onItemSelect(item)}
		>
			{({ open }) => {
				if (open !== props.open) props.onToggle(open);
				return (
					<div className={getDropdownClassNames(open)}>
						<Listbox.Button
							className="dropdown-header flex flex-auto justify-between items-center"
							ref={referenceElement}
						>
							{getDropdownHeader()}
							{getCaretIcon(open)}
						</Listbox.Button>
						<Transition
							className="absolute top-full w-full overflow-hidden"
							enter="transition duration-300 ease-out"
							enterFrom="transform -translate-y-1 opacity-0"
							enterTo="transform translate-y-0 opacity-100"
						>
							<Listbox.Options
								className="dropdown-menu w-full"
								style={styles.popper}
								ref={popperElement}
								{...attributes.popper}
							>
								{getDropdownItems()}
							</Listbox.Options>
						</Transition>
					</div>
				);
			}}
		</Listbox>
	);
});
