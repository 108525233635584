export const provinceOther = "Other";

export const provinceList = [
	"Eastern Cape",
	"Free State",
	"Gauteng",
	"Kwazulu-Natal",
	"Limpopo",
	"Mpumalanga",
	"Northern Cape",
	"North West",
	"Western Cape",
	provinceOther,
] as const;

export type Province = (typeof provinceList)[number];
