import type { AccountType, WithdrawalAccount } from "@app/entities";
import { useCreateWithdrawalBank, useUpdateWithdrawalBank } from "@app/helpers";
import type { RootState } from "@app/redux";
import type { MappedReasons } from "@app/services";
import { useState } from "react";
import { useSelector } from "react-redux";
import { BankAccountModalView } from "./bank-account-modal-view";
import type { FormErrors, FormValues } from "./models/form";
import type { State } from "./models/state";

export const BankAccountModal = (props: {
	open: boolean;
	errors?: string[];
	onClose: () => void;
	mode: "add" | "edit";
	bankAccount: WithdrawalAccount;
}) => {
	const { activeClient } = useSelector((state: RootState) => state.clients);

	const defaultState: State = {
		form: {
			clientId: activeClient?.id,
			bankName: props.bankAccount?.bankName || "",
			branchCode: props.bankAccount?.branchCode || "",
			accountType: props.bankAccount?.accountType || "",
			accountNumber: props.bankAccount?.accountNumber || "",
			accessibleBy: props.bankAccount?.accessibleBy || "",
			id: props.bankAccount?.id || undefined,
		},
		accountTypes: [],
		hasSpouse: !!activeClient?.spouse,
		validate: false,
	};

	const [createWithdrawalBank] = useCreateWithdrawalBank();
	const [updateWithdrawalBank] = useUpdateWithdrawalBank();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [state, setState] = useState<State>(defaultState);
	const [mappedErrors, setMappedErrors] = useState<MappedReasons | undefined>(
		undefined,
	);

	const mapFormValuesToBankAccount = (
		values: FormValues,
	): WithdrawalAccount => {
		return {
			accessibleBy: !!values.accessibleBy ? "both" : "client",
			accountNumber: values.accountNumber as string,
			accountType: values.accountType as AccountType,
			bankName: values.bankName as string,
			branchCode: values.branchCode as string,
			clientId: (values.clientId ?? 0) as number,
			id: (values.id ?? 0) as number,
		};
	};

	const onSubmit = (values: FormValues) => {
		const newState: State = {
			...state,
			form: {
				...state.form,
				...values,
			},
		};

		setIsSubmitting(true);

		(props.mode === "add" ? createWithdrawalBank : updateWithdrawalBank)(
			mapFormValuesToBankAccount(newState.form),
			(success, response, mappedReasons) => {
				setIsSubmitting(false);
				if (!success) {
					setMappedErrors(mappedReasons);
					return;
				}
				setState(() => newState);
				props.onClose();
			},
		);

		setState(() => newState);
	};

	const onValidate = (errors: FormErrors, submit: () => void) => {
		if (Object.keys(errors).length > 0) {
			setState({ ...state, validate: true });
			return;
		}

		submit();
	};

	const onClearErrors = (name?: string) => {
		if (name) {
			const newMappedErrors = { ...mappedErrors };
			newMappedErrors[name] = undefined;
			setMappedErrors(newMappedErrors);
		} else setMappedErrors(undefined);
	};

	const onClose = () => {
		const newState = {
			...defaultState,
		};

		setState(() => newState);
		onClearErrors();
		props.onClose();
	};

	const onResetForm = () => {
		onClearErrors();
	};

	return (
		<BankAccountModalView
			{...props}
			defaultValues={state.form}
			mappedErrors={mappedErrors}
			validate={state.validate}
			onClearErrors={onClearErrors}
			onSubmit={onSubmit}
			isSubmitting={isSubmitting}
			onClose={onClose}
			onValidate={onValidate}
			onResetForm={onResetForm}
			hasSpouse={state.hasSpouse}
		/>
	);
};
