export function parseError(
	error: any,
	defaultMessage = "An unknown error has occurred",
): string {
	if (!error) return defaultMessage;
	if (typeof error === "string") return error;
	if (error.message && typeof error.message === "string") return error.message;
	if (error.response) {
		if (error.response.data && typeof error.response.data === "string") {
			return error.response.data;
		}
		return error.response.toString();
	}
	return error.toString();
}
