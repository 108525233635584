export const validationErrorOptions = [
	"currency",
	"custom",
	"date",
	"email",
	"maxLength",
	"minLength",
	"numeric",
	"required",
	"telephone",
] as const;

export type ValidationErrors = (typeof validationErrorOptions)[number];
