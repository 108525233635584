import errorMark from "@app/assets/images/error-mark.svg";
import successTick from "@app/assets/images/wizard-last-step.svg";
import { Modal } from "@app/components/modals/modal";
import { links } from "@app/constants/links";
import { Button } from "@app/controls/button";
import type { ReactNode } from "react";
import "./status-modal.css";

export const StatusModal = (props: {
	buttonContent?: ReactNode;
	className?: string;
	heading?: string;
	headingClassName?: string;
	message?: ReactNode;
	messageClassName?: string;
	open: boolean;
	showCloseButton?: boolean;
	status?: "ERROR" | "GENERAL" | "SUCCESS";
	onClose?: () => void;
}) => {
	const getStatusIcon = () => {
		const errorIcon = (
			<img className="mx-auto" src={errorMark} alt="error-mark" />
		);
		const successIcon = (
			<img className="mx-auto" src={successTick} alt="success-tick" />
		);

		if (!props.status) {
			return undefined;
		}

		switch (props.status) {
			case "ERROR": {
				return errorIcon;
			}
			case "SUCCESS": {
				return successIcon;
			}
			case "GENERAL":
			default: {
				return undefined;
			}
		}
	};

	const getStatusHeading = () => {
		if (props.heading) {
			return props.heading;
		}

		const generalMessage = "Information";

		if (!props.status) {
			generalMessage;
		}

		switch (props.status) {
			case "ERROR": {
				return "Apologies, you've encountered an error";
			}
			case "SUCCESS": {
				return "Success";
			}
			case "GENERAL":
			default: {
				return generalMessage;
			}
		}
	};

	const agreementStyle = [
		"font-primary-regular",
		"text-center",
		props.messageClassName,
	].join(" ");

	const containerStyle = ["flex", "flex-col", "max-w-max", "gap-y-6"].join(" ");

	const headingStyle = [
		"font-primary-bold",
		"text-center",
		"status-modal-header",
		props.headingClassName,
	].join(" ");

	const isErrorStatus = props.status === "ERROR";

	return (
		<Modal
			className={["status-modal", props.className].join(" ")}
			open={props.open}
			noCloseButton={
				props.showCloseButton === undefined ? true : !props.showCloseButton
			}
			onClose={props.showCloseButton ? props.onClose : undefined}
		>
			<div className={containerStyle}>
				{getStatusIcon()}
				<h1 className={headingStyle}>{getStatusHeading()}</h1>

				<div className={agreementStyle}>
					<p className="status-modal-contact">
						{props.message ? (
							typeof props.message === "string" ? (
								<p>{props.message}</p>
							) : (
								props.message
							)
						) : null}
						{isErrorStatus && (
							<p>
								Contact us at{" "}
								<a
									href={links.getInTouch.emailAddress}
									target="_blank"
									rel="noreferrer"
								>
									info@futureforex.co.za
								</a>{" "}
								if you need help.
							</p>
						)}
					</p>
				</div>
				<Button
					className="font-primary-medium pt-3 pb-3"
					theme="primary"
					onClick={props.onClose}
				>
					OK
				</Button>
			</div>
		</Modal>
	);
};
