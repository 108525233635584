import type { NotificationResponse } from "@app/entities";
import type { AppDispatch } from "@app/redux";
import { setNotificationReadAction } from "@app/redux/notifications-read/notifications-read-actions";
import { useDispatch } from "react-redux";

export const useSetNotificationRead = () => {
	const dispatch: AppDispatch = useDispatch();

	const setNotificationRead = (notification: NotificationResponse) => {
		dispatch(setNotificationReadAction(notification));
	};

	return [setNotificationRead];
};
