function parseUrl(url: string): string {
	return url.replace(/^\/+|\/+$/g, "");
}

/**
 * Joins strings into a relative url:  a, b, c => /a/b/c
 * @param urls
 */
export function joinUrl(...urls: string[]): string {
	const parsedUrl = urls.map(parseUrl).join("/");

	// Changes a/b/c to /a/b/c
	return parsedUrl.startsWith("/") ? parsedUrl : `/${parsedUrl}`;
}
