const messages = {
	en: {
		accountDeletionHeader: "Confirm account deletion",
		bankName: "Bank: ",
		branchCode: "Branch Code: ",
		accountType: {
			header: "Account Type: ",
			bond: "Bond Account",
			cheque: "Current/Cheque Account",
			credit: "Credit Card Account",
			savings: "Savings Account",
			subscription: "Subscription Account",
			transmission: "Transmission Account",
		},
		accountNumber: "Account Number: ",
	},
};

export default messages;
