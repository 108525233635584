import { NotificationLine } from "@app/components/notification-line";
import { Popover } from "@app/components/popovers/popover";
import type { Notification } from "@app/entities";
import type { RootState } from "@app/redux";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import "./notifications.css";
import { IoNotifications, IoNotificationsOutline } from "react-icons/io5";

export const NotificationsView = memo(
	(props: {
		className?: string;
		notificationList: Notification[];
		open: boolean;
		onRead: (index: number) => void;
		onToggle: (toggle: boolean) => void;
	}) => {
		const { language } = useSelector((state: RootState) => state.language);

		const unreadCount = React.useMemo(() => {
			let count = 0;
			props.notificationList.forEach((x) => !x.read && count++);
			return count;
		}, [props.notificationList]);

		const getContainerClasses = () => {
			const classesToUse = ["notifications flex p-1.5 items-center"];
			if (props.className) classesToUse.push(props.className);
			return classesToUse.join(" ");
		};

		const getToggleContent = () => {
			return (
				<div className="notification-toggle relative">
					{unreadCount > 0 && (
						<div className="unread-count flex justify-center items-center absolute right-0 top-0">
							{unreadCount}
						</div>
					)}
					{props.open ? (
						<IoNotifications className="icon-outlined" size="28px" />
					) : (
						<IoNotificationsOutline className="icon-outlined" size="28px" />
					)}
				</div>
			);
		};

		const getNotification = (notification: Notification, index: number) => {
			return (
				<NotificationLine
					key={index}
					notification={notification}
					onRead={props.onRead}
					index={index}
				/>
			);
		};

		const getPopoverContent = () => {
			return (
				<div className="notification-content">
					<div className="notification-header flex flex-1 items-center justify-between gap-x-4 px-4 pt-4 pb-2.5 z-10">
						<h4>Notifications</h4>
					</div>
					<div className="notification-body flex flex-col flex-1 justify-center">
						{props.notificationList.length > 0 ? (
							props.notificationList.map((x, index) =>
								getNotification(x, index),
							)
						) : (
							<span className="py-2 px-4">No notifications</span>
						)}
					</div>
				</div>
			);
		};

		return (
			<Popover
				className={getContainerClasses()}
				placement="bottom-start"
				offset={[0, 10]}
				toggleContent={getToggleContent()}
				popperContent={getPopoverContent()}
				onToggle={props.onToggle}
			/>
		);
	},
);
