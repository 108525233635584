import type {
	Period,
	PeriodSettingsResponse,
	SpreadResponse,
} from "@app/entities";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	currentPeriod?: Period;
	historySpread: SpreadResponse[];
	historySpreadError?: string[] | null;
	historySpreadLoading?: boolean;
	periodSettings?: PeriodSettingsResponse;
	periodSettingsError?: string[] | null;
	periodSettingsLoading?: boolean;
};

export const defaultState: State = {
	historySpread: [],
};

const slice = createSlice({
	name: "arbitrager",
	initialState: defaultState,
	reducers: {
		setCurrentPeriod(state, action: PayloadAction<Period>) {
			state.currentPeriod = action.payload;
		},
		setHistorySpread(state, action: PayloadAction<SpreadResponse[]>) {
			state.historySpread = action.payload;
		},
		setHistorySpreadError(state, action: PayloadAction<string[] | null>) {
			state.historySpreadError = action.payload;
		},
		setHistorySpreadLoading(state, action: PayloadAction<boolean>) {
			state.historySpreadLoading = action.payload;
		},
		appendMostRecentSpread(state, action: PayloadAction<SpreadResponse>) {
			const [, ...newHistory] = state.historySpread;
			state.historySpread = [...newHistory, action.payload];
		},
		setPeriodSettings(state, action: PayloadAction<PeriodSettingsResponse>) {
			state.periodSettings = action.payload;
		},
		setPeriodSettingsError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.periodSettingsError = action.payload;
		},
		setPeriodSettingsLoading(state: State, action: PayloadAction<boolean>) {
			state.periodSettingsLoading = action.payload;
		},
	},
});

export const {
	setCurrentPeriod,
	setHistorySpread,
	setHistorySpreadError,
	setHistorySpreadLoading,
	appendMostRecentSpread,
	setPeriodSettings,
	setPeriodSettingsError,
	setPeriodSettingsLoading,
} = slice.actions;

export default slice.reducer;
