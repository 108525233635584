import logo from "@app/assets/images/logo.svg";
import { links } from "@app/constants/links";
import type { RootState } from "@app/redux";
import React from "react";
import { useSelector } from "react-redux";
import "./footer.css";

export const Footer = React.memo(() => {
	const { language } = useSelector(
		(rootState: RootState) => rootState.language,
	);

	return (
		<div className="font-primary-light px-7 lg:px-24 py-20 lg:py-24 footer">
			<div className="flex flex-col gap-x-8 lg:flex-row justify-start">
				<div className="basis-1/3 shrink-0">
					<img src={logo} alt="Future Forex" className="max-w-full max-w-" />
					<div className="flex flex-col leading-8 mt-11 mb-14 footer-text-primary opacity-70 lg:opacity-100">
						<span>Your partner in low-risk,</span>
						<span>high yield Crypto Asset Arbitrage.</span>
					</div>
				</div>
				<div className="basis-2/3 flex flex-col shrink lg:flex-row gap-x-6 gap-y-16 px-0 justify-between">
					<div className="basis-auto flex flex-col gap-y-2 items-start">
						<div className="font-primary-bold leading-7 lg:tracking-normal text-lg tracking-wider whitespace-nowrap footer-link-heading">
							QUICK LINKS
						</div>
						<div className="mb-6 footer-stack-underline" />
						<a
							className="font-primary-light hover:underline leading-normal lg:text-base text-xl footer-text-primary opacity-70 lg:opacity-100"
							href={links.quickLinks.about}
							target={"_blank"}
							rel="noreferrer"
						>
							About
						</a>
						<a
							className="font-primary-light hover:underline leading-normal lg:text-base text-xl footer-text-primary opacity-70 lg:opacity-100"
							href={links.quickLinks.faqs}
							target={"_blank"}
							rel="noreferrer"
						>
							FAQs
						</a>
						<a
							className="font-primary-light hover:underline leading-normal lg:text-base text-xl footer-text-primary opacity-70 lg:opacity-100"
							href={links.quickLinks.careers}
							target={"_blank"}
							rel="noreferrer"
						>
							Careers
						</a>
					</div>
					<div className="basis-auto flex flex-col gap-y-2 items-start">
						<div className="font-primary-bold leading-7 lg:tracking-normal text-lg tracking-wider whitespace-nowrap footer-link-heading">
							LEGAL
						</div>
						<div className="mb-6 footer-stack-underline" />
						<a
							className="font-primary-light hover:underline leading-normal lg:text-base text-xl footer-text-primary opacity-70 lg:opacity-100"
							href={links.legal.termsAndConditions}
							target={"_blank"}
							rel="noreferrer"
						>
							Terms and Conditions
						</a>
						<a
							className="font-primary-light hover:underline leading-normal lg:text-base text-xl footer-text-primary opacity-70 lg:opacity-100"
							href={links.legal.termsOfUse}
							target={"_blank"}
							rel="noreferrer"
						>
							Website Terms of Use
						</a>
						<a
							className="font-primary-light hover:underline leading-normal lg:text-base text-xl footer-text-primary opacity-70 lg:opacity-100"
							href={links.legal.privacyPolicy}
							target={"_blank"}
							rel="noreferrer"
						>
							Privacy Policy
						</a>
					</div>
					<div className="basis-auto flex flex-col gap-y-2 items-start">
						<div className="font-primary-bold leading-7 lg:tracking-normal text-lg tracking-wider whitespace-nowrap footer-link-heading">
							GET IN TOUCH
						</div>
						<div className="mb-6 footer-stack-underline" />
						<a
							className="font-primary-light hover:underline leading-normal lg:text-base text-xl footer-text-primary opacity-70 lg:opacity-100"
							href={links.getInTouch.phoneNumber}
							target={"_blank"}
							rel="noreferrer"
						>
							+27 (0)21 518 0558
						</a>
						<a
							className="font-primary-light hover:underline leading-normal lg:text-base text-xl footer-text-primary opacity-70 lg:opacity-100"
							href={links.getInTouch.emailAddress}
							target={"_blank"}
							rel="noreferrer"
						>
							info@futureforex.co.za
						</a>
						<a
							className="flex flex-col gap-y-2 mt-8 font-primary-light hover:underline leading-normal lg:text-base text-xl footer-text-primary opacity-70 lg:opacity-100 inline-block"
							href={links.getInTouch.physicalAddress}
							target={"_blank"}
							rel="noreferrer"
						>
							<span>66 Roeland Street,</span>
							<span>Cape Town, South Africa</span>
						</a>
					</div>
				</div>
			</div>
			<hr className="mb-6 mt-9 opacity-30 footer-separator" />
			<div className="flex flex-col gap-y-5 leading-normal text-xs footer-text-primary opacity-70 lg:opacity-70">
				<span>
					Future Forex SA (Pty) Ltd is an authorised Financial Services Provider
					(FSP 51884) - it is licensed for the remittance of international
					payments only. Future Forex SA is a regulated primary accountable
					institution with the Financial Intelligence Centre (FIC).
				</span>
				<span>
					For the circumstances of “unregulated cryptocurrencies”, we provide a
					forex intermediary service, with zero financial advice, within the
					framework of the FSCA, SARB and Currency and Exchanges Act.
				</span>
			</div>
		</div>
	);
});
