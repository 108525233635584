import React from "react";
import { PopoverView } from "./popover-view";
import type { Properties } from "./properties";

export const Popover = (props: Properties) => {
	const [open, setOpen] = React.useState<boolean>(false);
	// #region

	const onToggle = (toggle: boolean) => {
		setOpen(toggle);

		if (props.onToggle) props.onToggle(toggle);
	};

	return <PopoverView {...props} onToggle={onToggle} open={open} />;
};
