import { useGetMostRecentSpread } from "@app/helpers";
import type { RootState } from "@app/redux";
import React from "react";
import { useSelector } from "react-redux";

export const ArbitragerUpdater = () => {
	const [getMostRecentSpread] = useGetMostRecentSpread();

	const { historySpreadLoading, currentPeriod } = useSelector(
		(rootState: RootState) => rootState.arbitrager,
	);

	React.useEffect(() => {
		if (!historySpreadLoading && currentPeriod) {
			const timeout = setTimeout(() => {
				getMostRecentSpread(currentPeriod.days);
			}, currentPeriod?.interval.timedelta * 1000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [historySpreadLoading]);

	return <></>;
};
