import { Modal } from "@app/components/modals/modal";
import { Button } from "@app/controls/button";
import type { RootState } from "@app/redux";
import { memo } from "react";
import { useSelector } from "react-redux";
import "./account-deletion-modal.css";
import type { AccountType } from "@app/entities";
import messages from "./messages";

export const AccountDeletionModalView = memo(
	(props: {
		open: boolean;
		bankId: number;
		bankName: string;
		branchCode: string;
		accountNumber: string;
		accountType: AccountType;
		onClose: () => void;
		disabled?: boolean;
		onDelete: (bankId: number) => void;
	}) => {
		const { language } = useSelector((state: RootState) => state.language);

		const getAccountInfo = () => {
			return (
				<>
					<h1 className="font-bold text-2xl mb-8">
						{messages[language].accountDeletionHeader}
					</h1>
					<div className="account-details text-lg mb-9">
						<p>
							<span>{messages[language].bankName}</span>
							{props.bankName}
						</p>
						<p>
							<span>{messages[language].branchCode}</span>
							{props.branchCode}
						</p>
						<p>
							<span>{messages[language].accountNumber}</span>
							{props.accountNumber}
						</p>
						<p className="mb-9">
							<span>{messages[language].accountType.header}</span>
							{messages[language].accountType[props.accountType]}
						</p>
					</div>
				</>
			);
		};

		return (
			<Modal
				className={"account-deletion-modal"}
				open={props.open}
				onClose={props.onClose}
			>
				{getAccountInfo()}
				<Button
					disabled={props.disabled}
					onClick={() => {
						props.onDelete(props.bankId);
					}}
					className={"account-deletion-button"}
					type="button"
					theme="primary"
				>
					Delete account
				</Button>
			</Modal>
		);
	},
);
