import type {
	Period,
	PeriodSettingsResponse,
	SpreadResponse,
} from "@app/entities";
import type { AppDispatch } from "@app/redux";
import {
	getHistorySpreadAction,
	getMostRecentSpreadAction,
	listPeriodSettingsAction,
	setCurrentPeriodAction,
} from "@app/redux/arbitrager/arbitrager-actions";
import { isArray } from "lodash";
import { useDispatch } from "react-redux";

export const useListPeriodSettings = () => {
	type ListPeriodSettingsHelperResponse = string[] | PeriodSettingsResponse;

	const dispatch: AppDispatch = useDispatch();
	const [setCurrentPeriod] = useSetCurrentPeriod();

	const listPeriodSettings = (
		callback?: (response?: ListPeriodSettingsHelperResponse) => void,
	) => {
		var _resolve = (
				response?:
					| ListPeriodSettingsHelperResponse
					| PromiseLike<ListPeriodSettingsHelperResponse>,
			) => {},
			_reject = (reason?: any) => {};

		var promise = new Promise<ListPeriodSettingsHelperResponse | undefined>(
			(resolve, reject) => {
				_resolve = resolve;
				_reject = reject;
			},
		);

		const _callback = (response?: ListPeriodSettingsHelperResponse) => {
			callback && callback(response);
			_resolve(response);
		};

		try {
			dispatch(
				listPeriodSettingsAction((response) => {
					const responseAsSettings = response as PeriodSettingsResponse;

					if (
						response &&
						!("reasons" in response) &&
						!isArray(response) &&
						responseAsSettings &&
						responseAsSettings.marketChart.periods.length > 0
					) {
						const foundCurrentPeriod =
							responseAsSettings.marketChart.periods.find(
								(value) =>
									value.days === responseAsSettings.marketChart.default,
							);

						setCurrentPeriod(
							foundCurrentPeriod !== undefined
								? foundCurrentPeriod
								: responseAsSettings.marketChart.periods[0],
							() => _callback(response),
						);
					} else {
						_callback(response);
					}
				}),
			);
		} catch (error: any) {
			_reject(error);
		} finally {
			return promise;
		}
	};

	return [listPeriodSettings];
};

export const useGetHistorySpread = () => {
	const dispatch: AppDispatch = useDispatch();

	const getHistorySpread = (
		period?: number,
		callback?: (response?: string[] | SpreadResponse[]) => void,
	) => {
		dispatch(
			getHistorySpreadAction(period, (response) => {
				callback && callback(response);
			}),
		);
	};

	return [getHistorySpread];
};

export const useGetMostRecentSpread = () => {
	const dispatch: AppDispatch = useDispatch();

	const getMostRecentSpread = (
		period?: number,
		callback?: (response: string[] | SpreadResponse | undefined) => void,
	) => {
		dispatch(getMostRecentSpreadAction(period, callback));
	};

	return [getMostRecentSpread];
};

export const useSetCurrentPeriod = () => {
	const dispatch: AppDispatch = useDispatch();

	const setCurrentPeriod = (period: Period, callback?: () => void) => {
		dispatch(setCurrentPeriodAction(period, callback));
	};

	return [setCurrentPeriod];
};
