import { type RootState, store } from "@app/redux";
import moment from "moment";

export const secondsToHoursMinutesSeconds = (totalSeconds: number) => {
	const {
		language: { language },
	} = store.getState() as RootState;

	const hoursMinutesSeconds = moment();

	hoursMinutesSeconds.set("hours", Math.floor(totalSeconds / 3600));
	hoursMinutesSeconds.set("minutes", Math.floor((totalSeconds % 3600) / 60));
	hoursMinutesSeconds.set("seconds", Math.floor((totalSeconds % 3600) % 60));

	const seconds = hoursMinutesSeconds.seconds();
	const minutes = hoursMinutesSeconds.minutes();
	const hours = hoursMinutesSeconds.hours();

	let outputFormat = hours > 0 ? `${hours} hour${hours === 1 ? "" : "s"}` : "";

	outputFormat +=
		minutes > 0
			? `${
					hours > 0 ? `, ${hours > 0 && seconds === 0 ? " " : ""}` : ""
				}${minutes} minute${minutes === 1 ? "" : "s"}`
			: "";

	outputFormat +=
		seconds > 0
			? `${
					minutes > 0 || hours > 0 ? `, ${minutes > 0 ? " " : ""}` : ""
				}${seconds} second${seconds === 1 ? "" : "s"}`
			: "";

	if (outputFormat) return `Please wait ${outputFormat}`;

	return undefined;
};
