import { Button } from "@app/controls/button";
import type { RootState } from "@app/redux";
import React from "react";
import { useSelector } from "react-redux";
import { AccordionCard } from "../accordion-card";
import "./client-accordion.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import type { ViewProperties } from "./properties";

const messages = {
	en: {
		allowanceAvailable: "Allowance Available",
		balance: "Balance",
		currencyCode: "R ",
		defaultPercent: "0",
		defaultValue: "0",
		footnote:
			"Note that this is an <strong>approximate</strong> balance based on your recent trades. It may be inaccurate if you have recently deposited or withdrawn funds.",
		minReturn: "Min Return",
		totalProfit: "Total Profit",
		tradeStatus: "Trade Status",
	},
};

const ClientAccordionView = React.memo((props: ViewProperties) => {
	const { language } = useSelector(
		(rootState: RootState) => rootState.language,
	);

	const childStyle = [
		"border-y-2",
		props.open && "-mb-0.5",
		"pb-3.5",
		"pt-3",
		"px-6",
		"client-accordion-panel",
	].join(" ");

	const textMediumStyle = ["font-primary-medium", "text-lg"].join(" ");

	const figureStyle = [
		"font-primary-regular text-lg",
		"shrink-0",
		"client-accordion-figure",
	].join(" ");

	const iconStyleOuter = [
		"border",
		"px-2.5",
		"py-2",
		"client-accordion-chevron",
	].join(" ");

	const labelStyle = [textMediumStyle, "client-accordion-label", "shrink"].join(
		" ",
	);

	const headerStyle = [
		"flex flex-row justify-between gap-x-2",
		textMediumStyle,
		"items-center",
		"px-6",
		"py-6",
		"client-accordion-header",
	].join(" ");

	const getChildContent = () => {
		return (
			<div className={childStyle}>
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row justify-between gap-x-2">
						<div className="flex flex-row gap-x-2">
							<div className={labelStyle}>{messages[language].balance}</div>
							{props.tooltipContent}
						</div>
						<div className={figureStyle}>
							{props.fundsAvailable || messages[language].defaultValue}
						</div>
					</div>
					<div className="flex flex-row justify-between gap-x-2">
						<div className={labelStyle}>{messages[language].minReturn}</div>
						<div className={figureStyle}>{props.minReturn}</div>
					</div>
					<div className="flex flex-row justify-between gap-x-2">
						<div className={labelStyle}>
							{messages[language].allowanceAvailable}
						</div>
						<div className={figureStyle}>
							{props.allowanceAvailable || messages[language].defaultValue}
						</div>
					</div>
					<div className="flex flex-row justify-between gap-x-2">
						<div className={labelStyle}>{messages[language].totalProfit}</div>
						<div className={figureStyle}>
							{props.totalProfit || messages[language].defaultValue}
						</div>
					</div>
					<div className="flex flex-row justify-between gap-x-2">
						<div className={labelStyle}>{messages[language].tradeStatus}</div>
						{props.statusContent}
					</div>
				</div>
			</div>
		);
	};

	const getHeaderContent = () => {
		const clientName = props.clientName
			? `${props.clientName[0].toUpperCase()}${props.clientName.slice(1)} `
			: "";
		const clientSurname = props.clientSurname
			? `${props.clientSurname[0].toUpperCase()}${props.clientSurname.slice(1)}`
			: "";

		return (
			<div className={headerStyle}>
				<Button
					className="inline-block border-b-2 text-left min-w-min grow-0 client-accordion-heading client-accordion-label"
					theme="secondary"
					onClick={() => props.onViewClient(props.clientId)}
				>
					<div>
						{clientName}
						{clientSurname}
					</div>
				</Button>
				<div className={iconStyleOuter} onClick={() => props.onToggleOpen()}>
					{props.open ? (
						<FaChevronUp size="14px" className="client-accordion icon-filled" />
					) : (
						<FaChevronDown
							size="14px"
							className="client-accordion icon-filled"
						/>
					)}
				</div>
			</div>
		);
	};

	return (
		<AccordionCard headerContent={getHeaderContent()} open={props.open} locked>
			{getChildContent()}
		</AccordionCard>
	);
});

export { ClientAccordionView };
