const messages = {
	en: {
		breakdown: {
			EXCHANGE_RATE: "Exchange Rate (USD/ZAR)",
			LOCAL_PRICE: "Local BTC Price",
			OFFSHORE_PRICE: "Offshore BTC Price",
			SPREAD: "Spread",
		},
		default: {
			dataSet: "Default Dataset",
			date: "Date",
		},
		dropdownHeading: "Choose Crypto Currency",
		groupSeparator: ",",
		headingTime: "over",
		prefix: {
			EXCHANGE_RATE: "R",
			LOCAL_PRICE: "R",
			OFFSHORE_PRICE: "$",
			SPREAD: "",
		},
		suffix: {
			EXCHANGE_RATE: "",
			LOCAL_PRICE: "",
			OFFSHORE_PRICE: "",
			SPREAD: "%",
		},
	},
};

export default messages;
