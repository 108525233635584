import { apiErrors } from "@app/constants/api-errors";
import { useLogout } from "@app/helpers";
import { useState } from "react";
import { WelcomeModalView } from "./welcome-modal-view";

export const WelcomeModal = (props: {
	open: boolean;
	onProceed: () => void;
}) => {
	const [showLogoutModal, setShowLogoutModal] = useState(false);

	const [logout] = useLogout();

	const onCloseLogoutModal = () => {
		setShowLogoutModal(false);
	};

	const onLogout = () => {
		logout((errors?: string[]) => {
			const showLogout = !(
				errors &&
				(errors.includes(apiErrors.tokenExpired) ||
					errors.includes(apiErrors.invalidToken))
			);

			setShowLogoutModal(showLogout);
		});
	};

	return (
		<WelcomeModalView
			{...props}
			showLogoutModal={showLogoutModal}
			onCloseLogoutModal={onCloseLogoutModal}
			onLogout={onLogout}
		/>
	);
};
