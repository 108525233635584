import type { NotificationResponse } from "@app/entities";
import { parseError } from "@app/utils/parse-error";
import type { AppThunk } from "..";
import {
	setNotificationRead,
	setNotificationsReadError,
	setNotificationsReadLoading,
} from "./notifications-read-slice";

export const setNotificationReadAction =
	(notification: NotificationResponse): AppThunk =>
	async (dispatch, getState) => {
		try {
			dispatch(setNotificationsReadLoading(true));
			dispatch(setNotificationsReadError(undefined));

			const { profile } = getState().auth;

			if (profile?.id !== undefined)
				dispatch(
					setNotificationRead({
						userId: profile.id,
						notificationId: notification.id,
					}),
				);
		} catch (error: any) {
			var reasons = [parseError(error)];

			dispatch(setNotificationsReadError(reasons));
		} finally {
			dispatch(setNotificationsReadLoading(false));
		}
	};
