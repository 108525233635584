import { useSetThrottlingTime } from "@app/helpers";
import React, { useEffect } from "react";

const ThrottleManager = () => {
	const [setThrottlingTime] = useSetThrottlingTime();

	useEffect(() => {
		setThrottlingTime(0);
	}, []);

	return <></>;
};

export { ThrottleManager };
