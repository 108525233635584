import type { AccountType } from "@app/entities";
import { useDestroyWithdrawalBank } from "@app/helpers";
import { useState } from "react";
import { AccountDeletionModalView } from "./account-deletion-modal-view";

export const AccountDeletionModal = (props: {
	open: boolean;
	bankId: number;
	bankName: string;
	branchCode: string;
	accountNumber: string;
	accountType: AccountType;
	onClose: () => void;
	onDelete: () => void;
	disabled?: boolean;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [destroyWithdrawalBank] = useDestroyWithdrawalBank();

	const onDelete = (bankId: number) => {
		setIsLoading(true);
		destroyWithdrawalBank(bankId, () => {
			setIsLoading(false);
			props.onDelete();
		});
	};

	return (
		<AccountDeletionModalView
			{...props}
			disabled={props.disabled || isLoading}
			onDelete={onDelete}
			onClose={props.onClose}
		/>
	);
};
