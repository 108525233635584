import type {
	CreateWithdrawalAccount,
	UpdateWithdrawalAccount,
	WithdrawalAccount,
} from "@app/entities";
import { concatenateArrays } from "@app/utils/concatenate-arrays";
import { authorizedApi } from "./api";
import type {
	CreateWithdrawalAccountRequest,
	CreateWithdrawalAccountResponse,
	GenericFailureResponse,
	GenericResponse,
	MappedReasons,
	RequestWithdrawalResponse,
	UpdateWithdrawalAccountRequest,
	UpdateWithdrawalBankResponse,
	WithdrawalAccountResponse,
} from "./models";

export async function retrieveWithdrawalBankList(
	clientId: number,
): Promise<WithdrawalAccount[] | GenericResponse> {
	try {
		var { data } = await authorizedApi.get<WithdrawalAccountResponse[]>(
			`withdrawal_bank_list/${clientId}/`,
		);

		return data.map(mapToWithdrawalAccount);
	} catch (exception: any) {
		var error = exception.data as GenericFailureResponse;

		var reasons = concatenateArrays(error.genericErrors, [error.detail ?? ""]);

		return {
			reasons: reasons,
		};
	}
}

export async function retrieveWithdrawalBank(
	bankId: number,
): Promise<WithdrawalAccount | GenericResponse> {
	try {
		var { data } = await authorizedApi.get<WithdrawalAccountResponse>(
			`withdrawal_bank/${bankId}/`,
		);

		return mapToWithdrawalAccount(data);
	} catch (exception: any) {
		var error = exception.data as GenericFailureResponse;

		var reasons = concatenateArrays(error.genericErrors, [error.detail ?? ""]);

		return {
			reasons: reasons,
		};
	}
}

export async function createWithdrawalBank(
	account: CreateWithdrawalAccount,
): Promise<WithdrawalAccount | GenericResponse> {
	try {
		var request = mapToCreateWithdrawalAccountRequest(account);

		var { data } = await authorizedApi.post<WithdrawalAccountResponse>(
			`create_withdrawal_bank/`,
			request,
		);

		return mapToWithdrawalAccount(data);
	} catch (exception: any) {
		var error = exception.data as CreateWithdrawalAccountResponse;

		var reasons = concatenateArrays(
			error.genericErrors,
			[error.detail ?? ""],
			error.non_field_errors,

			error.accessible_by,
			error.account_number,
			error.account_type,
			error.bank,
			error.branch_code,
			error.client,
		);

		var mappedReasons: MappedReasons = {
			accessibleBy: error.accessible_by,
			accountNumber: error.account_number,
			accountType: error.account_type,
			bankName: error.bank,
			branchCode: error.branch_code,
			clientId: error.client,
		};

		return {
			reasons: reasons,
			mappedReasons: mappedReasons,
		};
	}
}

export async function requestWithdrawal(
	clientId: number,
	amount: string | null,
	withdrawAll: boolean,
	bankId: number,
	straightThroughProcess: boolean,
): Promise<undefined | GenericResponse> {
	try {
		await authorizedApi.post(`request_withdrawal_v2/`, {
			client_id: clientId,
			withdrawal_amount: withdrawAll ? null : amount,
			withdraw_all: withdrawAll,
			to_bank_account: bankId,
			stp: straightThroughProcess,
		});
	} catch (exception: any) {
		var error = exception.data as RequestWithdrawalResponse;

		var reasons = concatenateArrays(
			error.genericErrors,
			[error.detail ?? ""],

			error.client_id,
			error.non_field_errors,

			error.stp,
			error.to_bank_account,
			error.withdraw_all,
			error.withdrawal_amount,
		);

		var mappedReasons: MappedReasons = {
			clientId: error.client_id,
			amount: error.withdrawal_amount,
			withdrawAll: error.withdraw_all,
			bankId: error.to_bank_account,
			straightThroughProcess: error.stp,

			errors: concatenateArrays(
				error.genericErrors,
				[error.detail ?? ""],
				error.non_field_errors,
			),
		};

		return {
			reasons: reasons,
			mappedReasons: mappedReasons,
		};
	}
}

export async function updateWithdrawalBank(
	bankId: number,
	account: UpdateWithdrawalAccount,
): Promise<WithdrawalAccount | GenericResponse> {
	try {
		var { data } = await authorizedApi.put<WithdrawalAccountResponse>(
			`withdrawal_bank/${bankId}/`,
			mapToUpdateWithdrawalBankRequest(account),
		);

		return mapToWithdrawalAccount(data);
	} catch (exception: any) {
		var error = exception.data as UpdateWithdrawalBankResponse;

		var reasons = concatenateArrays(
			error.genericErrors,
			[error.detail ?? ""],

			error.non_field_errors,

			error.account_number,
			error.bank,
			error.account_type,
			error.branch_code,
			error.accessible_by,
			error.client,
		);

		var mappedReasons: MappedReasons = {
			accessibleBy: error.accessible_by,
			accountNumber: error.account_number,
			accountType: error.account_type,
			bankName: error.bank,
			branchCode: error.branch_code,
			client: error.client,

			errors: concatenateArrays(
				error.genericErrors,
				[error.detail ?? ""],
				error.non_field_errors,
			),
		};

		return {
			reasons: reasons,
			mappedReasons: mappedReasons,
		};
	}
}

export async function destroyWithdrawalBank(
	bankId: number,
): Promise<undefined | GenericResponse> {
	try {
		await authorizedApi.delete(`withdrawal_bank/${bankId}/`);
	} catch (exception: any) {
		var error = exception.data as GenericFailureResponse;

		var reasons = concatenateArrays(error.genericErrors, [error.detail ?? ""]);

		return {
			reasons: reasons,
		};
	}
}

function mapToWithdrawalAccount(
	account: WithdrawalAccountResponse,
): WithdrawalAccount {
	return {
		accessibleBy: account.accessible_by,
		accountNumber: account.account_number,
		accountType: account.account_type,
		bankName: account.bank,
		branchCode: account.branch_code,
		clientId: account.client,
		id: account.id,
	};
}

function mapToCreateWithdrawalAccountRequest(
	account: CreateWithdrawalAccount,
): CreateWithdrawalAccountRequest {
	return {
		accessible_by: account.accessibleBy,
		account_number: account.accountNumber,
		account_type: account.accountType,
		bank: account.bankName,
		branch_code: account.branchCode,
		client: account.clientId,
	};
}

function mapToUpdateWithdrawalBankRequest(
	account: UpdateWithdrawalAccount,
): UpdateWithdrawalAccountRequest {
	return {
		accessible_by: account.accessibleBy,
		account_number: account.accountNumber,
		account_type: account.accountType,
		bank: account.bankName,
		branch_code: account.branchCode,
	};
}
