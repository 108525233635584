import { Modal } from "@app/components/modals/modal";
import { NewClientDisclaimerModal } from "@app/components/modals/new-client-disclaimer-modal";
import { WelcomeModal } from "@app/components/modals/welcome-modal";
import { Wizard } from "@app/components/wizard";
import { commonMessages } from "@app/constants/common-messages";
import {
	type Country,
	countryList,
	countrySouthAfrica,
} from "@app/constants/country";
import {
	type Province,
	provinceList,
	provinceOther,
} from "@app/constants/province";
import {
	type ValidationErrors,
	validationErrorOptions,
} from "@app/constants/validation-errors";
import { Button } from "@app/controls/button";
import { Checkbox } from "@app/controls/checkbox";
import { Dropdown } from "@app/controls/dropdown";
import { Input } from "@app/controls/input";
import type { DropdownItem } from "@app/entities";
import type { ClientSummary, ReferrerResponse } from "@app/entities";
import type { RootState } from "@app/redux";
import type { MappedReasons } from "@app/services";
import { Transition } from "@headlessui/react";
import { Formik, type FormikErrors } from "formik";
import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import type {
	AddressFieldKey,
	AddressFieldOptions,
} from "./models/address-fields";
import type { DropdownFieldKey } from "./models/dropdown-fields";
import type { FormErrors, FormValues } from "./models/form";
import "./new-client-modal.css";
import { links } from "@app/constants/links";
import { FaChevronLeft } from "react-icons/fa";

export const NewClientModalView = memo(
	(props: {
		open: boolean;
		errors?: string[];
		onClose: () => void;
		clients: ClientSummary[];
		defaultValues: FormValues;
		isValidReferralCode: boolean;
		step: number;
		isSubmitting?: boolean;
		totalSteps: number;
		firstClientDisclaimed: boolean;
		mappedErrors?: MappedReasons;
		referrerOptions: ReferrerResponse[];
		residentialCountry?: Country;
		postalCountry?: Country;
		residentialProvince?: Province;
		postalProvince?: Province;
		showOtherProvinceFields?: boolean;
		showOtherPostalProvinceFields?: boolean;
		showPostalAddressFields: boolean;
		showHeardAboutUsFields: boolean;
		showDisclaimer: boolean;
		showWizard: boolean;
		showWelcome: boolean;
		validate: boolean;
		onClearErrors: (name: string) => void;
		onDisclaimerProceed: () => void;
		onSubmit: (values: FormValues) => void;
		onDropdownSelect: (name: DropdownFieldKey, value: string) => void;
		onValidate: (errors: FormErrors, submit: () => void) => void;
		onResetWizard: () => void;
		onShowWizard: () => void;
		onBack: (values?: FormValues) => void;
	}) => {
		const { language } = useSelector((state: RootState) => state.language);
		const countryItems = countryList.map((x) => ({
			value: x,
		}));

		const residentialProvinceItems = useMemo(() => {
			const list =
				props.residentialCountry === countrySouthAfrica
					? provinceList.filter((x) => x !== provinceOther)
					: provinceList;

			return list.map((x) => ({
				label: x === "Kwazulu-Natal" ? "KwaZulu-Natal" : x,
				value: x,
			}));
		}, [props.residentialCountry]);

		const postalProvinceItems = useMemo(() => {
			const list =
				props.postalCountry === countrySouthAfrica
					? provinceList.filter((x) => x !== provinceOther)
					: provinceList;

			return list.map((x) => ({
				label: x === "Kwazulu-Natal" ? "KwaZulu-Natal" : x,
				value: x,
			}));
		}, [props.postalCountry]);

		const referrerOptions = useMemo(() => {
			return props.referrerOptions.map<DropdownItem<string>>((x) => ({
				value: String(x.id),
				label: x.name,
			}));
		}, [props.referrerOptions]);

		const getError = (show: boolean) => {
			const areErrors =
				show ||
				(props.mappedErrors !== undefined &&
					Object.entries(props.mappedErrors).find((x) => x[1] !== undefined) !==
						undefined);

			return (
				<div className="error pb-5 flex justify-center">
					<span>
						<Transition
							show={areErrors}
							enter="transition ease-out duration-300"
							enterFrom="opacity-0 translate-y-5"
							leave="transition ease-out duration-300"
							leaveTo="opacity-0 -translate-y-5"
						>
							<p>There are errors in your submission. Please see above.</p>
						</Transition>
					</span>
				</div>
			);
		};

		const getFieldErrorContent = (fieldName: string, localError?: string) => {
			const errors = getFieldErrors(fieldName, localError);
			const showCondition = isFieldInError(fieldName, localError);

			if (!errors && !showCondition) return null;

			return (
				<Transition
					show={showCondition}
					enter="transition ease-out duration-300"
					leave="transition east-out duration-300"
					enterFrom="opacity-0 translate-y-5"
					enterTo="opacity-100 translate-y-0"
					leaveTo="opacity-0 -translate-y-5"
				>
					{errors && (
						<div className="flex flex-col gap-y-2">
							<div
								className="font-primary-regular new-client-error"
								dangerouslySetInnerHTML={{
									__html: `<p>${errors.join("</p><p>")}</p>`,
								}}
							/>
						</div>
					)}
				</Transition>
			);
		};

		const getHeader = () => {
			return (
				<div className="main-heading mb-10">
					<h2 className="mb-5">
						{props.clients.length === 0 ? "Add Details" : "Add Client"}
					</h2>
					<div className="heading-underline" />
				</div>
			);
		};

		const isFieldInError = (
			key: string,
			localError?: string,
		): boolean | undefined => {
			if (localError) {
				return true;
			}

			let result = false;

			if (props.mappedErrors)
				result = (props.mappedErrors[key] ?? []).length > 0;

			if (!result) return undefined;

			return result;
		};

		const getFieldErrors = (
			key: string,
			localError?: string,
		): string[] | undefined => {
			if (localError) {
				return [mapControlError(localError)];
			}

			let result: string[] = [];

			if (props.mappedErrors) {
				result = props.mappedErrors[key] ?? [];
			}

			if (result.length > 0) {
				return result;
			}

			return undefined;
		};

		const mapControlError = (error: string) => {
			const validationError = error as ValidationErrors;

			if (validationErrorOptions.includes(validationError)) {
				if (validationError && validationError.length > 0) {
					return commonMessages[language].validationError[validationError];
				}
				return commonMessages[language].validationError.required;
			}
			return commonMessages[language].validationError.required;
		};

		const getStep1 = () => {
			const defaultValues: FormValues = {
				...{
					firstName: "",
					middleName: "",
					lastName: "",
					contactNumber: "",
					initialInvestment: "",
					secondaryEmail: "",
					ageAndResidency: undefined,
					notUSCitizen: undefined,
					sourceOfFunds: undefined,
				},
				...props.defaultValues,
			};

			return (
				<Formik initialValues={defaultValues} onSubmit={props.onSubmit}>
					{({ handleSubmit, validateForm, errors }) => {
						return (
							<>
								{getHeader()}
								<div className="flex flex-wrap flex-1 gap-y-4.5 mb-9.5">
									<div className="grid sm:grid-cols-2 w-full gap-x-11 gap-y-4.5">
										<div className="flex flex-col w-full gap-y-4.5">
											<div className="grow mt-auto">
												{getFieldErrorContent("firstName", errors.firstName)}
												<Input
													className="w-full lg:flex-1"
													defaultValue={
														defaultValues.firstName as string | undefined
													}
													error={isFieldInError("firstName")}
													maxLength={30}
													name="firstName"
													placeholder="First Name *"
													required
													title="Please enter client's first name"
													useFormik
													validateOnChange={props.validate}
													onChange={() => props.onClearErrors("firstName")}
												/>
											</div>
											<div className="grow mt-auto">
												{getFieldErrorContent("middleName", errors.middleName)}
												<Input
													className="w-full lg:flex-1"
													defaultValue={
														defaultValues.middleName as string | undefined
													}
													error={isFieldInError("middleName")}
													maxLength={30}
													name="middleName"
													placeholder="Middle Name"
													title="Please enter client's middle name"
													useFormik
													onChange={() => props.onClearErrors("middleName")}
												/>
											</div>
											<div className="grow mt-auto">
												{getFieldErrorContent("lastName", errors.lastName)}
												<Input
													className="w-full lg:flex-1"
													defaultValue={
														defaultValues.lastName as string | undefined
													}
													error={isFieldInError("lastName")}
													maxLength={30}
													name="lastName"
													placeholder="Last Name *"
													required
													title="Please enter client's last name"
													useFormik
													validateOnChange={props.validate}
													onChange={() => props.onClearErrors("lastName")}
												/>
											</div>
										</div>
										<div className="flex flex-col w-full gap-y-4.5">
											<div className="grow mt-auto">
												{getFieldErrorContent(
													"initialInvestment",
													errors.initialInvestment,
												)}
												<Input
													className="w-full lg:flex-1"
													defaultValue={
														defaultValues.initialInvestment as
															| string
															| undefined
													}
													error={isFieldInError("initialInvestment")}
													maxLength={30}
													name="initialInvestment"
													placeholder="Investment Amount *"
													required
													title="Please enter client's investment amount"
													type="currency"
													scrollDisabled={true}
													useFormik
													validateOnChange={props.validate}
													onChange={() =>
														props.onClearErrors("initialInvestment")
													}
												/>
											</div>
											<div className="grow mt-auto">
												{getFieldErrorContent(
													"secondaryEmail",
													errors.secondaryEmail,
												)}
												<Input
													className="w-full lg:flex-1"
													defaultValue={
														defaultValues.secondaryEmail as string | undefined
													}
													error={isFieldInError("secondaryEmail")}
													maxLength={254}
													name="secondaryEmail"
													placeholder={
														props.clients.length > 0
															? "Email"
															: "Secondary email (optional)"
													}
													title="Please enter client's secondary contact email if one exist"
													type="email"
													useFormik
													validateOnChange={props.validate}
													onChange={() => props.onClearErrors("secondaryEmail")}
												/>
											</div>

											<div className="grow mt-auto">
												{getFieldErrorContent(
													"contactNumber",
													errors.contactNumber,
												)}
												<Input
													className="w-full lg:flex-1"
													defaultValue={
														defaultValues.contactNumber as string | undefined
													}
													error={isFieldInError("contactNumber")}
													maxLength={20}
													name="contactNumber"
													placeholder="Phone Number *"
													required
													title="Please enter client's contact number"
													type="tel"
													useFormik
													validateOnChange={props.validate}
													onChange={() => props.onClearErrors("contactNumber")}
												/>
											</div>
										</div>
									</div>
									<div className="flex flex-col w-full gap-y-4.5">
										<h4 className="secondary-heading">Age and Residency *</h4>
										{getFieldErrorContent(
											"ageAndResidency",
											errors.ageAndResidency,
										)}
										<Checkbox
											name="ageAndResidency"
											label="I am 18+ years old and a South African resident with a South African ID"
											onClick={() => props.onClearErrors("ageAndResidency")}
											required
											useFormik
											validateOnChange={props.validate}
											defaultValue={
												defaultValues.ageAndResidency as boolean | undefined
											}
										/>

										{getFieldErrorContent("notUSCitizen", errors.notUSCitizen)}
										<Checkbox
											name="notUSCitizen"
											label={
												<>
													I confirm I am <strong>not a US citizen</strong> (US
													Citizens are not permitted to do Crypto Arbitrage in
													South Africa)
												</>
											}
											onClick={() => props.onClearErrors("notUSCitizen")}
											required
											useFormik
											validateOnChange={props.validate}
											defaultValue={
												defaultValues.notUSCitizen as boolean | undefined
											}
										/>

										<h4 className="secondary-heading">Source of Funds *</h4>
										{getFieldErrorContent(
											"sourceOfFunds",
											errors.sourceOfFunds,
										)}
										<Checkbox
											name="sourceOfFunds"
											label={
												<div
													dangerouslySetInnerHTML={{
														__html: `The funds invested are my own or donated by my spouse and were <a href=${links.sourceOfFundsFaq} target="_blank">not acquired through an informal loan</a>`,
													}}
												/>
											}
											onClick={() => props.onClearErrors("sourceOfFunds")}
											required
											useFormik
											validateOnChange={props.validate}
											defaultValue={
												defaultValues.sourceOfFunds as boolean | undefined
											}
										/>
									</div>
								</div>
								{getError(Object.keys(errors).length > 0)}
								<div className="flex justify-center mx-auto">
									<Button
										className="mx-auto"
										type="button"
										theme="primary"
										onClick={async () =>
											props.onValidate(await validateForm(), handleSubmit)
										}
									>
										NEXT STEP
									</Button>
								</div>
							</>
						);
					}}
				</Formik>
			);
		};

		const getOtherProvinceFields = (
			name: string,
			defaultValue?: string,
			tooltip?: string,
			error?: string,
		) => {
			return (
				<div className="w-full">
					{getFieldErrorContent(name, error)}
					<Input
						className="w-full"
						defaultValue={defaultValue}
						error={isFieldInError(name)}
						name={name}
						placeholder="Other State/Province *"
						required
						title={tooltip}
						useFormik
						validateOnChange={props.validate}
						onChange={() => props.onClearErrors(name)}
					/>
				</div>
			);
		};

		const getAddressFields = ({
			inputClasses,
			fieldOptions,
		}: {
			inputClasses: string;
			fieldOptions: AddressFieldOptions;
		}) => {
			const getName = (name: AddressFieldKey) => {
				return fieldOptions[name].name;
			};
			const getDefaultValue = (name: AddressFieldKey) => {
				return fieldOptions[name].defaultValue;
			};

			const getAddressError = (name: AddressFieldKey) => {
				return fieldOptions[name].error;
			};

			const getTooltip = (name: AddressFieldKey) => {
				return fieldOptions[name].tooltip;
			};

			const province = getName("province");

			let provinceItems = residentialProvinceItems;

			let showOtherProvince = props.showOtherProvinceFields;

			let forceOtherProvince =
				props.residentialCountry &&
				props.residentialCountry !== countrySouthAfrica;

			let selectedProvince = props.residentialProvince;

			if (province === ("postalProvince" as DropdownFieldKey)) {
				provinceItems = postalProvinceItems;

				showOtherProvince = props.showOtherPostalProvinceFields;

				forceOtherProvince =
					props.postalCountry && props.postalCountry !== countrySouthAfrica;

				selectedProvince = props.postalProvince;
			}

			return (
				<div className="w-full">
					<div className="gap-x-11 gap-y-4.5 grid sm:grid-cols-2 mb-4.5">
						<div className="grow mt-auto">
							{getFieldErrorContent(
								getName("address"),
								getAddressError("address"),
							)}
							<Input
								className={inputClasses}
								defaultValue={getDefaultValue("address")}
								error={isFieldInError(getName("address"))}
								maxLength={100}
								name={getName("address")}
								placeholder="Street Address *"
								required
								title="Please enter client's street address"
								useFormik
								validateOnChange={props.validate}
								onChange={() => props.onClearErrors(getName("address"))}
							/>
						</div>
						<div className="grow mt-auto">
							{getFieldErrorContent(
								getName("suburb"),
								getAddressError("suburb"),
							)}
							<Input
								className={inputClasses}
								defaultValue={getDefaultValue("suburb")}
								error={isFieldInError(getName("suburb"))}
								maxLength={50}
								name={getName("suburb")}
								placeholder="Suburb *"
								required
								title="Please enter client's suburb"
								useFormik
								validateOnChange={props.validate}
								onChange={() => props.onClearErrors(getName("suburb"))}
							/>
						</div>
					</div>
					<div className="gap-x-11 gap-y-4.5 grid sm:grid-cols-2 mb-4.5">
						<div className="grow mt-auto">
							{getFieldErrorContent(getName("city"), getAddressError("city"))}
							<Input
								className={[inputClasses, "pr-0"].join(" ")}
								defaultValue={getDefaultValue("city")}
								error={isFieldInError(getName("city"))}
								maxLength={30}
								name={getName("city")}
								placeholder="City *"
								required
								title="Please enter client's city"
								useFormik
								validateOnChange={props.validate}
								onChange={() => props.onClearErrors(getName("city"))}
							/>
						</div>
						<div className="grow mt-auto">
							{getFieldErrorContent(
								getName("country"),
								getAddressError("country"),
							)}
							<Dropdown
								className={inputClasses}
								header="Country *"
								items={countryItems}
								name={getName("country")}
								onSelect={(value) =>
									props.onDropdownSelect(
										getName("country") as DropdownFieldKey,
										value,
									)
								}
								required
								theme="secondary"
								useFormik
								validateOnChange={props.validate}
								defaultValue={getDefaultValue("country")}
							/>
						</div>
					</div>
					<div
						className={`gap-x-11 gap-y-4.5 grid sm:grid-cols-2 ${
							showOtherProvince ? "mb-4.5" : ""
						}`}
					>
						<div className="grow mt-auto">
							{getFieldErrorContent(
								getName("postalCode"),
								getAddressError("postalCode"),
							)}
							<Input
								className={[inputClasses, "pr-0"].join(" ")}
								defaultValue={getDefaultValue("postalCode")}
								error={isFieldInError(getName("postalCode"))}
								maxLength={10}
								name={getName("postalCode")}
								placeholder="Zip / Postal Code *"
								required
								title="Please enter client's postal code"
								useFormik
								validateOnChange={props.validate}
								onChange={() => props.onClearErrors(getName("postalCode"))}
							/>
						</div>
						<div className="grow mt-auto">
							{getFieldErrorContent(
								getName("province"),
								getAddressError("province"),
							)}
							<Dropdown
								className={inputClasses}
								header="State/Province *"
								items={provinceItems}
								disabled={forceOtherProvince}
								name={province}
								selectedValue={
									forceOtherProvince ? provinceOther : selectedProvince
								}
								required
								theme="secondary"
								useFormik
								validateOnChange={props.validate}
								onSelect={(value) =>
									props.onDropdownSelect(
										getName("province") as DropdownFieldKey,
										value,
									)
								}
								defaultValue={getDefaultValue("province")}
							/>
						</div>
					</div>
					{showOtherProvince &&
						getOtherProvinceFields(
							getName("otherProvince"),
							getDefaultValue("otherProvince"),
							getTooltip("otherProvince"),
							getAddressError("otherProvince"),
						)}
				</div>
			);
		};

		const getPostalAddressFields = ({
			inputClasses,
			errors,
		}: {
			inputClasses: string;
			errors: FormikErrors<FormValues>;
		}) => {
			return (
				<>
					<h4 className="secondary-heading w-full">Postal Address</h4>
					{getAddressFields({
						inputClasses,
						fieldOptions: {
							address: {
								name: "postalAddress",
								defaultValue: props.defaultValues.postalAddress as
									| string
									| undefined,
								tooltip: "Please enter client's postal physical address",
								error: errors.postalAddress,
							},
							suburb: {
								name: "postalSuburb",
								defaultValue: props.defaultValues.postalSuburb as
									| string
									| undefined,
								tooltip: "Please enter client's postal suburb",
								error: errors.postalSuburb,
							},
							city: {
								name: "postalCity",
								defaultValue: props.defaultValues.postalCity as
									| string
									| undefined,
								tooltip: "Please enter client's postal city",
								error: errors.postalCity,
							},
							province: {
								name: "postalProvince",
								defaultValue: props.defaultValues.postalProvince as
									| string
									| undefined,
								tooltip: "Please enter client's postal province",
								error: errors.postalProvince,
							},
							otherProvince: {
								name: "postalProvinceOther",
								defaultValue: props.defaultValues.postalProvinceOther as
									| string
									| undefined,
								tooltip: "Please enter client's postal province",
								error: errors.postalProvinceOther,
							},
							postalCode: {
								name: "postalPostalCode",
								defaultValue: props.defaultValues.postalPostalCode as
									| string
									| undefined,
								tooltip:
									"Please enter the postal code for client's postal physical address",
								error: errors.postalPostalCode,
							},
							country: {
								name: "postalCountry",
								defaultValue: props.defaultValues.postalCountry as
									| string
									| undefined,
								tooltip: "Please enter client's postal country",
								error: errors.postalCountry,
							},
						},
					})}
				</>
			);
		};

		const getReferredByDetailsFields = (defaultValue: string | undefined) => {
			return (
				<Input
					className="w-full lg:w-auto flex-1 pr-0 force-grow"
					defaultValue={defaultValue}
					error={isFieldInError("referredByDetails")}
					name="referredByDetails"
					placeholder="Referred by *"
					required
					title="How did you hear about us? Please elaborate"
					useFormik
					validateOnChange={props.validate}
					onChange={() => props.onClearErrors("referredByDetails")}
				/>
			);
		};

		const getStep2 = () => {
			const defaultValues: FormValues = {
				...{
					idNumber: "",
					taxNumber: "",
					residentialAddress: "",
					residentialSuburb: "",
					residentialCity: "",
					residentialProvince: "",
					residentialProvinceOther: "",
					residentialPostalCode: "",
					residentialCountry: "",
					postalAddress: "",
					postalSuburb: "",
					postalCity: "",
					postalProvince: "",
					postalProvinceOther: "",
					postalPostalCode: "",
					postalCountry: "",
					isPostalSameAsResidentialAddress: undefined,
					referredBy: undefined,
					referredByDetails: undefined,
					acceptedTerms: undefined,
				},
				...props.defaultValues,
			};

			const inputClasses = "w-full lg:flex-1";

			return (
				<Formik initialValues={defaultValues} onSubmit={props.onSubmit}>
					{({ handleSubmit, validateForm, errors, values }) => {
						return (
							<>
								{getHeader()}
								<div className="grid sm:grid-cols-2 gap-y-4.5 gap-x-11 mb-4.5">
									<div className="grow mt-auto">
										{getFieldErrorContent("idNumber", errors.idNumber)}
										<Input
											className={inputClasses}
											defaultValue={
												defaultValues.idNumber as string | undefined
											}
											error={isFieldInError("idNumber")}
											maxLength={13}
											name="idNumber"
											placeholder="ID Number *"
											required
											title="Please enter client's valid ID number"
											useFormik
											validateOnChange={props.validate}
											onChange={() => props.onClearErrors("idNumber")}
										/>
									</div>
									<div className="grow mt-auto">
										{getFieldErrorContent("taxNumber", errors.taxNumber)}
										<Input
											className={inputClasses}
											defaultValue={
												defaultValues.taxNumber as string | undefined
											}
											error={isFieldInError("taxNumber")}
											maxLength={10}
											name="taxNumber"
											placeholder="Tax Number *"
											required
											title="Please enter client's valid tax number"
											useFormik
											validateOnChange={props.validate}
											onChange={() => props.onClearErrors("taxNumber")}
										/>
									</div>
								</div>
								<div className="flex flex-1 flex-wrap gap-y-4.5 gap-x-11 mb-9.5">
									<h4 className="secondary-heading w-full">
										Residential Address
									</h4>
									{getAddressFields({
										inputClasses,
										fieldOptions: {
											address: {
												name: "residentialAddress",
												defaultValue: defaultValues.residentialAddress as
													| string
													| undefined,
												tooltip:
													"Please enter client's residential physical address",
												error: errors.residentialAddress,
											},
											suburb: {
												name: "residentialSuburb",
												defaultValue: defaultValues.residentialSuburb as
													| string
													| undefined,
												tooltip: "Please enter client's residential suburb",
												error: errors.residentialSuburb,
											},
											city: {
												name: "residentialCity",
												defaultValue: defaultValues.residentialCity as
													| string
													| undefined,
												tooltip: "Please enter client's residential city",
												error: errors.residentialCity,
											},
											province: {
												name: "residentialProvince",
												defaultValue: defaultValues.residentialProvince as
													| string
													| undefined,
												tooltip: "Please enter client's residential province",
												error: errors.residentialProvince,
											},
											otherProvince: {
												name: "residentialProvinceOther",
												defaultValue: defaultValues.residentialProvinceOther as
													| string
													| undefined,
												tooltip: "Please enter client's residential province",
												error: errors.residentialProvinceOther,
											},
											postalCode: {
												name: "residentialPostalCode",
												defaultValue: defaultValues.residentialPostalCode as
													| string
													| undefined,
												tooltip:
													"Please enter the postal code for client's residential physical address",
												error: errors.residentialPostalCode,
											},
											country: {
												name: "residentialCountry",
												defaultValue: defaultValues.residentialCountry as
													| string
													| undefined,
												tooltip: "Please enter client's residential country",
												error: errors.residentialCountry,
											},
										},
									})}
									<div className="grow mt-auto w-full">
										{getFieldErrorContent(
											"isPostalSameAsResidentialAddress",
											errors.isPostalSameAsResidentialAddress,
										)}
										<Dropdown
											className="w-full"
											theme="secondary"
											name="isPostalSameAsResidentialAddress"
											header="Is your postal and residential address the same? *"
											items={[
												{ label: "No", value: "no" },
												{ label: "Yes", value: "yes" },
											]}
											onSelect={(value) =>
												props.onDropdownSelect(
													"isPostalSameAsResidentialAddress",
													value,
												)
											}
											required
											useFormik
											validateOnChange={props.validate}
											defaultValue={
												defaultValues.isPostalSameAsResidentialAddress as
													| string
													| undefined
											}
										/>
									</div>
									{props.showPostalAddressFields &&
										getPostalAddressFields({
											inputClasses,
											errors,
										})}
									{props.clients.length === 0 && !props.isValidReferralCode && (
										<div className="w-full">
											<div className="gap-x-11 gap-y-4.5 grid sm:grid-cols-2 mb-4.5">
												<div className={["grow mt-auto"].join(" ")}>
													{getFieldErrorContent(
														"referredBy",
														errors.referredBy,
													)}
													<Dropdown
														className="min-h-0 w-full lg:flex-1"
														theme="secondary"
														name="referredBy"
														header="How did you hear about us? *"
														items={referrerOptions}
														onSelect={(value) =>
															props.onDropdownSelect("referredBy", value)
														}
														required
														useFormik
														defaultValue={
															defaultValues.referredBy as string | undefined
														}
													/>
												</div>
												{props.showHeardAboutUsFields && (
													<div className="grow mt-auto">
														{getFieldErrorContent(
															"referredByDetails",
															errors.referredByDetails,
														)}
														{getReferredByDetailsFields(
															defaultValues.referredByDetails as
																| string
																| undefined,
														)}
													</div>
												)}
											</div>
										</div>
									)}
									<div
										className={[
											"terms-and-conditions flex flex-col gap-y-[15px] w-full",
										].join(" ")}
									>
										<h4 className="secondary-heading">
											Terms and Conditions *
										</h4>
										<div className="grow mt-auto">
											{getFieldErrorContent(
												"acceptedTerms",
												errors.acceptedTerms,
											)}
											<Checkbox
												name="acceptedTerms"
												label={
													<div
														dangerouslySetInnerHTML={{
															__html: `I agree to the <a href=${links.legal.termsAndConditions} target="_blank">Terms and Conditions</a> and <a href=${links.legal.privacyPolicy} target="_blank">Privacy Policy</a>`,
														}}
													/>
												}
												onClick={() => props.onClearErrors("acceptedTerms")}
												required
												useFormik
												validateOnChange={props.validate}
												defaultValue={
													defaultValues.acceptedTerms as boolean | undefined
												}
											/>
										</div>
									</div>
								</div>
								{getError(Object.keys(errors).length > 0)}
								<div className="flex justify-center mx-auto">
									<Button
										className="w-full font-primary-medium text-lg"
										type="button"
										theme="tertiary"
										onClick={() => {
											props.onBack(values);
										}}
									>
										<FaChevronLeft className="mr-1" size="14px" />
										BACK
									</Button>
									<Button
										className="w-full mx-1"
										type="button"
										theme="primary"
										disabled={props.isSubmitting}
										onClick={async () =>
											props.onValidate(await validateForm(), handleSubmit)
										}
									>
										SUBMIT
									</Button>
								</div>
							</>
						);
					}}
				</Formik>
			);
		};

		const getForms = () => {
			return [getStep1(), getStep2()];
		};

		const showWelcomeModal =
			props.showWelcome &&
			props.clients.length === 0 &&
			!props.firstClientDisclaimed &&
			props.open;

		const showNewClientDisclaimerModal =
			props.clients.length === 1 &&
			!props.firstClientDisclaimed &&
			props.open &&
			props.showDisclaimer;

		const showWizard = !props.showWelcome && props.open && props.showWizard;

		return (
			<>
				{showWelcomeModal && (
					<WelcomeModal open onProceed={props.onDisclaimerProceed} />
				)}
				{showNewClientDisclaimerModal && (
					<NewClientDisclaimerModal
						open
						onClose={props.onClose}
						onCloseTransitionEnd={props.onShowWizard}
						onProceed={props.onDisclaimerProceed}
					/>
				)}
				{showWizard && (
					<Modal
						className="new-client-modal w-full h-full flex flex-col items-center"
						overlayColor="light"
						open
						onClose={props.onClose}
						onCloseTransitionEnd={props.onResetWizard}
					>
						<div className="new-client-container flex flex-col">
							<Wizard
								step={props.step}
								forms={getForms()}
								isComplete={props.step >= props.totalSteps}
								completeForm={
									<h1 className="success font-primary-bold mt-3">
										New client successfully added
									</h1>
								}
							/>
						</div>
					</Modal>
				)}
			</>
		);
	},
);
