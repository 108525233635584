import { Modal } from "@app/components/modals/modal";
import { Button } from "@app/controls/button";
import { Transition } from "@headlessui/react";
import { memo } from "react";
import "./activate-account-modal.css";

const ActivateAccountModalView = memo(
	(props: {
		open: boolean;
		onClose: () => void;
		onResend: () => void;
		isResendingEmail: boolean;
		formattedThrottling: string | undefined;
	}) => {
		return (
			<Modal open={props.open} onClose={props.onClose}>
				<h1 className="activate-account-modal-heading font-primary-bold mb-8 text-2xl">
					Activate Account
				</h1>
				<div className="flex flex-col gap-y-2 text-lg activate-account-modal-text">
					<span className="font-primary-regular">
						Your account has not yet been activated.
					</span>
					<span className="font-primary-regular mt-4">
						An activation email was previously sent to your email address.
					</span>
					<span className="font-primary-regular">
						If you did not receive this email, please resend it below.
					</span>
					<span className="font-primary-regular mt-4">
						You can login after your account has been activated.
					</span>
				</div>
				<div className={props.formattedThrottling ? "mt-4" : "mt-6"}>
					<Transition
						show={props.formattedThrottling !== undefined}
						enter="transition ease-out duration-300"
						leave="transition east-out duration-300"
						enterFrom="opacity-0 translate-y-5"
						enterTo="opacity-100 translate-y-0"
						leaveTo="opacity-0 -translate-y-5"
					>
						<div className="flex flex-col gap-y-2">
							<div className="font-primary-regular activate-account-modal-error">
								Please wait before trying again
							</div>
						</div>
					</Transition>
					<Button
						className="w-full font-primary-medium py-3 px-5"
						disabled={
							props.formattedThrottling !== undefined || props.isResendingEmail
						}
						theme="primary"
						onClick={props.onResend}
					>
						{(props.formattedThrottling ?? props.isResendingEmail)
							? "RESENDING EMAIL"
							: "RESEND EMAIL"}
					</Button>
				</div>
			</Modal>
		);
	},
);

export { ActivateAccountModalView };
