import type { ValidationErrors } from "@app/constants/validation-errors";
import { FormikControlWrapper } from "@app/wrappers/formik-control-wrapper";
import { useEffect, useState } from "react";
import { CheckboxView } from "./checkbox-view";
import type { Properties } from "./properties";

export const Checkbox = (props: Properties) => {
	const getError = (checked?: boolean): ValidationErrors | undefined => {
		if (!checked && props.required) return "required";
		return;
	};

	const [state, setState] = useState<{
		checked?: boolean;
		error?: string;
	}>({
		checked: props.checked,
		error: undefined,
	});

	const onClick = () => {
		let error = state.error;
		const checked = !state.checked;

		if (!props.useFormik && props.validateOnChange) error = getError(checked);

		setState({ ...state, checked: checked, error: error });
	};

	const onValidate = (value: boolean) => {
		const error = getError(value);

		setState({ ...state, error: getError(value) });

		return error;
	};

	useEffect(() => {
		if (state.checked !== props.checked)
			setState({ ...state, checked: props.checked });
	}, [props.checked]);

	useEffect(() => {
		if (
			props.defaultValue !== undefined &&
			state.checked !== props.defaultValue
		)
			setState({ ...state, checked: props.defaultValue });
	}, [props.defaultValue]);

	return (
		<FormikControlWrapper
			name={props.name || "checkbox"}
			value={state.checked ?? props.defaultValue}
			error={state.error}
			component={
				<CheckboxView
					{...props}
					checked={state.checked ?? props.defaultValue}
					onClick={onClick}
					hasError={state.error !== undefined}
				/>
			}
			useFormik={props.useFormik}
			validateOnBlur={props.validateOnBlur}
			validateOnChange={props.validateOnChange}
			validate={onValidate}
		/>
	);
};
