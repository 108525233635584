import { useEffect, useState } from "react";
import { TradeHistoryAccordionView } from "./trade-history-accordion-view";

interface TradeHistoryAccordionState {
	open: boolean;
}

export const TradeHistoryAccordion = (props: {
	amountInvested?: string;
	netProfit?: string;
	netReturn?: string;
	open?: boolean;
	tradeId: string;
	onDownloadStatement?: () => void;
}) => {
	const defaultState: TradeHistoryAccordionState = {
		open: props.open || false,
	};

	const [state, setState] = useState<TradeHistoryAccordionState>(defaultState);

	const onToggleOpen = (open?: boolean) => {
		setState({ ...state, open: open ?? !state.open });
	};

	useEffect(() => {
		if (props.open !== undefined && props.open !== state.open) {
			setState({ ...state, open: props.open });
		}
	}, [props.open]);

	return (
		<TradeHistoryAccordionView
			{...props}
			open={state.open}
			onToggleOpen={onToggleOpen}
		/>
	);
};
