const NotFound = () => {
	return (
		<div className="py-5">
			<div className="w-full">
				<div>Not Found</div>
				<div>The page you are looking for could not be found</div>
			</div>
		</div>
	);
};

export default NotFound;
