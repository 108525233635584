const messages = {
	en: {
		default: {
			accountHolder: "XXXXXXXXXX",
			accountNumber: "000000000",
			accountType: "XXXXXXXXX",
			bankName: "XXXXXXXXXX",
			branchCode: "000000",
			rmName: "",
			rmEmail: "",
			clientName: "",
			percent: "0%",
			status: "Deposit Arrived - Awaiting Settlement",
			value: "R 0",
			valueBasic: "0",
			valueLastUpdated: "",
			valueLastUpdatedWarning: "",
		},
		fia: "AIT (FIA) Remaining",
		header: "Dashboard",
		sda: "SDA Remaining",
		linkKrakenAccount: "Link Kraken Account",
	},
};

export default messages;
