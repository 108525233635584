import { Graphing } from "@app/components/graphing";
import { Dropdown } from "@app/controls/dropdown";
import { memo, useMemo } from "react";
import type { ViewProperties } from "./properties";
import "./statistics-card.css";

const StatisticsCardView = memo((props: ViewProperties) => {
	const chartMin = useMemo(() => {
		return props.chartData
			? Math.max(0, Math.min(...props.chartData?.data) - 1)
			: 0;
	}, [props.chartData]);

	const mainStyle = [
		"flex",
		"flex-col",
		"grow",
		"px-5",
		"lg:px-7",
		"pb-4",
		"pt-6",
		"lg:pb-7",
		"relative",
		"statistics-card",
		props.className,
	].join(" ");

	const getGraphContent = () => (
		<Graphing
			autoSkip={false}
			className="flex w-full h-full statistics-card-graph"
			datasets={props.chartData ? [props.chartData] : []}
			loading={props.loading}
			labels={props.chartLabels ?? []}
			maxTicksLimit={props.chartData?.data.length ?? undefined}
			type={props.chartType}
			min={chartMin}
			yAxisExaggeration={0.9}
			onRenderTooltipLabel={props.onRenderTooltipLabel}
		/>
	);

	const getHeaderContent = () => (
		<div className="flex flex-col gap-y-4">
			<div className="flex flex-row justify-between gap-x-4 gap-y-4 flex-wrap">
				<div className="font-primary-bold statistics-card-heading">
					Statistics
				</div>
				<Dropdown
					className="mb-4 lg:mb-0"
					items={props.items}
					selectedValue={props.defaultSelection}
					theme="primary"
					onSelect={props.onChange}
				/>
			</div>
			<div className="font-primary-light text-3xl statistics-card-figure">
				{props.value}
			</div>
		</div>
	);

	return (
		<div className={mainStyle}>
			{getHeaderContent()}
			{getGraphContent()}
		</div>
	);
});

export { StatisticsCardView };
