import React from "react";
import { DepositFundsModalView } from "./deposit-funds-modal-view";
import type { Properties } from "./properties";

export const DepositFundsModal = (props: Properties) => {
	const [isComplete, setIsComplete] = React.useState(false);

	const onNextStep = () => {
		if (!isComplete) setIsComplete(true);
		else props.onClose();
	};

	return (
		<DepositFundsModalView
			{...props}
			onNextStep={onNextStep}
			isComplete={isComplete}
		/>
	);
};
