import { combineReducers } from "redux";
import arbitragerReducer from "./arbitrager/arbitrager-slice";
import authReducer from "./auth/auth-slice";
import clientsReducer from "./clients/clients-slice";
import languageReducer from "./language/language-slice";
import lookupReducer from "./lookup/lookup-slice";
import notificationsReadReducer from "./notifications-read/notifications-read-slice";
import notificationsReducer from "./notifications/notifications-slice";
import withdrawalsReducer from "./withdrawal/withdrawal-slice";

const combinedReducers = combineReducers({
	arbitrager: arbitragerReducer,
	auth: authReducer,
	language: languageReducer,
	lookup: lookupReducer,
	clients: clientsReducer,
	notifications: notificationsReducer,
	notificationsRead: notificationsReadReducer,
	withdrawals: withdrawalsReducer,
});

export type RootState = ReturnType<typeof combinedReducers>;

const resetExclusions = ["notificationsRead"];

/**
 * This completely resets the redux store (typically used when logging out.)
 */
const rootReducer = (state: any, action: any) => {
	if (action.type === "RESET") {
		/**
		 * Do not change theme on logout.  It must go through "setTheme" action.  (Otherwise body style and store do not match)
		 */
		var newState: { [key: string]: any } = {};

		Object.keys(state).forEach((x) => {
			if (resetExclusions.includes(x)) newState[x] = state[x];
		});

		state = newState;
	}
	return combinedReducers(state, action);
};

export default rootReducer;
