export const downloadFile = (
	contentType: string,
	data: string,
	fileName: string,
): void => {
	const element = document.createElement("a");
	const dataUri = data.includes(",")
		? data
		: `data:${contentType};base64,${data}`;

	element.href = dataUri;
	element.download = fileName;

	document.body.appendChild(element);
	element.click();
	element.remove();
};
