import { Button } from "@app/controls/button";
import React from "react";
import { AccordionCard } from "../accordion-card";
import type { ViewProperties } from "./properties";
import "./trade-history-accordion.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const TradeHistoryAccordionView = React.memo((props: ViewProperties) => {
	const labelStyle = [
		"font-primary-medium text-lg",
		"trade-accordion-label",
	].join(" ");

	const rowStyle = ["flex", "flex-row", "justify-between"].join(" ");

	const headerStyle = [
		rowStyle,
		"font-primary-medium text-lg",
		"items-center",
		"px-6",
		"py-6",
		"trade-accordion-header",
	].join(" ");

	const getChildContent = () => (
		<div className="border-y-2 pb-3.5 pt-3 px-6 trade-accordion-panel">
			<div className="flex flex-col gap-y-2">
				<div className={rowStyle}>
					<div className={labelStyle}>Amount Invested</div>
					<div className="font-primary-regular text-lg trade-accordion-figure">
						{props.amountInvested}
					</div>
				</div>
				<div className={rowStyle}>
					<div className={labelStyle}>Net Profit</div>
					<div className="font-primary-regular text-lg trade-accordion-figure">
						{props.netProfit}
					</div>
				</div>
				<div className={rowStyle}>
					<div className={labelStyle}>Net Return</div>
					<div className="font-primary-regular text-lg trade-accordion-figure">
						{props.netReturn}
					</div>
				</div>
				<div className={rowStyle}>
					<div className={labelStyle}>Statement</div>
					<div className="font-primary-regular text-lg">
						<Button
							className="font-primary-regular text-lg px-0 py-0 trade-accordion-action"
							theme="secondary"
							onClick={props.onDownloadStatement}
							disabled={!props.onDownloadStatement}
						>
							Download
						</Button>
					</div>
				</div>
			</div>
		</div>
	);

	const getHeaderContent = () => {
		return (
			<div className={headerStyle}>
				<div className="border-b-2 trade-accordion-heading trade-accordion-label">
					{props.tradeId}
				</div>
				<div className="border px-2.5 py-2 trade-accordion-chevron">
					{props.open ? (
						<FaChevronUp className="icon-filled" size="14px" />
					) : (
						<FaChevronDown className="icon-filled" size="14px" />
					)}
				</div>
			</div>
		);
	};

	return (
		<AccordionCard
			headerContent={getHeaderContent()}
			open={props.open}
			onToggleOpen={props.onToggleOpen}
		>
			{getChildContent()}
		</AccordionCard>
	);
});

export { TradeHistoryAccordionView };
