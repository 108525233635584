import { StatusModal } from "@app/components/modals/status-modal";
import { type Row, Table } from "@app/components/table";
import { Button } from "@app/controls/button";
import {
	type FormatNumberOptions,
	formatNumber,
} from "@app/utils/format-number";
import React from "react";
import "./summary-of-trades-card.css";
import clsx from "clsx";
import { FiDownload } from "react-icons/fi";

export interface Trade {
	id: number;
	amount: string;
	netReturn: string;
	netProfit: string;
}

export const SummaryOfTradesCardView = React.memo(
	(props: {
		className?: string;
		data: Trade[];
		errors?: string;
		mobile?: boolean;
		showErrors?: boolean;
		onClearErrors: () => void;
		onDownloadTradeStatement: (id: number) => void;
		onViewAll: () => void;
	}) => {
		const getHeaders = () => {
			return {
				cells: [
					{
						content: "Amount",
					},
					{
						content: "Net Return",
					},
					{
						content: "Net Profit",
					},
					{
						content: <></>,
					},
				],
			};
		};

		const getRows = () => {
			const currencyOptions: FormatNumberOptions = {
				currencyCode: "R ",
				decimalPlaces: props.mobile ? 0 : 2,
				groupSeparator: ",",
			};

			const percentageOptions: FormatNumberOptions = {
				decimalPlaces: 2,
				groupSeparator: ",",
				isPercentage: true,
			};

			return props.data.map<Row>((trade) => {
				const netReturn = +trade.netReturn;

				return {
					cells: [
						{
							className: "whitespace-nowrap font-primary-medium",
							content:
								+trade.amount > 0
									? formatNumber(trade.amount, currencyOptions)
									: "R 0",
						},
						{
							content:
								netReturn > 0
									? formatNumber(netReturn * 100, percentageOptions)
									: "0%",
						},
						{
							className: "whitespace-nowrap",
							content:
								+trade.netProfit > 0
									? formatNumber(trade.netProfit, currencyOptions)
									: "R 0",
						},
						{
							content: (
								<div className="flex justify-end">
									<Button
										className="icon-button shrink-0 grow-0 p-0 mr-1 lg:mr-6 opacity-50"
										onClick={() => props.onDownloadTradeStatement(trade.id)}
									>
										<FiDownload className="summary-of-trades" size="20px" />
									</Button>
								</div>
							),
						},
					],
				};
			});
		};

		return (
			<>
				<div
					className={clsx("summary-of-trades pt-5 bg-white", props.className)}
				>
					<div className="header flex justify-between items-center mb-5 gap-x-3 px-5 and lg:px-7">
						<h1 className="font-primary-bold">Trade History</h1>
						<Button
							className="grow-0 w-auto font-primary-medium text-lg"
							theme={"secondary"}
							onClick={props.onViewAll}
						>
							View all
						</Button>
					</div>
					{props.data.length === 0 ? (
						<div className="font-primary-regular my-14 opacity-70 text-base text-center">
							No trade data to display
						</div>
					) : (
						<Table headers={getHeaders()} data={getRows()} />
					)}
				</div>
				{props.showErrors && (
					<StatusModal
						open
						message={props.errors}
						status="ERROR"
						showCloseButton
						onClose={props.onClearErrors}
					/>
				)}
			</>
		);
	},
);
