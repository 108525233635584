import { commonMessages } from "@app/constants/common-messages";
import type { ValidationErrors } from "@app/constants/validation-errors";
import { Button } from "@app/controls/button";
import { Input } from "@app/controls/input";
import type { RootState } from "@app/redux";
import { Transition } from "@headlessui/react";
import { Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "../modal";
import "./change-email-modal.css";
import messages from "./messages";
import type { FormKey } from "./models/form";
import type { ViewProperties } from "./properties";

export const ChangeEmailModalView = React.memo((props: ViewProperties) => {
	const { language } = useSelector((state: RootState) => state.language);

	const getErrors = (error?: string) => {
		const errors = error ? [error] : props.errors || [];

		return (
			<Transition
				show={errors.length > 0}
				enter="transition ease-out duration-300"
				enterFrom="opacity-0 translate-y-5"
			>
				{errors.join(", ")}
			</Transition>
		);
	};

	const getMappedErrorContent = (
		mappedError?: string[],
		localError?: string,
	) => {
		if (!mappedError && !localError) {
			return undefined;
		}

		let errors = [];

		if (localError) {
			if (localError in commonMessages[language].validationError)
				errors.push(
					commonMessages[language].validationError[
						localError as ValidationErrors
					],
				);
			else errors.push(localError);
		}

		if (errors.length === 0 && mappedError) errors = mappedError;

		return (
			<Transition
				show={props.mappedReasons !== undefined || localError !== undefined}
				enter="transition ease-out duration-300"
				enterFrom="opacity-0 translate-y-5"
			>
				<div
					className="api-error"
					dangerouslySetInnerHTML={{
						__html: `<p>${errors.join("</p><p>")}</p>`,
					}}
				/>
			</Transition>
		);
	};

	const getInputField = (options: {
		fieldName: FormKey;
		className: string;
		tooltip?: string;
		type?: "email" | "password";
		error?: string;
	}) => {
		const label = messages[language][options.fieldName];

		const mappedErrorContent = getMappedErrorContent(
			props.mappedReasons ? props.mappedReasons[options.fieldName] : undefined,
			options.error,
		);

		return (
			<>
				<label className="mb-2">{label}</label>
				{mappedErrorContent}
				<Input
					className={options.className}
					error={mappedErrorContent !== undefined}
					hideIcon
					name={options.fieldName}
					theme="secondary"
					title={options.tooltip}
					type={options.type}
					peekablePassword={options.type === "password"}
					placeholder={messages[language][options.fieldName]}
					required
					useFormik
					validateOnChange={props.validate}
					onChange={() => props.onClearErrors(options.fieldName)}
				/>
			</>
		);
	};

	const emailIsInvalid = (email: string) => {
		return /[^\d\w+-_@]/.test(email) || /[\=\^]/.test(email);
	};

	return (
		<Modal
			className="change-email-modal"
			open={props.open}
			onClose={props.onClose}
		>
			<h1 className="font-primary-bold mb-3">{messages[language].header}</h1>
			<Formik
				initialValues={{
					newUsername: "",
					currentPassword: "",
				}}
				onSubmit={props.onFormSubmit}
			>
				{({ handleSubmit, validateForm, values, errors }) => {
					const invalidEmail: ValidationErrors | undefined =
						props.validate && emailIsInvalid(values.newUsername)
							? "email"
							: undefined;

					return (
						<>
							<div className="api-error mb-3">
								{invalidEmail
									? getErrors(messages[language].error.emailInvalid)
									: getErrors()}
							</div>
							{getInputField({
								fieldName: "newUsername",
								className: "mb-6",
								tooltip: messages[language].tooltip.newEmail,
								type: "email",
								error: invalidEmail ?? errors.newUsername,
							})}
							{getInputField({
								fieldName: "currentPassword",
								className: "mb-6",
								error: errors.currentPassword,
								tooltip: messages[language].tooltip.password,
								type: "password",
							})}
							<Button
								className="font-primary-medium submit"
								theme="primary"
								onClick={async () =>
									props.onValidate(
										await validateForm(),
										undefined,
										handleSubmit,
									)
								}
							>
								Submit
							</Button>
						</>
					);
				}}
			</Formik>
		</Modal>
	);
});
