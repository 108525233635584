import { Button } from "@app/controls/button";
import type { RootState } from "@app/redux";
import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "../modal";
import "./has-account-modal.css";
import messages from "./messages";
import type { Properties } from "./properties";

const HasAccountModalView = React.memo((props: Properties) => {
	const { language } = useSelector((state: RootState) => state.language);
	// #region

	return (
		<Modal open={props.open} onClose={props.onClose}>
			<h1 className="font-primary-bold mb-8 has-account-modal-heading">
				{messages[language].header}
			</h1>
			<div className="flex flex-col mb-9 has-account-modal-text">
				<div>{messages[language].warning}</div>
				<div>{messages[language].action}</div>
			</div>
			<Button
				className="py-3 w-full uppercase font-primary-medium select-none"
				theme="primary"
				onClick={props.onLogin}
			>
				{messages[language].loginButton}
			</Button>
		</Modal>
	);
});

export { HasAccountModalView };
