const messages = {
	en: {
		errors: {
			fieldsRequired: "Please complete the required fields highlighted in red",
			generic: "There are errors in your submission. Please see above.",
		},

		tooltip: {
			bankName: "Please enter your bank name",
			branchCode: "Please enter your branch code",
			accountType: "Please choose your account type",
			accountNumber: "Please enter your account number",
		},

		accountTypes: {
			bond: "Bond Account",
			cheque: "Current/Cheque Account",
			credit: "Credit Card Account",
			savings: "Savings Account",
			subscription: "Subscription Account",
			transmission: "Transmission Account",
		},
		bankName: "Bank *",
		branchCode: "Branch Code *",
		accountType: "Account Type *",
		accountNumber: "Account Number *",
		accessibleBy: "Allow spouse to withdraw to this account?",

		submit: "SUBMIT",
	},
};

export default messages;
