import { useState } from "react";
import { NotificationLineView } from "./notification-line-view";
import type { Properties } from "./properties";

export const NotificationLine = (props: Properties) => {
	const [open, setOpen] = useState(false);

	const onToggle = () => {
		setOpen(!open);
	};

	return <NotificationLineView {...props} open={open} onToggle={onToggle} />;
};
