import type {
	AccessibleBy,
	AccountType,
	WithdrawalAccount,
} from "@app/entities";
import { useRetrieveWithdrawalBankList } from "@app/helpers";
import type { RootState } from "@app/redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AccountDeletionModal } from "../account-deletion-modal";
import { BankAccountModal } from "../bank-account-modal";
import { WithdrawAmountModal } from "../withdraw-amount-modal";
import { WithdrawSuccessModal } from "../withdraw-success-modal";
import { AccountSelectionModalView } from "./account-selection-modal-view";
import type { Properties } from "./properties";

export const AccountSelectionModal = (props: Properties) => {
	const { withdrawalBanks, retrieveWithdrawalBankListLoading } = useSelector(
		(rootState: RootState) => rootState.withdrawals,
	);
	const { activeClient } = useSelector(
		(rootState: RootState) => rootState.clients,
	);

	const [retrieveWithdrawalBankList] = useRetrieveWithdrawalBankList();

	const defaultState = {
		stepNumber: 1,
		showAccountSelectModal: true,
		showDeleteModal: false,
		showEditModal: false,
		showWithdrawModal: false,
		showWithdrawSuccessModal: false,
		showBankAccountModal: false,
		withdrawAll: true,
		straightThroughProcess: false,
		bankAccountModalMode: "add",
		accessibleBy: "" as AccessibleBy,
		accountNumber: "",
		accountType: "" as AccountType,
		bankName: "",
		branchCode: "",
		bankId: 0,
		clientId: activeClient?.id ?? 0,
	};

	const [state, setState] = useState(defaultState);

	const onEdit = (account: WithdrawalAccount) => {
		getAccountInfo(account);

		setState((state) => ({
			...state,
			showBankAccountModal: true,
			showAccountSelectModal: false,
			bankAccountModalMode: "edit",
		}));
	};

	const onAdd = () => {
		getAccountInfo({} as WithdrawalAccount);

		setState((state) => ({
			...state,
			showBankAccountModal: true,
			showAccountSelectModal: false,
			bankAccountModalMode: "add",
		}));
	};

	const getAccountInfo = (account: WithdrawalAccount) => {
		setState((state) => {
			return {
				...state,
				accessibleBy: account.accessibleBy,
				accountNumber: account.accountNumber,
				accountType: account.accountType,
				bankName: account.bankName,
				branchCode: account.branchCode,
				bankId: account.id,
				clientId: account.clientId,
			};
		});
	};

	const onToggleDeleteModal = (account?: WithdrawalAccount) => {
		const newState = {
			...state,
			accessibleBy: account?.accessibleBy || ("" as AccessibleBy),
			accountNumber: account?.accountNumber || "",
			accountType: account?.accountType || ("" as AccountType),
			bankName: account?.bankName || "",
			branchCode: account?.branchCode || "",
			bankId: account?.id || 0,
			clientId: account?.clientId || (activeClient?.id ?? 0),
			showDeleteModal: !state.showDeleteModal,
			showAccountSelectModal: !state.showAccountSelectModal,
		};
		setState(() => ({ ...newState }));
		if (newState.showAccountSelectModal) retrieveWithdrawalBankList();
	};

	const onShowDelete = (account: WithdrawalAccount) => {
		getAccountInfo(account);
		onToggleDeleteModal(account);
	};

	const onToggleBankAccountModal = () => {
		const newState = { ...state };

		newState.showBankAccountModal = !newState.showBankAccountModal;
		newState.showAccountSelectModal = !newState.showAccountSelectModal;

		setState(() => ({ ...newState }));

		if (!newState.showBankAccountModal) retrieveWithdrawalBankList();
	};

	const onSelectAccount = (account: WithdrawalAccount) => {
		getAccountInfo(account);
		ChangeStep(true, account);
	};

	const ChangeStep = (addStep = true, account?: WithdrawalAccount) => {
		let stepNumber = state.stepNumber;
		if (addStep) {
			stepNumber = ++stepNumber;
		} else {
			stepNumber = --stepNumber;
		}

		switch (stepNumber) {
			case 1:
				setState({
					...state,
					showAccountSelectModal: true,
					showWithdrawModal: false,
					showWithdrawSuccessModal: false,
					stepNumber,
				});
				break;
			case 2:
				setState({
					...state,
					showAccountSelectModal: false,
					showWithdrawModal: true,
					showWithdrawSuccessModal: false,
					accountNumber: account!.accountNumber,
					accountType: account!.accountType,
					bankName: account!.bankName,
					branchCode: account!.branchCode,
					bankId: account!.id,
					stepNumber,
				});
				break;
			case 3:
				setState({
					...state,
					showAccountSelectModal: false,
					showWithdrawModal: false,
					showWithdrawSuccessModal: true,
					stepNumber,
				});
				break;

			default:
				break;
		}
	};

	const getBankAccount = (): WithdrawalAccount => {
		return {
			accessibleBy: state.accessibleBy,
			accountNumber: state.accountNumber,
			accountType: state.accountType,
			bankName: state.bankName,
			branchCode: state.branchCode,
			clientId: state.clientId,
			id: state.bankId,
		};
	};

	useEffect(() => {
		retrieveWithdrawalBankList();
	}, []);

	return (
		<>
			{state.showAccountSelectModal && (
				<AccountSelectionModalView
					{...props}
					disabled={props.disabled}
					onAdd={onAdd}
					onEdit={onEdit}
					onShowDelete={onShowDelete}
					onSelectAccount={onSelectAccount}
					data={withdrawalBanks ?? []}
					dataLoading={retrieveWithdrawalBankListLoading}
				/>
			)}
			{state.showDeleteModal && (
				<AccountDeletionModal
					open
					disabled={props.disabled}
					onClose={onToggleDeleteModal}
					accountNumber={state.accountNumber}
					onDelete={() => {
						onToggleDeleteModal();
						retrieveWithdrawalBankList();
					}}
					accountType={state.accountType}
					bankName={state.bankName}
					branchCode={state.branchCode}
					bankId={state.bankId}
				/>
			)}
			{state.showWithdrawModal && !props.showWithdrawSuccessModal && (
				<WithdrawAmountModal
					open
					disabled={props.disabled}
					withdrawAll={state.withdrawAll}
					onClose={() => ChangeStep(false)}
					onSubmit={props.onSubmitWithdrawal}
					accountNumber={state.accountNumber}
					accountType={state.accountType}
					bankId={state.bankId}
					bankName={state.bankName}
					branchCode={state.branchCode}
					mappedReasons={props.mappedReasons}
				/>
			)}
			{props.showWithdrawSuccessModal && (
				<WithdrawSuccessModal
					open
					onClose={props.onToggleWithdrawSuccessModal}
					straightThroughProcess={props.straightThroughProcess}
				/>
			)}
			{state.showBankAccountModal && (
				<BankAccountModal
					open
					mode={state.bankAccountModalMode as "add" | "edit"}
					onClose={onToggleBankAccountModal}
					bankAccount={getBankAccount()}
				/>
			)}
		</>
	);
};
