export const loginErrors = {
	inactiveAccount: "User inactive or deleted.",
};

export const apiErrors = {
	invalidToken: "Invalid token",
	noCredentials: "Invalid token header. No credentials provided.",
	throttled: "Request was throttled.",
	tokenExpired: "Token has expired",
};

export const registrationErrors = {
	duplicateAccount: "A user with that username already exists.",
};
