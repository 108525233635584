import successTick from "@app/assets/images/wizard-last-step.svg";
import { Button } from "@app/controls/button";
import type { RootState } from "@app/redux";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Modal } from "../modal";
import "./logout-modal.css";
import type { Properties } from "./properties";

const LogoutModalView = memo((props: Properties) => {
	const { language } = useSelector(
		(rootState: RootState) => rootState.language,
	);

	const containerStyle = ["flex", "flex-col", "gap-y-6"].join(" ");

	const headingStyle = [
		"font-primary-bold",
		"mb-3",
		"text-center",
		"logout-modal-header",
	].join(" ");

	return (
		<Modal noCloseButton open={props.open} onClose={props.onClose}>
			<div className={containerStyle}>
				<img className="mx-auto" src={successTick} alt="success-tick" />
				<h1 className={headingStyle}>Successfully logged out</h1>
				<Button
					className="font-primary-medium pt-3 pb-3"
					theme="primary"
					onClick={props.onClose}
				>
					OK
				</Button>
			</div>
		</Modal>
	);
});

export { LogoutModalView };
