import { AllowanceCard } from "@app/components/allowance-card";
import { BalanceCard } from "@app/components/balance-card";
import { CustomLoader } from "@app/components/custom-loader";
import { Footer } from "@app/components/footer";
import { MinimumReturnCard } from "@app/components/minimum-return-card";
import { AccountSelectionModal } from "@app/components/modals/account-selection-modal";
import { DepositFundsModal } from "@app/components/modals/deposit-funds-modal";
import { StatusModal } from "@app/components/modals/status-modal";
import { Navbar } from "@app/components/navbar";
import { Sidebar } from "@app/components/sidebar";
import { SpreadGraphCard } from "@app/components/spread-graph-card";
import { StatisticsCard } from "@app/components/statistics-card";
import { SummaryOfTradesCard } from "@app/components/summary-of-trades-card";
import { TradeStatusCard } from "@app/components/trade-status-card";
import type { DropdownItem, Statistic } from "@app/entities";
import type { RootState } from "@app/redux";
import { formatNumber } from "@app/utils/format-number";
import { memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import "./dashboard.css";
import messages from "./messages";
import type { Properties } from "./properties";

import clsx from "clsx";
import { useMediaQuery } from "../../hooks/use-media-query";
import { ForexBanner } from "./forex-banner";
import styles from "./index.module.css";

export const DashboardView = memo((props: Properties) => {
	const [bannerShown, setBannerShown] = useState(false);
	const { language } = useSelector((state: RootState) => state.language);
	const isMobile = useMediaQuery();
	const statisticItems: DropdownItem<string>[] = useMemo(() => {
		if (!props.periodSettings) {
			return [];
		}

		return props.periodSettings?.statsChart.map<DropdownItem<string>>((x) => {
			return {
				value: x.slug,
				label: x.label,
			};
		});
	}, [props.periodSettings]);

	const defaultStatsSelection = useMemo(() => {
		return props.statistics
			? props.statistics.slug
			: props.activeClient
				? props.activeClient.defaultStat?.slug
				: "";
	}, [props.statistics, props.activeClient]);

	const getStatisticsValue = () => {
		const isStatistics = (props.statistics?.data ?? []).length > 0;

		const isActiveClientStatistics =
			(props.activeClient?.defaultStat?.data ?? []).length > 0;

		if (
			(!defaultStatsSelection && !props.statistics) ||
			(!isStatistics && !isActiveClientStatistics)
		) {
			return messages[language].default.valueBasic;
		}

		let statisticsDataSet: Statistic[] = [];
		let statisticsFinalLabel: string | undefined = undefined;
		let statisticsSlug: string;

		if (isStatistics) {
			statisticsDataSet = props.statistics?.data ?? [];
			statisticsFinalLabel =
				statisticsDataSet[Math.max(0, statisticsDataSet?.length - 1)].y.label;
			statisticsSlug = props.statistics?.slug ?? "";
		} else if (isActiveClientStatistics) {
			statisticsDataSet = props.activeClient?.defaultStat?.data ?? [];
			statisticsFinalLabel =
				statisticsDataSet[Math.max(0, statisticsDataSet.length - 1)].y.label;
			statisticsSlug = props.activeClient?.defaultStat?.slug ?? "";
		} else {
			statisticsDataSet = [];
			statisticsFinalLabel = undefined;
			statisticsSlug = "";
		}

		switch (statisticsSlug) {
			case "profit_to_date": {
				return statisticsFinalLabel ?? messages[language].default.value;
			}
			case "return_to_date": {
				return statisticsFinalLabel ?? messages[language].default.percent;
			}
			case "num_cycles": {
				if (statisticsDataSet.length > 0) {
					return String(
						statisticsDataSet
							.map((stat) => stat.y.value)
							.reduce((previous, current) => {
								return previous + current;
							}),
					);
				}
				return messages[language].default.valueBasic;
			}
			default: {
				return statisticsFinalLabel ?? messages[language].default.valueBasic;
			}
		}
	};

	const summaryOfTrades = props.tradeList.map((x) => ({
		id: x.id,
		amount: x.zarIn,
		netReturn: x.clientProfitPercentage,
		netProfit: x.clientProfit,
	}));

	return (
		<>
			<div className="dashboard-screen min-h-screen w-full">
				<Navbar />
				<div className="content-container flex">
					<Sidebar />
					<div className="content flex min-w-0 flex-col grow">
						<div className="flex flex-row justify-between gap-x-4 items-center">
							<div className="font-secondary-regular mb-6 dashboard-screen-header">
								{props.clientName}
								{!!props.clientName && "'s "}
								{messages[language].header}
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.firstColumn}>
								<StatisticsCard
									className={styles.card}
									defaultSelection={defaultStatsSelection}
									chartType={props.activeClient?.defaultStat?.chartType}
									loading={props.loading}
									items={statisticItems}
									statistics={props.statistics}
									value={getStatisticsValue()}
									onChange={props.onChangeStatistics}
								/>
								{isMobile && props.activeClient?.id ? (
									<ForexBanner
										onBannerShowChange={setBannerShown}
										email={props.email}
									/>
								) : null}
								{!isMobile && bannerShown && (
									<AllowanceCard
										className={styles.card}
										allowances={[
											{
												allowanceType: "sda",
												heading: messages[language].sda,
												valueLower: props.allowance.sda.current,
												valueUpper: props.allowance.sda.limit,
											},
											{
												allowanceType: "fia",
												heading: messages[language].fia,
												valueLower: props.allowance.fia.current,
												valueUpper: props.allowance.fia.limit,
											},
										]}
										fiaDetails={props.allowance.fiaDetails}
										sdaDetails={props.allowance.sdaDetails}
									/>
								)}
								<TradeStatusCard
									className={styles.card}
									investedAmount={props.tradeStatus.investedAmount}
									netProfit={props.tradeStatus.netProfit}
									stage={props.tradeStatus.stage}
									status={props.tradeStatus.status}
								/>
							</div>
							<div className={styles.secondColumn}>
								{!isMobile && props.activeClient?.id ? (
									<ForexBanner
										onBannerShowChange={setBannerShown}
										email={props.email}
									/>
								) : null}

								<div className={styles.innerRow}>
									<div
										className={styles.innerRow}
										style={{
											flexDirection: bannerShown ? undefined : "column",
										}}
									>
										<MinimumReturnCard
											className={styles.card}
											investedAmount={props.tradeStatus.investedAmount}
											percent={formatNumber(props.minimumReturn, {
												decimalPlaces: 2,
											})}
											onChange={props.onMinimumReturnChange}
										/>
										<BalanceCard
											className={styles.card}
											balance={props.balance}
											balanceLastUpdated={props.balanceLastUpdated}
											balanceLastUpdatedWarning={
												props.balanceLastUpdatedWarning
											}
											onDeposit={props.onToggleDepositModal}
											onWithdrawOption={props.onToggleAccountSelectionModal}
										/>
									</div>

									{(isMobile || !bannerShown) && (
										<AllowanceCard
											className={styles.card}
											allowances={[
												{
													allowanceType: "sda",
													heading: messages[language].sda,
													valueLower: props.allowance.sda.current,
													valueUpper: props.allowance.sda.limit,
												},
												{
													allowanceType: "fia",
													heading: messages[language].fia,
													valueLower: props.allowance.fia.current,
													valueUpper: props.allowance.fia.limit,
												},
											]}
											fiaDetails={props.allowance.fiaDetails}
											sdaDetails={props.allowance.sdaDetails}
										/>
									)}
								</div>

								<SummaryOfTradesCard
									className={clsx(styles.card, styles.tradeHistory)}
									data={summaryOfTrades}
								/>
							</div>
						</div>
						<SpreadGraphCard
							className={styles.card}
							onChangePeriod={props.onChangePeriod}
							loading={props.loading}
						/>
					</div>

					{props.showDepositModal && (
						<DepositFundsModal
							open
							accountHolder={props.depositFundsDetails.accountHolder}
							accountNumber={props.depositFundsDetails.accountNumber}
							accountType={props.depositFundsDetails.accountType}
							bankName={props.depositFundsDetails.bankName}
							branchCode={props.depositFundsDetails.branchCode}
							rmName={props.depositFundsDetails.rmName}
							rmEmail={props.depositFundsDetails.rmEmail}
							clientName={props.clientName}
							onClose={props.onToggleDepositModal}
						/>
					)}

					{props.showAccountSelectionModal && (
						<AccountSelectionModal
							open
							clientId={props.activeClient?.id}
							data={[]}
							disabled={props.loading}
							mappedReasons={props.mappedReasons}
							onClose={props.onToggleAccountSelectionModal}
							onSubmitWithdrawal={props.onSubmitWithdrawal}
							onToggleWithdrawSuccessModal={props.onToggleWithdrawSuccessModal}
							showWithdrawSuccessModal={props.showWithdrawSuccessModal}
							straightThroughProcess={props.straightThroughProcess}
						/>
					)}
				</div>
				<Footer />
			</div>
			{props.showErrors && (
				<StatusModal
					open
					message={props.errors}
					status="ERROR"
					showCloseButton
					onClose={props.onClearErrors}
				/>
			)}
			{props.loading && <CustomLoader page />}
		</>
	);
});
