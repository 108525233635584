import type {
	Client,
	ClientResponse,
	ClientSummariesResponse,
	CycleListResponse,
	StatementResponse,
	StatisticsResponse,
} from "@app/entities";
import { type MappedReasons, apiClients } from "@app/services";
import { parseError } from "@app/utils/parse-error";
import type { AppThunk } from "..";
import {
	setActiveClient,
	setActiveClientError,
	setActiveClientLoading,
	setClientSummaries,
	setClientSummariesError,
	setClientSummariesLoading,
	setCreateClientError,
	setCreateClientLoading,
	setCycles,
	setCyclesError,
	setCyclesLoading,
	setSaveKrakenDetailsError,
	setSaveKrakenDetailsLoading,
	setStatementError,
	setStatementLoading,
	setStatistics,
	setStatisticsError,
	setStatisticsLoading,
} from "./clients-slice";

export const getClientSummariesAction =
	(
		pageNumber?: number,
		searchQuery?: string,
		callback?: (response?: ClientSummariesResponse | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: ClientSummariesResponse | string[] | undefined = undefined;

		try {
			dispatch(setClientSummariesLoading(true));
			dispatch(setClientSummariesError(null));

			const response = await apiClients.getClientSummaries(
				undefined,
				pageNumber,
				searchQuery,
			);

			if ("reasons" in response) {
				dispatch(setClientSummariesError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setClientSummaries(response));
				results = response;
			}
		} catch (error: any) {
			const reasons = [parseError(error)];
			results = reasons;
			dispatch(setClientSummariesError(reasons));
		} finally {
			if (callback) callback(results);
			dispatch(setClientSummariesLoading(false));
		}
	};

export const getClientAction =
	(
		clientId: number,
		callback?: (response?: ClientResponse | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		let results: ClientResponse | string[] | undefined = undefined;

		try {
			dispatch(setActiveClientLoading(true));
			dispatch(setActiveClientError(null));

			const response = await apiClients.getClient(clientId);

			if ("reasons" in response) {
				dispatch(setActiveClientError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setActiveClient(response));
				results = response;
			}
		} catch (error: any) {
			const reasons = [parseError(error)];
			results = reasons;
			dispatch(setActiveClientError(reasons));
		} finally {
			if (callback) callback(results);
			dispatch(setActiveClientLoading(false));
		}
	};

export const getClientCyclesAction =
	(
		clientId: number,
		startDate?: Date,
		endDate?: Date,
		pageSize?: number,
		pageNumber?: number,
		download?: string,
		callback?: (
			response?: CycleListResponse | StatementResponse | string[],
		) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: CycleListResponse | StatementResponse | string[] | undefined =
			undefined;

		try {
			dispatch(setCyclesLoading(true));
			dispatch(setCyclesError(null));

			var response = await apiClients.getCycleList(
				clientId,
				startDate,
				endDate,
				pageSize,
				pageNumber,
				download,
			);

			if ("reasons" in response) {
				dispatch(setCyclesError(response.reasons));
				results = response.reasons;
			} else {
				if ("cycles" in response) {
					dispatch(setCycles(response));
				}
				results = response;
			}
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setCyclesError(reasons));
		} finally {
			callback && callback(results);

			dispatch(setCyclesLoading(false));
		}
	};

export const getStatementAction =
	(
		statementId: number,
		callback?: (response?: StatementResponse | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: StatementResponse | string[] | undefined = undefined;

		try {
			dispatch(setStatementLoading(true));
			dispatch(setStatementError(null));

			var response = await apiClients.getStatement(statementId);

			if ("reasons" in response) {
				dispatch(setStatementError(response.reasons));
				results = response.reasons;
			} else results = response;
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setStatementError(reasons));
		} finally {
			callback && callback(results);

			dispatch(setStatementLoading(false));
		}
	};

export const createClientAction =
	(
		client: Client,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: string[] | undefined;
		var mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setCreateClientLoading(true));
			dispatch(setCreateClientError(null));

			var response = await apiClients.createClient(client);

			if (response) {
				dispatch(setCreateClientError(response.reasons));
				results = response.reasons;
				mappedReasons = response.mappedReasons;
			}
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setCreateClientError(reasons));
		} finally {
			callback && callback(results, mappedReasons);

			dispatch(setCreateClientLoading(false));
		}
	};

export const saveKrakenDetailsAction =
	(
		clientId: number,
		krakenId: string,
		krakenApiKey: string,
		krakenApiSecret: string,
		callback?: (
			success: boolean,
			errors?: string[],
			mappedReasons?: MappedReasons,
		) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: string[] | undefined;
		var mappedReasons: MappedReasons | undefined;
		var success = false;

		try {
			dispatch(setSaveKrakenDetailsLoading(true));
			dispatch(setSaveKrakenDetailsError(null));

			var response = await apiClients.saveKrakenDetails(
				clientId,
				krakenId,
				krakenApiKey,
				krakenApiSecret,
			);

			if ("reasons" in response) {
				dispatch(setSaveKrakenDetailsError(response.reasons));
				results = response.reasons;
				mappedReasons = response.mappedReasons;
			} else {
				results = response;
				success = true;
			}
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setSaveKrakenDetailsError(reasons));
		} finally {
			callback && callback(success, results, mappedReasons);

			dispatch(setSaveKrakenDetailsLoading(false));
		}
	};

export const getStatisticsAction =
	(
		clientId: number,
		slug: string,
		callback?: (response?: StatisticsResponse | string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: StatisticsResponse | string[] | undefined = undefined;

		try {
			dispatch(setStatisticsLoading(true));
			dispatch(setStatisticsError(null));

			var response = await apiClients.getStatistics(clientId, slug);

			if ("reasons" in response) {
				dispatch(setStatisticsError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setStatistics(response));
				results = response;
			}
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setStatisticsError(reasons));
		} finally {
			callback && callback(results);

			dispatch(setStatisticsLoading(false));
		}
	};
