import { useGetProfile } from "@app/helpers";
import type { RootState } from "@app/redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AccountSettingsView } from "./account-settings-view";

interface AccountSettingsState {
	showReduxErrors?: boolean;
	openEmailModal: boolean;
	openPasswordModal: boolean;
}

const AccountSettings = () => {
	const [getProfile] = useGetProfile();

	const navigate = useNavigate();

	const { profile, updateUserUsernameLoading, updateUserPasswordLoading } =
		useSelector((rootState: RootState) => rootState.auth);

	const defaultState: AccountSettingsState = {
		openEmailModal: false,
		openPasswordModal: false,
	};

	const [state, setState] = useState<AccountSettingsState>(defaultState);

	const onChangeEmail = () => {
		setState({ ...state, openEmailModal: true, showReduxErrors: false });
	};

	const onChangePassword = () => {
		setState({ ...state, openPasswordModal: true, showReduxErrors: false });
	};

	const onClose = () => {
		setState({ ...state, openEmailModal: false, openPasswordModal: false });
	};

	const onNavigateBack = () => {
		navigate(-1);
	};

	useEffect(() => {
		if (!state.openEmailModal || !state.openPasswordModal) {
			getProfile();
		}
	}, [state.openEmailModal, state.openPasswordModal]);

	return (
		<AccountSettingsView
			email={profile?.email}
			loading={updateUserUsernameLoading || updateUserPasswordLoading}
			openEmailModal={state.openEmailModal}
			openPasswordModal={state.openPasswordModal}
			password={"********"}
			onChangeEmail={onChangeEmail}
			onChangePassword={onChangePassword}
			onClose={onClose}
			onNavigateBack={onNavigateBack}
		/>
	);
};

export default AccountSettings;
