import { paths } from "@app/constants/paths";
import type { ClientSummariesResponse } from "@app/entities";
import { useGetClient, useGetClientSummaries } from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { RootState } from "@app/redux";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClientsView } from "./clients-view";

const STORAGE_KEY_PAGE = "clients-page";
const DEFAULT_LIMIT = 25;

const Clients = () => {
	const [pageSize, setPageSize] = useState(DEFAULT_LIMIT);
	const isMobile = useMediaQuery();
	const navigate = useNavigate();
	const [getClientSummaries] = useGetClientSummaries();
	const [getClient] = useGetClient();

	const {
		clients,
		clientsError,
		clientsLoading,
		clientsTotalPages,
		clientsTotalCount,
	} = useSelector((state: RootState) => state.clients);

	const [showReduxErrors, setShowReduxErrors] = useState(false);
	const [showNewClientModal, setShowNewClientModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(
		window.sessionStorage.getItem(STORAGE_KEY_PAGE)
			? Number(window.sessionStorage.getItem(STORAGE_KEY_PAGE))
			: 1,
	);
	const [search, setSearchText] = useState<string | undefined>(undefined);

	const getReduxErrors = () => clientsError?.join(" ");
	const onClearErrors = () => setShowReduxErrors(false);
	const onToggleNewClientModal = () =>
		setShowNewClientModal(!showNewClientModal);
	const handleSearch = (value: string) => {
		setSearchText(value);
		if (value !== undefined) {
			onNavigatePage(0, value);
		}
	};

	const onNavigatePage = useCallback(
		(page: number, searchOverride?: string) => {
			if (page !== currentPage || page === 0) {
				getClientSummaries(
					page,
					searchOverride ?? search,
					(response?: ClientSummariesResponse | string[] | undefined) => {
						if (!response || Array.isArray(response)) {
							setShowReduxErrors(true);
						}
					},
				);
				setCurrentPage(page);
				window.sessionStorage.setItem(STORAGE_KEY_PAGE, page.toString());
			}
		},
		[currentPage, getClientSummaries, search],
	);

	const onViewClient = (identifier: number) => {
		getClient(identifier, (response) => {
			if (response && !Array.isArray(response)) {
				navigate(paths.dashboard);
			} else {
				setShowReduxErrors(true);
			}
		});
	};

	useEffect(() => {
		getClientSummaries(currentPage, search);
	}, []);

	const viewProps = {
		clients: clients,
		loading: clientsLoading,
		page: currentPage,
		pageCount: clientsTotalPages,
		totalCount: clientsTotalCount,
		reduxErrors: getReduxErrors(),
		showNewClientModal,
		showReduxErrors,
		pageSize,
		onPageSizeChange: setPageSize,
		onClearErrors: onClearErrors,
		onToggleNewClientModal: onToggleNewClientModal,
		onNavigatePage,
		onSearch: handleSearch,
		onViewClient,
	};

	return <ClientsView {...viewProps} isDesktop={!isMobile} />;
};

export default Clients;
