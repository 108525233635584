import { useClearUserData } from "@app/helpers";
import React from "react";
import { LogoutModalView } from "./logout-modal-view";
import type { Properties } from "./properties";

const LogoutModal = (props: Properties) => {
	// #region CONST
	const [clearUserData] = useClearUserData();

	const onClose = () => {
		clearUserData();
		if (props.onClose) {
			props.onClose();
		}
	};

	const onUnload = () => {
		if (props.open) clearUserData();
	};

	React.useEffect(() => {
		window.addEventListener("beforeunload", onUnload, true);

		return () => window.removeEventListener("beforeunload", onUnload);
	}, [props.open]);

	return <LogoutModalView open={props.open} onClose={onClose} />;
};

export { LogoutModal };
