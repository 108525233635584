import type { TradeStage } from "@app/constants/trade-stage";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { TradeStatusCardView } from "./trade-status-card-view";

export const TradeStatusCard = (props: {
	className?: string;
	investedAmount: number;
	isDesktop?: boolean;
	netProfit: number;
	stage: TradeStage;
	status: string;
}) => {
	const isMobile = useMediaQuery();
	return <TradeStatusCardView {...props} isDesktop={!isMobile} />;
};
