import { Button } from "@app/controls/button";
import { Input } from "@app/controls/input";
import React from "react";
import "./email-change-card.css";

export const EmailChangeCard = React.memo(
	(props: {
		value?: string;
		onChangeEmail?: () => void;
	}) => {
		return (
			<div className="flex flex-col gap-y-4 grow pb-8 pt-7 px-5 lg:px-7 email-change">
				<div className="font-primary-bold email-change-heading">Email</div>
				<Input
					className="mt-2 email-change-input"
					disabled
					theme="secondary"
					title="Current email address"
					value={props.value}
				/>
				<div>
					<Button
						className="capitalize -mt-3 p-0 email-change-action"
						onClick={props.onChangeEmail}
					>
						Change
					</Button>
				</div>
			</div>
		);
	},
);
