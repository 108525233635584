import type {
	Client,
	ClientResponse,
	CycleListResponse,
	GetClientSummariesHelperResponse,
	StatementResponse,
	StatisticsResponse,
} from "@app/entities";
import type { AppDispatch, RootState } from "@app/redux";
import {
	createClientAction,
	getClientAction,
	getClientCyclesAction,
	getClientSummariesAction,
	getStatementAction,
	getStatisticsAction,
	saveKrakenDetailsAction,
} from "@app/redux/clients/clients-actions";
import type { MappedReasons } from "@app/services";
import { useDispatch, useSelector } from "react-redux";

export const useGetClientSummaries = () => {
	const dispatch: AppDispatch = useDispatch();

	const getClientSummaries = (
		pageNumber?: number,
		searchQuery?: string,
		callback?: (response?: GetClientSummariesHelperResponse) => void,
	) => {
		var _resolve = (
				response?:
					| GetClientSummariesHelperResponse
					| PromiseLike<GetClientSummariesHelperResponse>,
			) => {},
			_reject = (reason?: any) => {};

		var promise = new Promise<GetClientSummariesHelperResponse | undefined>(
			(resolve, reject) => {
				_resolve = resolve;
				_reject = reject;
			},
		);

		const _callback = (response?: GetClientSummariesHelperResponse) => {
			if (callback) callback(response);
			_resolve(response);
		};

		try {
			dispatch(getClientSummariesAction(pageNumber, searchQuery, _callback));
		} catch (error: any) {
			_reject(error);
		} finally {
			return promise;
		}
	};

	return [getClientSummaries];
};

export const useGetClient = () => {
	const dispatch: AppDispatch = useDispatch();

	const getClient = (
		clientId: number,
		callback?: (response?: ClientResponse | string[]) => void,
	) => {
		dispatch(getClientAction(clientId, callback));
	};

	return [getClient];
};

export const useGetClientCycles = () => {
	const dispatch: AppDispatch = useDispatch();
	var { activeClient } = useSelector((state: RootState) => state.clients);

	const getClientCycles = (
		startDate?: Date,
		endDate?: Date,
		pageSize?: number,
		pageNumber?: number,
		download?: string,
		callback?: (
			response?: string[] | CycleListResponse | StatementResponse,
		) => void,
	) => {
		if (activeClient)
			dispatch(
				getClientCyclesAction(
					activeClient.id,
					startDate,
					endDate,
					pageSize,
					pageNumber,
					download,
					callback,
				),
			);
		else {
			var messages = ["No active client selected"];

			callback && callback(messages);
		}
	};

	return [getClientCycles];
};

export const useGetCycleStatement = () => {
	const dispatch: AppDispatch = useDispatch();

	const getCycleStatement = (
		statementId: number,
		callback: (response?: string[] | StatementResponse) => void,
	) => {
		dispatch(getStatementAction(statementId, callback));
	};

	return [getCycleStatement];
};

export const useCreateClient = () => {
	const dispatch: AppDispatch = useDispatch();

	const createClient = (
		client: Client,
		callback: (response?: string[], mappedReasons?: MappedReasons) => void,
	) => {
		dispatch(createClientAction(client, callback));
	};

	return [createClient];
};

export const useSaveKrakenDetails = () => {
	const dispatch: AppDispatch = useDispatch();
	var { activeClient } = useSelector((state: RootState) => state.clients);

	const saveKrakenDetails = (
		krakenId: string,
		krakenApiKey: string,
		krakenApiSecret: string,
		callback: (
			success: boolean,
			response?: string[],
			mappedReasons?: MappedReasons,
		) => void,
	) => {
		if (activeClient)
			dispatch(
				saveKrakenDetailsAction(
					activeClient.id,
					krakenId,
					krakenApiKey,
					krakenApiSecret,
					callback,
				),
			);
		else {
			var messages = ["No active client selected"];

			callback && callback(false, messages);
		}
	};

	return [saveKrakenDetails];
};

export const useGetStatistics = () => {
	const dispatch: AppDispatch = useDispatch();

	const getStatistics = (
		clientId: number,
		slug: string,
		callback?: (response?: StatisticsResponse | string[] | undefined) => void,
	) => {
		dispatch(getStatisticsAction(clientId, slug, callback));
	};

	return [getStatistics];
};
