const messages = {
	en: {
		action: "Please log in.",
		header: "Account already exists",
		loginButton: "LOGIN",
		warning: "An account with matching credentials already exists.",
	},
};

export default messages;
