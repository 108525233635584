import { Modal } from "@app/components/modals/modal";
import { Button } from "@app/controls/button";
import React from "react";
import "./session-expired-modal.css";

export const SessionExpiredModal = React.memo(
	(props: {
		open: boolean;
		onClose: () => void;
	}) => {
		return (
			<Modal
				className="session-expired-modal"
				ignoreTokenExpiry
				open={props.open}
				noCloseButton
				overlayColor="light"
			>
				{
					<>
						<h1 className="font-bold text-2xl mb-8">Session Expired</h1>
						<p>Your session has expired.</p>
						<Button
							className="mt-9.5 login mx-auto"
							type="button"
							theme="primary"
							onClick={props.onClose}
						>
							CLOSE
						</Button>
					</>
				}
			</Modal>
		);
	},
);
