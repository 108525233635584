import { Status } from "@app/components/status";
import type { TradeStage } from "@app/constants/trade-stage";
import type { RootState } from "@app/redux";
import {
	type FormatNumberOptions,
	formatNumber,
} from "@app/utils/format-number";
import React from "react";
import { useSelector } from "react-redux";
import "./trade-status-card.css";

const TradeStatusCardView = React.memo(
	(props: {
		className?: string;
		investedAmount: number;
		isDesktop?: boolean;
		netProfit: number;
		stage: TradeStage;
		status: string;
	}) => {
		const { language } = useSelector(
			(rootState: RootState) => rootState.language,
		);

		const getFiguresContent = () => {
			const currencyOptions: FormatNumberOptions = {
				currencyCode: "R ",
				decimalPlaces: props.isDesktop ? 2 : 0,
				groupSeparator: ",",
			};

			return (
				<>
					<div className="flex flex-row justify-between">
						<div className={figureStyle}>Invested Amount:</div>
						<div className={figureStyle}>
							{props.investedAmount > 0
								? formatNumber(props.investedAmount, currencyOptions)
								: "-"}
						</div>
					</div>
					<div className="flex flex-row justify-between">
						<div className={figureStyle}>Net Profit:</div>
						<div className={figureStyle}>
							{props.netProfit > 0
								? formatNumber(props.netProfit, currencyOptions)
								: "-"}
						</div>
					</div>
				</>
			);
		};

		const figureStyle = [
			"font-primary-medium",
			"lg:mb-2",
			"text-sm",
			"trade-status-text",
		].join(" ");

		const mainStyle = [
			"px-5",
			"lg:px-7",
			"pb-4",
			"lg:py-8",
			"trade-status",
			props.className,
		].join(" ");

		return (
			<div className={mainStyle}>
				<div className="font-primary-bold trade-status-text trade-status-heading leading-none">
					{props.isDesktop ? "Trade Status" : "Trade"}
				</div>
				<div className="font-primary-light flex items-center justify-center text-sm lg:text-lg trade-status-text trade-status-subheading">
					{props.status}
				</div>
				<div className="flex flex-row justify-between">
					<Status
						className="h-10 trade-status-block"
						type="bar"
						stage={props.stage}
						totalStages={3}
					/>
				</div>
				<div className="flex flex-col mt-7 trade-status-text">
					{getFiguresContent()}
				</div>
			</div>
		);
	},
);

export { TradeStatusCardView };
