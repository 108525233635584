import type { NotificationResponse } from "@app/entities";
import type { AppDispatch } from "@app/redux";
import { getNotificationsAction } from "@app/redux/notifications/notifications-actions";
import { useDispatch } from "react-redux";

export const useGetNotifications = () => {
	const dispatch: AppDispatch = useDispatch();

	const getNotifications = (
		callback?: (response?: string[] | NotificationResponse[]) => void,
	) => {
		dispatch(getNotificationsAction(callback));
	};

	return [getNotifications];
};
