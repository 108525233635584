import type { ReferrerResponse } from "@app/entities";
import { apiLookup } from "@app/services";
import { parseError } from "@app/utils/parse-error";
import { isArray } from "lodash";
import type { AppThunk } from "..";
import {
	setReferrers,
	setReferrersError,
	setReferrersLoading,
} from "./lookup-slice";

export const getReferrersAction =
	(callback?: (response?: ReferrerResponse[] | string[]) => void): AppThunk =>
	async (dispatch) => {
		var results: ReferrerResponse[] | string[] | undefined = undefined;

		try {
			dispatch(setReferrersLoading(true));
			dispatch(setReferrersError(null));

			var response = await apiLookup.getReferrers();

			if (isArray(response)) {
				dispatch(setReferrers(response));
				results = response;
			} else if (response.reasons) {
				dispatch(setReferrersError(response.reasons));
				results = response.reasons;
			}
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setReferrersError(reasons));
		} finally {
			callback && callback(results);

			dispatch(setReferrersLoading(false));
		}
	};
