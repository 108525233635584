import type { ButtonTheme } from "@app/constants/button-theme";
import type React from "react";

import "./button.css";

type Type = "button" | "submit" | "reset";

export const Button = (props: {
	children?: React.ReactNode;
	className?: string;
	disabled?: boolean;
	theme?: ButtonTheme;
	type?: Type;
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
	const getClassNames = () => {
		const classesToUse = [
			"button flex flex-auto items-center justify-center border-none p-1.5",
		];

		switch (props.theme) {
			case "primary":
				classesToUse.push("bg-transparent button-primary");
				break;
			case "secondary":
				classesToUse.push("bg-transparent button-secondary");
				break;
			case "tertiary":
				classesToUse.push("button-tertiary");
				break;
		}

		if (props.className) classesToUse.push(props.className);

		return classesToUse.join(" ");
	};

	return (
		<button
			type={props.type}
			className={getClassNames()}
			onClick={props.onClick}
			disabled={props.disabled}
		>
			{props.children}
		</button>
	);
};
