import type { DropdownItem } from "@app/entities";
import React from "react";
import { ComboButtonView } from "./combo-button-view";
import type { ComboButtonState } from "./models/combo-button-state";
import type { Properties } from "./properties";

const ComboButton = (props: Properties) => {
	const defaultState = React.useMemo((): ComboButtonState => {
		return {
			fixedItem: props.items.find((value) => value.value === props.fixedValue),
			selectedItem:
				props.items.length > 0 ? props.items[0] : ({} as DropdownItem<string>),
		};
	}, []);

	const [state, setState] = React.useState<ComboButtonState>(defaultState);

	const findItem = (value?: string) => {
		return props.items.find((item) => item.value === value);
	};

	const getSelectedItem = (value: string) => {
		const item = props.items.find((x) => x.value === value);
		if (item) {
			return item;
		} else {
			return props.items.length > 0
				? props.items[0]
				: ({} as DropdownItem<string>);
		}
	};
	// #endregion

	const onClick = (value?: string) => {
		if (props.onClick) {
			props.onClick(
				state.fixedItem && value ? value : state.selectedItem?.value,
			);
		}
	};

	const onSelect = (value: string) => {
		if (!state.fixedItem) {
			setState({ ...state, selectedItem: getSelectedItem(value) });
		}
	};
	// #endregion

	React.useEffect(() => {
		setState({ ...state, fixedItem: findItem(props.fixedValue) });
	}, [props.fixedValue]);

	const viewItems = React.useMemo(() => {
		if (state.fixedItem && props.fixedValueHiddenInDropdown) {
			return props.items.filter(
				(item) => item.value !== state.fixedItem?.value,
			);
		} else {
			return [];
		}
	}, [state.fixedItem, props.fixedValueHiddenInDropdown]);
	// #endregion

	return (
		<ComboButtonView
			{...props}
			fixedItem={state.fixedItem}
			items={viewItems.length > 0 ? viewItems : props.items}
			selectedItem={state.selectedItem}
			onSelect={onSelect}
			onClick={onClick}
		/>
	);
};

export { ComboButton };
