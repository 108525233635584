import type {
	CreateWithdrawalAccount,
	UpdateWithdrawalAccount,
	WithdrawalAccount,
} from "@app/entities";
import { type MappedReasons, apiWithdrawal } from "@app/services";
import { parseError } from "@app/utils/parse-error";
import type { AppThunk } from "..";
import {
	setCreateWithdrawalBankError,
	setCreateWithdrawalBankLoading,
	setDestroyWithdrawalBankError,
	setDestroyWithdrawalBankLoading,
	setRequestWithdrawalError,
	setRequestWithdrawalLoading,
	setRetrieveWithdrawalBankError,
	setRetrieveWithdrawalBankListError,
	setRetrieveWithdrawalBankListLoading,
	setRetrieveWithdrawalBankLoading,
	setUpdateWithdrawalBankError,
	setUpdateWithdrawalBankLoading,
	setWithdrawalBank,
	setWithdrawalBanks,
} from "./withdrawal-slice";

export const createWithdrawalBankAction =
	(
		account: CreateWithdrawalAccount,
		callback?: (
			success: boolean,
			response?: string[],
			mappedReasons?: MappedReasons,
		) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: string[] | undefined = undefined;
		var mappedReasons: MappedReasons | undefined;
		var success = false;

		try {
			dispatch(setCreateWithdrawalBankLoading(true));
			dispatch(setCreateWithdrawalBankError(null));

			var response = await apiWithdrawal.createWithdrawalBank(account);

			if ("reasons" in response) {
				dispatch(setCreateWithdrawalBankError(response.reasons));
				results = response.reasons;
				mappedReasons = response.mappedReasons;
			} else success = !!response;
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;
			dispatch(setCreateWithdrawalBankError(reasons));
		} finally {
			callback && callback(success, results, mappedReasons);

			dispatch(setCreateWithdrawalBankLoading(false));
		}
	};

export const destroyWithdrawalBankAction =
	(
		bankId: number,
		callback?: (success: boolean, response?: string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: string[] | string | undefined = undefined;
		var success = false;

		try {
			dispatch(setDestroyWithdrawalBankLoading(true));
			dispatch(setDestroyWithdrawalBankError(null));

			var response = await apiWithdrawal.destroyWithdrawalBank(bankId);

			if (response !== undefined) {
				dispatch(setDestroyWithdrawalBankError(response.reasons));
				results = response.reasons;
			} else success = !!response;
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setDestroyWithdrawalBankError(reasons));
		} finally {
			callback && callback(success, results as string[] | undefined);

			dispatch(setDestroyWithdrawalBankLoading(false));
		}
	};

export const retrieveWithdrawalBankListAction =
	(
		clientId: number,
		callback?: (
			success: boolean,
			response?: string[] | WithdrawalAccount[],
		) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: string[] | string | WithdrawalAccount[] | undefined =
			undefined;
		var success = false;

		try {
			dispatch(setRetrieveWithdrawalBankListLoading(true));
			dispatch(setRetrieveWithdrawalBankListError(null));

			var response = await apiWithdrawal.retrieveWithdrawalBankList(clientId);

			if ("reasons" in response) {
				dispatch(setRetrieveWithdrawalBankListError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setWithdrawalBanks(response));
				results = response;
				success = !!response;
			}
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setRetrieveWithdrawalBankListError(reasons));
		} finally {
			callback &&
				callback(
					success,
					results as string[] | WithdrawalAccount[] | undefined,
				);

			dispatch(setRetrieveWithdrawalBankListLoading(false));
		}
	};

export const retrieveWithdrawalBankAction =
	(
		bankId: number,
		callback?: (
			success: boolean,
			response?: string[] | WithdrawalAccount,
		) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: string[] | string | WithdrawalAccount | undefined = undefined;
		var success = false;

		try {
			dispatch(setRetrieveWithdrawalBankLoading(true));
			dispatch(setRetrieveWithdrawalBankError(null));

			var response = await apiWithdrawal.retrieveWithdrawalBank(bankId);

			if ("reasons" in response) {
				dispatch(setRetrieveWithdrawalBankError(response.reasons));
				results = response.reasons;
			} else {
				dispatch(setWithdrawalBank(response));
				results = response;
				success = !!response;
			}
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setRetrieveWithdrawalBankError(reasons));
		} finally {
			callback &&
				callback(success, results as string[] | WithdrawalAccount | undefined);

			dispatch(setRetrieveWithdrawalBankLoading(false));
		}
	};

export const requestWithdrawalAction =
	(
		clientId: number,
		amount: string | null,
		withdrawAll: boolean,
		bankId: number,
		straightThroughProcess: boolean,
		callback?: (
			success: boolean,
			response?: string[],
			mappedReasons?: MappedReasons,
		) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: string[] | string | undefined = undefined;
		var mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setRequestWithdrawalLoading(true));
			dispatch(setRequestWithdrawalError(null));

			var response = await apiWithdrawal.requestWithdrawal(
				clientId,
				amount,
				withdrawAll,
				bankId,
				straightThroughProcess,
			);

			if (typeof response !== "undefined") {
				dispatch(setRequestWithdrawalError(response.reasons));
				results = response.reasons;
				mappedReasons = response.mappedReasons;
			} else results = response;
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setRequestWithdrawalError(reasons));
		} finally {
			callback &&
				callback(
					typeof results === "undefined",
					results as string[] | undefined,
					mappedReasons,
				);

			dispatch(setRequestWithdrawalLoading(false));
		}
	};

export const updateWithdrawalBankAction =
	(
		bankId: number,
		account: UpdateWithdrawalAccount,
		callback?: (
			success: boolean,
			response?: string[],
			mappedReasons?: MappedReasons,
		) => void,
	): AppThunk =>
	async (dispatch) => {
		var results: string[] | string | undefined = undefined;
		var mappedReasons: MappedReasons | undefined;
		var success = false;

		try {
			dispatch(setUpdateWithdrawalBankLoading(true));
			dispatch(setUpdateWithdrawalBankError(null));

			var response = await apiWithdrawal.updateWithdrawalBank(bankId, account);

			if ("reasons" in response) {
				dispatch(setUpdateWithdrawalBankError(response.reasons));
				results = response.reasons;
				mappedReasons = response.mappedReasons;
			} else success = !!response;
		} catch (error: any) {
			var reasons = [parseError(error)];

			results = reasons;

			dispatch(setUpdateWithdrawalBankError(reasons));
		} finally {
			callback && callback(success, results, mappedReasons);

			dispatch(setUpdateWithdrawalBankLoading(false));
		}
	};
