import { Button } from "@app/controls/button";
import { Input } from "@app/controls/input";
import type { RootState } from "@app/redux";
import React from "react";
import { useSelector } from "react-redux";
import "./password-change-card.css";

const messages = {
	en: {
		change: "Change",
		heading: "Password",
		tooltip: {
			password: "Current password (does not contain actual password)",
		},
	},
};

const PasswordChangeCardView = React.memo(
	(props: {
		value?: string;
		onChangePassword?: () => void;
	}) => {
		const { language } = useSelector(
			(rootState: RootState) => rootState.language,
		);

		const buttonStyle = [
			"capitalize",
			"-mt-3",
			"p-0",
			"password-change-action",
		].join(" ");

		const headingStyle = ["font-primary-bold", "password-change-heading"].join(
			" ",
		);

		const inputStyle = ["mt-2", "password-change-input"].join(" ");

		const mainStyle = [
			"flex",
			"flex-col",
			"gap-y-4",
			"grow",
			"pb-8",
			"pt-7",
			"px-5",
			"lg:px-7",
			"password-change",
		].join(" ");

		return (
			<div className={mainStyle}>
				<div className={headingStyle}>{messages[language].heading}</div>
				<Input
					className={inputStyle}
					disabled
					hideIcon
					theme="secondary"
					title={messages[language].tooltip.password}
					type="password"
					value={props.value}
				/>
				<div>
					<Button className={buttonStyle} onClick={props.onChangePassword}>
						{messages[language].change}
					</Button>
				</div>
			</div>
		);
	},
);

export { PasswordChangeCardView };
