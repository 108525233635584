import { apiErrors } from "@app/constants/api-errors";
import { useLogout } from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { RootState } from "@app/redux";
import { useState } from "react";
import { useSelector } from "react-redux";
import type { SidebarState } from "./models/sidebar-state";
import type { Properties } from "./properties";
import { SidebarView } from "./sidebar-view";

export const Sidebar = (props: Properties) => {
	const isMobile = useMediaQuery();
	const { activeClient, clients } = useSelector(
		(state: RootState) => state.clients,
	);

	const { profile } = useSelector((state: RootState) => state.auth);

	const [logout] = useLogout();

	const [state, setState] = useState<SidebarState>({
		showLoadingModal: false,
		showLogoutModal: false,
		showNewClientModal: false,
	});

	const getProperties = () => {
		return {
			...props,
			open: props.open || false,
			userEmail: profile?.email || "...",
			clientName: activeClient
				? `${activeClient.firstName} ${activeClient.lastName}`
				: "...",
			manager: activeClient?.relationshipManager
				? {
						firstName: activeClient.relationshipManager.firstName,
						lastName: activeClient.relationshipManager.lastName,
						email: activeClient.relationshipManager.email,
						contactNumber: activeClient.relationshipManager.contactNumber,
						picture: activeClient.relationshipManager.picture,
					}
				: {
						firstName: "...",
						lastName: "...",
						email: "...",
						contactNumber: "...",
						picture: null,
					},
			showAddClient: clients.length < 2,
			showLoadingModal: state.showLoadingModal,
			showLogoutModal: state.showLogoutModal,
			showNewClientModal: state.showNewClientModal,
			onClose: onClose,
			onCloseLogoutModal: onCloseLogoutModal,
			onLogout: onLogout,
			onToggleNewClientModal: onToggleNewClientModal,
		};
	};

	const onClose = () => {
		if (props.onClose) props.onClose();
	};

	const onLogout = () => {
		logout((errors?: string[]) => {
			const showLogout = !(
				errors &&
				(errors.includes(apiErrors.tokenExpired) ||
					errors.includes(apiErrors.invalidToken))
			);
			setState({ ...state, showLogoutModal: showLogout });
		});
	};

	const onCloseLogoutModal = () => {
		setState({ ...state, showLogoutModal: false });
	};

	const onToggleNewClientModal = (toggle: boolean) => {
		setState({ ...state, showNewClientModal: toggle });
		if (toggle && props.onClose) props.onClose();
	};

	const viewProps = getProperties();

	return (
		<SidebarView
			{...viewProps}
			open={isMobile ? viewProps.open : true}
			mobile={isMobile}
		/>
	);
};
