import { formatNumber } from "@app/utils/format-number";
import { type MouseEventHandler, useRef, useState } from "react";
import { BarGraphView } from "./bar-graph-view";
import type { ViewProperties } from "./properties";

export const BarGraph = (props: any) => {
	const [mouseHoverPositionXAxisState, setMouseHoverPositionXAxis] = useState<
		number | null
	>(null);

	const popoverContainer = useRef<HTMLDivElement | null>(null);
	const headerContainer = useRef<HTMLDivElement | null>(null);

	const onBarGraphMouseMove: MouseEventHandler<HTMLDivElement> = ({
		currentTarget,
		clientX,
	}) => {
		let newMouseHoverPositionXAxisState: number | null = null;
		const xPosition = clientX - currentTarget.getBoundingClientRect().x;

		if (xPosition < 0 || xPosition > currentTarget.clientWidth)
			newMouseHoverPositionXAxisState = null;
		else
			newMouseHoverPositionXAxisState =
				(xPosition / currentTarget.clientWidth) * 100;

		if (mouseHoverPositionXAxisState !== newMouseHoverPositionXAxisState)
			setMouseHoverPositionXAxis(newMouseHoverPositionXAxisState);
	};

	const getFormattedFigures = (numbers: number[]) => {
		const formattedNumbers = numbers.map((number) =>
			formatNumber(number, {
				currencyCode: "R ",
				groupSeparator: ",",
			}),
		);
		return numbers.length === 2
			? formattedNumbers.join(" / ")
			: formattedNumbers.join("");
	};

	const getDetailWidthPercentages = (
		totalAmount: number,
		detailAmount: number,
		usedWidth: number,
	) => {
		return Math.round(((detailAmount / totalAmount) * 100 * usedWidth) / 100);
	};

	const viewProps: ViewProperties = {
		...props,

		headerContainer,
		mouseHoverPositionXAxisState,
		popoverContainer,

		getDetailWidthPercentages,
		getFormattedFigures,
		onBarGraphMouseMove,
	};
	return <BarGraphView {...viewProps} />;
};
