import type { Profile, User } from "@app/entities";
import {
	type MappedReasons,
	abortRequests,
	apiAccount,
	setToken,
} from "@app/services";
import { parseError } from "@app/utils/parse-error";
import moment from "moment";
import type { AppThunk } from "..";
import {
	setActivateUserError,
	setActivateUserLoading,
	setConfirmPasswordResetError,
	setConfirmPasswordResetLoading,
	setNewPageLoad,
	setProfile,
	setProfileError,
	setProfileLoading,
	setRegisterError,
	setRegisterLoading,
	setResendUserActivationError,
	setResendUserActivationLoading,
	setResetPasswordError,
	setResetPasswordLoading,
	setThrottlingTime,
	setTokenHasExpired,
	setUpdateUserPasswordError,
	setUpdateUserPasswordLoading,
	setUpdateUserUsernameError,
	setUpdateUserUsernameLoading,
	setUser,
	setUserError,
	setUserIsAuthenticated,
	setUserLoading,
} from "./auth-slice";

export const loginAction =
	(
		username: string,
		password: string,
		callback?: (user: User) => void,
	): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(setUserLoading(true));
			dispatch(setUserError(null));

			const response = await apiAccount.login(username, password);

			if (response.isAuthentic) {
				setToken(response.token || "");
				dispatch(setUser(response));
				dispatch(setUserIsAuthenticated(response.isAuthentic));
			} else {
				dispatch(setUserError(response.reasons || null));
			}

			if (callback) {
				callback(response);
			}
		} catch (error: any) {
			var reasons = [parseError(error)];

			dispatch(setUserError(reasons));

			callback && callback({ isAuthentic: false, reasons: reasons });
		} finally {
			dispatch(setUserLoading(false));
		}
	};

export const logoutAction =
	(callback?: (errors?: string[]) => void): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;

		try {
			dispatch(setUserLoading(true));
			dispatch(setUserError(null));

			abortRequests();

			const response = await apiAccount.logout();
			window.sessionStorage.clear();
			if (response?.reasons) {
				dispatch(setUserError(response.reasons));
				reasons = response.reasons;
			}
		} catch (error: any) {
			reasons = [parseError(error)];

			dispatch(setUserError(reasons));
		} finally {
			if (callback) callback(reasons);
			dispatch(setUserLoading(false));
		}
	};

export const registerAction =
	(
		email: string,
		password: string,
		ref?: string,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		let reasons: string[] | undefined = undefined;
		let mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setRegisterLoading(true));
			dispatch(setRegisterError(null));

			const response = await apiAccount.register(email, password, ref);

			if (response?.reasons) {
				dispatch(setRegisterError(response.reasons));
				reasons = response.reasons;
				mappedReasons = response.mappedReasons;
			}
		} catch (error: any) {
			reasons = [parseError(error)];

			dispatch(setRegisterError(reasons));
		} finally {
			if (callback) callback(reasons, mappedReasons);
			dispatch(setRegisterLoading(false));
		}
	};

export const activateUserAction =
	(
		userId: string,
		token: string,
		callback?: (errors?: string[]) => void,
	): AppThunk =>
	async (dispatch) => {
		var reasons: string[] | undefined = undefined;

		try {
			dispatch(setActivateUserLoading(true));
			dispatch(setActivateUserError(null));

			var response = await apiAccount.activateUser(userId, token);

			if (response?.reasons) {
				dispatch(setActivateUserError(response.reasons));
				reasons = response.reasons;
			}
		} catch (error: any) {
			reasons = [parseError(error)];

			dispatch(setActivateUserError(reasons));
		} finally {
			callback && callback(reasons);

			dispatch(setActivateUserLoading(false));
		}
	};

export const resendUserActivationAction =
	(email: string, callback?: (errors?: string[]) => void): AppThunk =>
	async (dispatch) => {
		var reasons: string[] | undefined = undefined;

		try {
			dispatch(setResendUserActivationLoading(true));
			dispatch(setResendUserActivationError(null));

			var response = await apiAccount.resendUserActivation(email);

			if (response?.reasons) {
				dispatch(setResendUserActivationError(response.reasons));
				reasons = response.reasons;
			}
		} catch (error: any) {
			reasons = [parseError(error)];

			dispatch(setResendUserActivationError(reasons));
		} finally {
			callback && callback(reasons);

			dispatch(setResendUserActivationLoading(false));
		}
	};

export const resetPasswordAction =
	(email: string, callback?: (errors?: string[]) => void): AppThunk =>
	async (dispatch) => {
		var reasons: string[] | undefined = undefined;

		try {
			dispatch(setResetPasswordLoading(true));
			dispatch(setResetPasswordError(null));

			var response = await apiAccount.resetPassword(email);

			if (response?.reasons) {
				dispatch(setResetPasswordError(response.reasons));
				reasons = response.reasons;
			}
		} catch (error: any) {
			reasons = [parseError(error)];

			dispatch(setResetPasswordError(reasons));
		} finally {
			callback && callback(reasons);

			dispatch(setResetPasswordLoading(false));
		}
	};

export const confirmPasswordResetAction =
	(
		userId: string,
		token: string,
		newPassword: string,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		var reasons: string[] | undefined = undefined;
		var mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setConfirmPasswordResetLoading(true));
			dispatch(setConfirmPasswordResetError(null));

			var response = await apiAccount.confirmPasswordReset(
				userId,
				token,
				newPassword,
			);

			if (response?.reasons) {
				dispatch(setConfirmPasswordResetError(response?.reasons));
				reasons = response.reasons;
				mappedReasons = response.mappedReasons;
			}
		} catch (error: any) {
			reasons = [parseError(error)];

			dispatch(setConfirmPasswordResetError(reasons));
		} finally {
			callback && callback(reasons, mappedReasons);

			dispatch(setConfirmPasswordResetLoading(false));
		}
	};

export const updateUserPasswordAction =
	(
		currentPassword: string,
		newPassword: string,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		var reasons: string[] | undefined = undefined;
		var mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setUpdateUserPasswordLoading(true));
			dispatch(setUpdateUserPasswordError(null));

			var response = await apiAccount.updateUserPassword(
				currentPassword,
				newPassword,
			);

			if (response?.reasons) {
				dispatch(setUpdateUserPasswordError(response.reasons));
				reasons = response.reasons;
				mappedReasons = response.mappedReasons;
			}
		} catch (error: any) {
			reasons = [parseError(error)];

			dispatch(setUpdateUserPasswordError(reasons));
		} finally {
			callback && callback(reasons, mappedReasons);

			dispatch(setUpdateUserPasswordLoading(false));
		}
	};

export const updateUserUsernameAction =
	(
		currentPassword: string,
		newUsername: string,
		callback?: (errors?: string[], mappedReasons?: MappedReasons) => void,
	): AppThunk =>
	async (dispatch) => {
		var reasons: string[] | undefined = undefined;
		var mappedReasons: MappedReasons | undefined;

		try {
			dispatch(setUpdateUserUsernameLoading(true));
			dispatch(setUpdateUserUsernameError(null));

			var response = await apiAccount.updateUserUsername(
				newUsername,
				currentPassword,
			);

			if (response?.reasons) {
				dispatch(setUpdateUserUsernameError(response.reasons));
				reasons = response.reasons;
				mappedReasons = response.mappedReasons;
			}
		} catch (error: any) {
			reasons = [parseError(error)];

			dispatch(setUpdateUserUsernameError(reasons));
		} finally {
			callback && callback(reasons, mappedReasons);

			dispatch(setUpdateUserUsernameLoading(false));
		}
	};

export const getProfileAction =
	(callback?: (profile?: Profile) => void): AppThunk =>
	async (dispatch) => {
		var profile: Profile | undefined = undefined;

		try {
			dispatch(setProfileLoading(true));
			dispatch(setProfileError(null));

			profile = await apiAccount.getUserProfile();

			dispatch(setProfile(profile));

			if (profile?.reasons) dispatch(setProfileError(profile?.reasons));
		} catch (error: any) {
			var reasons = [parseError(error)];

			profile = {
				reasons: reasons,
			};

			dispatch(setProfileError(reasons));
		} finally {
			callback && callback(profile);

			dispatch(setProfileLoading(false));
		}
	};

export const setThrottlingTimeAction =
	(throttlingSeconds: number): AppThunk =>
	async (dispatch) => {
		const newTime = moment().add(throttlingSeconds, "seconds").valueOf();

		dispatch(setThrottlingTime(newTime));
	};

export const setTokenHasExpiredAction =
	(hasExpired: boolean): AppThunk =>
	async (dispatch) => {
		dispatch(setTokenHasExpired(hasExpired));
	};

export const setPowerLoginAction =
	(authToken: string): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(setUserLoading(true));
			dispatch(setUserError(null));

			const powerUser: User = {
				isAuthentic: true,
				token: authToken,
			};

			setToken(authToken || "");
			dispatch(setUser(powerUser));
			dispatch(setUserIsAuthenticated(powerUser.isAuthentic));
		} catch (error: any) {
			var reasons = [parseError(error)];

			dispatch(setUserError(reasons));
		} finally {
			dispatch(setUserLoading(false));
		}
	};

export const setNewPageLoadAction =
	(newPageLoad: boolean): AppThunk =>
	async (dispatch) => {
		dispatch(setNewPageLoad(newPageLoad));
	};
