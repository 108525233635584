import { useEffect, useRef, useState } from "react";

export const MOBILE_MEDIA_QUERY = "only screen and (max-width: 1024px)";

export const useMediaQuery = (query = MOBILE_MEDIA_QUERY) => {
	const matchMedia = useRef(window.matchMedia(query));
	const [match, setMatch] = useState(matchMedia.current.matches);

	useEffect(() => {
		const handleMediaChange = (event: MediaQueryListEvent) => {
			setMatch(event.matches);
		};
		if (matchMedia.current.addEventListener) {
			matchMedia.current.addEventListener("change", handleMediaChange);
			return () =>
				matchMedia.current.removeEventListener("change", handleMediaChange);
		}
		matchMedia.current.addListener(handleMediaChange);
		return () => matchMedia.current.removeListener(handleMediaChange);
	}, []);
	return match;
};
