import type { WithdrawalAccount } from "@app/entities";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
	createWithdrawalBankError?: string[] | null;
	createWithdrawalBankLoading: boolean;

	destroyWithdrawalBankError?: string[] | null;
	destroyWithdrawalBankLoading: boolean;

	requestWithdrawalError?: string[] | null;
	requestWithdrawalLoading: boolean;

	withdrawalBanks: WithdrawalAccount[];
	retrieveWithdrawalBankListError?: string[] | null;
	retrieveWithdrawalBankListLoading: boolean;

	withdrawalBank?: WithdrawalAccount | null;
	retrieveWithdrawalBankError?: string[] | null;
	retrieveWithdrawalBankLoading: boolean;

	updateWithdrawalBankError?: string[] | null;
	updateWithdrawalBankLoading: boolean;
};

export const defaultState: State = {
	createWithdrawalBankLoading: false,

	destroyWithdrawalBankLoading: false,

	requestWithdrawalLoading: false,

	withdrawalBanks: [],
	retrieveWithdrawalBankListLoading: false,

	retrieveWithdrawalBankLoading: false,

	updateWithdrawalBankLoading: false,
};

const slice = createSlice({
	name: "clients",
	initialState: defaultState,
	reducers: {
		setCreateWithdrawalBankError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.createWithdrawalBankError = action.payload;
		},
		setCreateWithdrawalBankLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.createWithdrawalBankLoading = action.payload;
		},

		setDestroyWithdrawalBankError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.destroyWithdrawalBankError = action.payload;
		},
		setDestroyWithdrawalBankLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.destroyWithdrawalBankLoading = action.payload;
		},

		setRequestWithdrawalError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.destroyWithdrawalBankError = action.payload;
		},
		setRequestWithdrawalLoading(state: State, action: PayloadAction<boolean>) {
			state.requestWithdrawalLoading = action.payload;
		},

		setWithdrawalBanks(state, action: PayloadAction<WithdrawalAccount[]>) {
			state.withdrawalBanks = action.payload;
		},
		setRetrieveWithdrawalBankListError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.retrieveWithdrawalBankListError = action.payload;
		},
		setRetrieveWithdrawalBankListLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.retrieveWithdrawalBankListLoading = action.payload;
		},

		setWithdrawalBank(state, action: PayloadAction<WithdrawalAccount | null>) {
			state.withdrawalBank = action.payload;
		},
		setRetrieveWithdrawalBankError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.retrieveWithdrawalBankError = action.payload;
		},
		setRetrieveWithdrawalBankLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.retrieveWithdrawalBankLoading = action.payload;
		},

		setUpdateWithdrawalBankError(
			state: State,
			action: PayloadAction<string[] | null>,
		) {
			state.updateWithdrawalBankError = action.payload;
		},
		setUpdateWithdrawalBankLoading(
			state: State,
			action: PayloadAction<boolean>,
		) {
			state.updateWithdrawalBankLoading = action.payload;
		},
	},
});

export const {
	setCreateWithdrawalBankError,
	setCreateWithdrawalBankLoading,

	setDestroyWithdrawalBankError,
	setDestroyWithdrawalBankLoading,

	setRequestWithdrawalError,
	setRequestWithdrawalLoading,

	setWithdrawalBanks,
	setRetrieveWithdrawalBankListError,
	setRetrieveWithdrawalBankListLoading,

	setWithdrawalBank,
	setRetrieveWithdrawalBankError,
	setRetrieveWithdrawalBankLoading,

	setUpdateWithdrawalBankError,
	setUpdateWithdrawalBankLoading,
} = slice.actions;

export default slice.reducer;
