import lastStepImage from "@app/assets/images/wizard-last-step.svg";
import stepCompletedImage from "@app/assets/images/wizard-step-completed.svg";
import { Transition } from "@headlessui/react";
import React, { type ReactNode } from "react";
import "./wizard.css";

export const WizardView = React.memo(
	(props: {
		className?: string;
		completeForm: ReactNode;
		forms: ReactNode[];
		hideSteps?: boolean;
		isComplete?: boolean;
		step?: number;
		hideProgress?: boolean;
	}) => {
		const getClassNames = () => {
			const classesToUse = ["wizard flex flex-col items-center"];

			if (props.className) classesToUse.push(props.className);

			return classesToUse.join(" ");
		};

		const getStep = (step: number) => {
			const key = `step-${step}`;

			if (props.step <= step) {
				var classesToUse = [
					"wizard-circle flex-none relative font-bold select-none",
				];

				if (props.step === step) classesToUse.push("active");

				return !props.hideSteps ? (
					<div key={key} className={classesToUse.join(" ")}>
						<div className="outer-circle rounded-full" />
						<div className="inner-circle flex justify-center items-center absolute rounded-full">
							{step + 1}
						</div>
					</div>
				) : (
					<></>
				);
			}

			return (
				<img key={key} alt="wizard-step-completed" src={stepCompletedImage} />
			);
		};

		const getHeader = () => {
			const headerContent: JSX.Element[] = [];
			const wizardClasses = ["wizard-header flex items-center w-full"];

			if (props.isComplete) {
				if (!props.hideSteps) {
					headerContent.push(
						<img
							className="mx-auto"
							key={0}
							alt="wizard-last-step"
							src={lastStepImage}
						/>,
					);
				}
			} else {
				props.forms.map((x, index) => {
					headerContent.push(getStep(index));
					if (index < props.forms.length - 1)
						headerContent.push(
							<div
								key={`sep-${index}`}
								className="step-separator flex flex-1 mx-6"
							/>,
						);
				});
			}

			if (props.forms.length > 3) wizardClasses.push("lg:w-10/12");
			else if (props.forms.length > 2)
				wizardClasses.push("sm:w-10/12 lg:w-8/12");
			else {
				wizardClasses.push("sm:w-8/12 lg:w-6/12");
				if (props.forms.length === 1 && !props.isComplete)
					wizardClasses.push("justify-center");
			}

			return <div className={wizardClasses.join(" ")}>{headerContent}</div>;
		};

		const getForm = (index: number, form: ReactNode, show = false) => {
			return (
				<Transition
					key={index}
					className="form-container w-full"
					show={show}
					enter="ease-out duration-1000"
					enterFrom="opacity-0 translate-y-5"
					leaveFrom="opacity-0"
					leaveTo="opacity-0"
				>
					{form}
				</Transition>
			);
		};

		const getFormSteps = () => {
			return (
				<div className="wizard-form flex w-full pt-6">
					{props.forms.map((form, index) =>
						getForm(index, form, !props.isComplete && index === props.step),
					)}
					{getForm(props.forms.length, props.completeForm, props.isComplete)}
				</div>
			);
		};

		return (
			<div className={getClassNames()}>
				{!props.hideProgress && getHeader()}
				{getFormSteps()}
			</div>
		);
	},
);
