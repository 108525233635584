import { AccordionCard } from "@app/components/accordion-card";
import { memo } from "react";
import "./notification-line.css";
import { IoMailOpenSharp, IoMailUnreadSharp } from "react-icons/io5";
import type { ViewProperties } from "./properties";

export const NotificationLineView = memo((props: ViewProperties) => {
	const notification = props.notification;

	const containerClasses = [
		"notification flex py-2 pl-4 pr-2 cursor-pointer items-center",
	];

	if (!notification.read) {
		containerClasses.push("unread");
	}

	if (props.open) containerClasses.push("active");

	return (
		<div
			key={props.index}
			className={containerClasses.join(" ")}
			onClick={() => {
				props.onToggle();
				!props.open && !notification.read && props.onRead(props.index);
			}}
		>
			<AccordionCard
				className="accordion-content pr-2 min-w-0"
				headerContent={
					<div className="text-left flex w-full items-center select-none">
						<div className="flex flex-1 min-w-0">
							<div className="title font-primary-medium mr-auto truncate">
								{notification.title}
							</div>
						</div>
					</div>
				}
				open={props.open}
				locked
			>
				<div
					className="description font-primary-regular break-words"
					dangerouslySetInnerHTML={{
						__html: notification.description,
					}}
				/>
			</AccordionCard>
			<div className="pill flex-grow-0 p-1.5 ml-4">
				{notification.read ? (
					<IoMailOpenSharp size={16} color="white" />
				) : (
					<IoMailUnreadSharp size={16} color="white" />
				)}
			</div>
		</div>
	);
});
